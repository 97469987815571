import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { faLink, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Project } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import { UploadInput } from "components/ui/UploadInput";
import { useSignUploadUrlMutation } from "modules/generated";

import { Section } from "../Section";
import { type ProjectDetailsQuery, useUpdateProjectMutation } from "../generated";
import { getChangedFields } from "../helpers";
import type { SectionRef } from "./types";

type Fields = Pick<Project, "agreement_attachment_url" | "agreement_attachment_title">;

export const AppendixSection = forwardRef<
  SectionRef,
  {
    isSubmitting: boolean;
    project?: Partial<ProjectDetailsQuery["projects"][number]>;
  }
>(({ isSubmitting, project }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [fields, setFields] = useState<Partial<Fields>>({});

  useEffect(() => {
    if (!project) return;
    setFields({
      agreement_attachment_url: project.agreement_attachment_url,
      agreement_attachment_title: project.agreement_attachment_title || "Appendix C"
    });
  }, [project]);

  const [signUploadUrl] = useSignUploadUrlMutation();
  const [updateProject] = useUpdateProjectMutation();
  const snackbar = useSnackbar();

  const validate = () => {
    const errors: string[] = [];
    setFieldErrors(errors);
    return errors;
  };

  const handleSubmit = async () => {
    if (validate().length) return;

    if (!Object.values(changedFields).length) return;
    if (!project?.id) throw Error("missing project");
    if (changedFields.agreement_attachment_url) {
      const url = new URL(changedFields.agreement_attachment_url);
      await updateProject({
        variables: {
          projectId: project.id,
          set: { ...changedFields, agreement_attachment_url: url.origin + url.pathname }
        }
      });
    } else {
      await updateProject({ variables: { projectId: project.id, set: changedFields } });
    }
  };

  const changedFields = getChangedFields({
    data: fields,
    initialData: project as ProjectDetailsQuery["projects"][number]
  });

  const handleFieldChange = (partialData: Partial<Fields>) => {
    setFields({ ...fields, ...partialData });
  };

  const isLoading = isSubmitting || !project;
  const isChanged = Object.values(changedFields).length > 0;

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
    validate
  }));

  return (
    <Section
      isChanged={isChanged}
      fieldErrors={fieldErrors}
      title="Appendix"
      updated_at={project?.updated_at}
      sidebar={
        <Note variant="info" label="Appendix">
          Appendix is attached to the creator&apos;s agreement.
        </Note>
      }
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <UploadInput
          disabled={isLoading}
          accept={["files"]}
          label={fields?.agreement_attachment_url ? "Replace appendix" : "Upload appendix"}
          onChange={async files => {
            const file = files[0];
            if (!file) return;

            snackbar.info(
              `${file.name.toUpperCase()} is being uploaded. It is about to become public!`
            );
            try {
              const { data: signUploadUrlData } = await signUploadUrl({
                variables: {
                  input: {
                    fileType: file.type,
                    prefix: "AGREEMENT_ATTACHMENTS",
                    fileName: file.name
                  }
                }
              });
              if (!signUploadUrlData?.signUploadUrl) {
                snackbar.error("Something went wrong when signing a file for the upload");
                return;
              }
              const { signedDownloadUrl, signedUploadUrl } = signUploadUrlData.signUploadUrl;
              const response = await fetch(signedUploadUrl, {
                method: "PUT",
                body: file,
                headers: { "content-type": file?.type }
              });
              if (!response.ok) {
                snackbar.error("Something went wrong when uploading a file");
              } else {
                handleFieldChange({ agreement_attachment_url: signedDownloadUrl });
                snackbar.info(
                  `An agreement's appendix successfully uploaded - ${file.name.toUpperCase()}, don't forget to save the form`
                );
              }
            } catch {
              snackbar.error("Something went wrong when uploading a file");
            }
          }}
        />

        <div style={{ display: "flex", gap: 10 }}>
          <Button
            style={{ width: "100%" }}
            variant="outlined"
            icon={<FontAwesomeIcon icon={faLink} />}
            disabled={!fields.agreement_attachment_url || isLoading}
            onClick={() => {
              if (fields.agreement_attachment_url) {
                window.open(fields.agreement_attachment_url, "_blank")?.focus();
              }
            }}
          >
            See appendix
          </Button>
          <Button
            variant="outlined"
            style={{ width: "100%" }}
            icon={<FontAwesomeIcon icon={faTrash} />}
            disabled={!fields.agreement_attachment_url || isLoading}
            onClick={() => handleFieldChange({ agreement_attachment_url: null })}
          >
            Delete appendix
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <TextInput
          disabled={isLoading || !fields.agreement_attachment_url}
          label="Appendix name"
          value={fields.agreement_attachment_title}
          onChange={v => handleFieldChange({ agreement_attachment_title: v })}
        />
      </div>
    </Section>
  );
});
