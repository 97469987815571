import type { FC } from "react";

import styled from "@emotion/styled";
import { faMessageDots } from "@fortawesome/pro-regular-svg-icons";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { getUserLabel, getUserPlatform } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { CAMPAIGN_USER_STATE_DISPLAY_NAMES } from "lib/constants";

import { ListEditor } from "../common/ListEditor";
import type { CreatorListItemsQuery } from "../generated";

const Root = styled.div`
  display: flex;
  align-items: center;

  > .comment-badge {
    color: ${palette.gray.white};
    background: ${palette.gray[30]};
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    margin-left: 7px;
    &.active {
      background: ${palette.secondary.green};
    }
  }

  > .state {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  > a {
    > .user-button {
      min-width: 30px;
      padding: 8px;
      border-radius: 15px;
      margin-left: 7px;
      font-size: 14px;
    }
  }
`;

export const CreatorListItemButtons: FC<{
  user: CreatorListItemsQuery["users"][number];
  lowRatedUser: CreatorListItemsQuery["users"][number]["campaign_users"][number] | null;
  showListSelector?: boolean;
}> = ({ user, showListSelector, lowRatedUser }) => {
  if (!user) return null;

  const state = (() => {
    if (user.campaign_users.length !== 1) return "N/A";
    return (
      CAMPAIGN_USER_STATE_DISPLAY_NAMES[user.campaign_users[0]?.state as string] ?? "(not set)"
    );
  })();

  return (
    <Root>
      <span className="state">{state}</span>

      {lowRatedUser && (
        <Tooltip title={lowRatedUser.notes ?? ""}>
          <span className={`comment-badge ${lowRatedUser.notes ? "active" : ""}`}>
            <FontAwesomeIcon icon={faMessageDots} />
          </span>
        </Tooltip>
      )}

      <Button
        href={`/user/${user.id}`}
        className="user-button"
        color={lowRatedUser ? "secondary" : "primary"}
      >
        <FontAwesomeIcon icon={faUserCircle} />
      </Button>

      {showListSelector && (
        <ListEditor
          userId={user.id}
          userLabel={getUserLabel(user)}
          platform={getUserPlatform(user)}
        />
      )}
    </Root>
  );
};
