import type { FC } from "react";

import { faSnapchatSquare } from "@fortawesome/free-brands-svg-icons";
import { faCheck, faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { prettifyDate, prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { Link, useParams } from "react-router-dom";

import { Center } from "lib/styled";
import { useLinksByKeyQuery } from "modules/links/generated";
import { extractRltToKey } from "modules/links/util";

import { SnapNoteCell } from "./SnapNoteCell";
import { useCampaignSnapchatStatsQuery } from "./generated";

export const SnapsTable: FC = () => {
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");

  const { data, loading: snapsLoading } = useCampaignSnapchatStatsQuery({
    variables: { stubs: campaignStubs }
  });

  const rltToKeys = (data?.campaign_snaps ?? [])
    .map(p => extractRltToKey(p.track_url))
    .filter(Boolean) as string[];

  const { data: linkData, loading: linksLoading } = useLinksByKeyQuery({
    variables: { keys: rltToKeys },
    skip: !rltToKeys.length
  });

  if (!data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const rows = data.campaign_snaps.map(row => {
    const postRltLink = linkData?.link_clicks.find(lc => lc.key === extractRltToKey(row.track_url));
    const linkClicks = postRltLink?.clicks ?? 0;

    return {
      ...row,
      key: row.id,
      username: row.campaign_user.user?.user_snapchat?.username ?? "",
      id: row.id,
      userId: row.campaign_user?.user?.id,
      campaignStub: row.campaign_user.campaign.stub,
      externalId: row.campaign_user.user?.user_snapchat?.external_id,
      linkClicks,
      views: row.views ?? 0,
      reach: row.reach ?? 0,
      screenshots: row.screenshots ?? 0,
      comments: row.comments ?? 0,
      clicks: row.clicks ?? 0,
      engagementReach:
        ((row.screenshots ?? 0) + (row.comments ?? 0) + (row.clicks ?? 0)) / (row.reach ?? 0),
      engagementViews:
        ((row.screenshots ?? 0) + (row.comments ?? 0) + (row.clicks ?? 0)) / (row.views ?? 0)
    };
  });

  const columns: TableColumn<typeof rows>[] = [
    {
      headerName: "User",
      field: "username",
      minWidth: 200,
      renderCell: ({ row, value }) => (
        <Link to={`/user/${row.userId}`}>
          {row.approved ? (
            <FontAwesomeIcon
              color={palette.secondary.green}
              icon={faCheck}
              style={{ marginRight: 10 }}
            />
          ) : (
            <FontAwesomeIcon color={palette.secondary.red} icon={faX} style={{ marginRight: 10 }} />
          )}
          {value}
        </Link>
      )
    },
    {
      headerName: "Snap",
      field: "shortcode",
      minWidth: 150,
      renderCell: ({ row }) => {
        const icon = row.shortcode ? (
          <Link
            style={{ display: "inline-block", verticalAlign: "middle", marginRight: 5 }}
            target="_blank"
            rel="noreferrer"
            to={`https://www.snapchat.com/p/${row.externalId ?? null}/${row.shortcode}`}
          >
            <FontAwesomeIcon icon={faSnapchatSquare} color={palette.social.snapchat} />
          </Link>
        ) : null;

        return (
          <>
            {icon}
            <Link to={`/campaign/${row.campaignStub}/content/snap/${row.id}`}>View snap</Link>
          </>
        );
      }
    },
    {
      headerName: "Prod note",
      field: "notes",
      renderCell: ({ value, row }) => <SnapNoteCell note={value} campaignSnapId={row.key} />
    },
    { headerName: "Created", field: "created_at", renderCell: ({ value }) => prettifyDate(value) },
    {
      headerName: "Link clicks",
      field: "linkClicks",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Views",
      field: "views",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Reach",
      field: "reach",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Screenshots",
      field: "screenshots",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Comments",
      field: "comments",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Clicks",
      field: "clicks",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Engagement reach",
      field: "engagementReach",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      headerName: "Engagement views",
      field: "engagementViews",
      renderCell: ({ value }) => prettifyNumber(value)
    }
  ];

  return (
    <Table
      tableId="campaign-snaps"
      rows={rows}
      columns={columns}
      loading={snapsLoading || linksLoading}
      sortable
      canSelectColumns
      canExportCSV
      stickyColumn
    />
  );
};
