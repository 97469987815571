import { type FC, useCallback, useState } from "react";

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { prettifyDate, prettifyNumber } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { useLocation, useNavigate } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";
import { CustomLink } from "components/CustomLink";
import { Headline } from "components/ui/Headline";
import { NavigationButton } from "components/ui/NavigationButton";
import { getPlatformIcon } from "lib/campaigns";
import { HYPEAUDITOR_LABELS } from "lib/constants";

import { useRequestHypeAuditorReportMutation } from "../generated";
import { CSVBuilderModal } from "./CSVBuilderModal";
import { HypeAuditorUserListDocument, useHypeAuditorUserListQuery } from "./generated";
import { useAggregatedListPlatform } from "./useAggregatedListPlatform";

const platformKeys: Record<Platform_Enum, string> = {
  instagram: "users_instagram_username",
  youtube: "users_youtube_channel_title",
  tiktok: "user_tiktok_username",
  snapchat: "users_snapchat_username"
};

export const AggregatedList: FC = () => {
  const { campaignIds = "", listIds = "" } = useSearchParams<{
    campaignIds: string;
    listIds: string;
  }>();

  const navigate = useNavigate();
  const location = useLocation();
  const [isCsvBuilderPopup, setIsCsvBuilderPopup] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const platform = useAggregatedListPlatform();

  const platformNameKey = platformKeys[platform];

  const {
    data: { listUsers = [] } = {},
    loading
  } = useHypeAuditorUserListQuery({
    variables: {
      where: {
        ...(listIds && {
          lists_id:
            listIds.split(",").length > 1
              ? { _in: listIds.split(",").map(Number) }
              : { _eq: Number(listIds) }
        }),
        ...(campaignIds && {
          campaign_id:
            campaignIds.split(",").length > 1
              ? { _in: campaignIds.split(",").map(Number) }
              : { _eq: Number(campaignIds) }
        })
      },
      order_by: [{ [platformNameKey]: "asc" }]
    },
    skip: !listIds,
    pollInterval: 60 * 1000
  });

  const snackbar = useSnackbar();
  const [requestHypeauditorReport, { loading: requestHypeauditorReportLoading }] =
    useRequestHypeAuditorReportMutation({
      refetchQueries: [HypeAuditorUserListDocument],
      awaitRefetchQueries: true,
      onCompleted: () =>
        snackbar.success(
          "Reports requested. Please wait a few minutes until the reports are generated!"
        ),
      onError: () => snackbar.error("Something went wrong")
    });

  const uniqueListUsers = listUsers.reduce<typeof listUsers>(
    (acc, r) => (!acc.find(a => a[platformNameKey] === r[platformNameKey]) ? [...acc, r] : acc),
    []
  );

  const usersToShow = (campaignIds ? listUsers : uniqueListUsers)
    .filter(
      r =>
        (showConfirmed ? ["confirmed", "approved"].includes(r.campaign_user?.state || "") : true) &&
        r[platformNameKey]
    )
    .sort((a, b) => {
      if (a.hypeauditor?.state !== b.hypeauditor?.state) {
        return (b.hypeauditor?.state || "").indexOf("pending");
      } else {
        return a[platformNameKey]?.localeCompare(b[platformNameKey] || "");
      }
    });
  const usersToShowWithoutHAReports = usersToShow.filter(u => !u.hypeauditor);

  const { listNames, campaignTitles } = listUsers.reduce(
    (acc, { lists_name, campaign_title }) => ({
      ...acc,
      ...(!acc.listNames.includes(lists_name || "") && {
        listNames: [...acc.listNames, lists_name]
      }),
      ...(!acc.campaignTitles.includes(campaign_title || "") && {
        campaignTitles: [...acc.campaignTitles, campaign_title]
      })
    }),
    { listNames: [], campaignTitles: [] } as {
      listNames: (string | undefined | null)[];
      campaignTitles: (string | undefined | null)[];
    }
  );

  const handleCloseModal = useCallback(() => setIsCsvBuilderPopup(false), []);

  if (loading || requestHypeauditorReportLoading) return <Loader />;

  const handleRequestReportsButtonClick = () => {
    // if everyone has repots, we want to request an update instead
    const usersToRequestReports = usersToShowWithoutHAReports.length
      ? usersToShowWithoutHAReports
      : usersToShow;

    requestHypeauditorReport({
      variables: {
        objects: usersToRequestReports.map(u => {
          if (!u.user_id) throw new Error("missing user_id");
          return {
            users_id: u.user_id,
            updated_at: new Date().toISOString(),
            state: "pending_rel"
          };
        })
      }
    });
  };
  const existUsersWithoutHAReport = usersToShowWithoutHAReports.length > 0;
  const pendingReportRequests =
    usersToShow.filter(u => ["pending_ha", "pending_rel"].includes(u.hypeauditor?.state || ""))
      .length > 0;

  const getBuildCsvButtonTitle = () => {
    if (existUsersWithoutHAReport) {
      return "Not all the users have their profile analyzed, do that first before exporting!";
    }
    return "Build the list for the client";
  };
  const pageTitle = campaignIds ? campaignTitles.join(", ") : listNames.join(", ");

  const columns: TableColumn<typeof tableRows>[] = [
    {
      field: "username",
      headerName: "User",
      renderCell: ({ row, value }) => (
        <CustomLink className="user-link" url={row.userUrl}>
          {value}
        </CustomLink>
      )
    },
    {
      field: "followedBy",
      headerName: "Followed by",
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "updatedAt",
      headerName: "Data updated",
      renderCell: ({ value }) => prettifyDate(value)
    },
    {
      field: "state",
      headerName: "Campaign state",
      renderCell: ({ value }) => value ?? <i>Not set</i>
    },
    {
      field: "hypeauditorState",
      headerName: "HypeAuditor state",
      renderCell: ({ value }) => (
        <HypeAuditorStateCell $state={value}>
          {value ? HYPEAUDITOR_LABELS[value] : value}
        </HypeAuditorStateCell>
      )
    }
  ];
  const tableRows = usersToShow.map(row => {
    const urls: Record<Platform_Enum, string> = {
      instagram: `https://instagram.com/${row.users_instagram_username}`,
      youtube: `https://www.youtube.com/channel/${row.users_youtube_channel_id}`,
      tiktok: `https://www.tiktok.com/@${row.user_tiktok_username}`,
      snapchat: `https://www.snapchat.com/add/${row.users_snapchat_username}`
    };

    const userId = row.user_id;

    const paths: Record<Platform_Enum, string> = {
      instagram: `/hypeauditor/instagram/${userId}`,
      youtube: `/hypeauditor/youtube/${userId}`,
      tiktok: `/hypeauditor/tiktok/${userId}`,
      snapchat: `/hypeauditor/snapchat/${userId}`
    };

    return {
      key: `${row[platformNameKey]}-${row.lists_id}-${row.campaign_id}`,
      userUrl: urls[platform],
      username: row[platformNameKey],
      followedBy:
        row.hypeauditor?.data?.followers_count ||
        row.hypeauditor?.data?.metrics?.subscribers_count?.value,
      updatedAt: row.hypeauditor?.updated_at,
      state: row.campaign_user?.state,
      hypeauditorState: row.hypeauditor?.state,
      reportPath: paths[platform]
    };
  });
  return (
    <Root>
      <div>
        <NavigationButton direction="back" className="nav-button" />
        <TitleContainer>
          <FontAwesomeIcon icon={getPlatformIcon(platform)} />
          <Headline variant="h4">{pageTitle}</Headline>
        </TitleContainer>
      </div>

      <Table
        tableId="hypeauditor-list"
        rows={tableRows}
        columns={columns}
        sortable
        canSelectColumns
        canExportCSV
        onRowClick={row => navigate(row.reportPath, { state: { from: location.pathname } })}
      />
      <Button style={{ margin: "20px 0" }} onClick={() => setShowConfirmed(!showConfirmed)}>
        {showConfirmed ? "Show all states" : "Only confirmed"}
      </Button>
      <ButtonContainer>
        {usersToShow.length ? (
          <Button onClick={handleRequestReportsButtonClick} variant="outlined">
            Request {existUsersWithoutHAReport ? "analysis" : "update"} for{" "}
            {existUsersWithoutHAReport
              ? `${usersToShowWithoutHAReports.length} out of ${usersToShow.length}`
              : ""}{" "}
            creators
          </Button>
        ) : (
          <span>No creators to show!</span>
        )}

        <ModalTriggerButton
          color="primary"
          variant="contained"
          onClick={() => {
            if (pendingReportRequests) {
              // eslint-disable-next-line no-alert
              const isUserAware = window.confirm(
                "Some user's reports are still being generated and will not show up in the exported list"
              );
              if (!isUserAware) {
                return;
              }
            }
            setIsCsvBuilderPopup(true);
          }}
          tooltipText={getBuildCsvButtonTitle()}
        >
          Build CSV to export
        </ModalTriggerButton>
      </ButtonContainer>

      {isCsvBuilderPopup && (
        <CSVBuilderModal
          closeModal={handleCloseModal}
          filename={pageTitle.split(" ").join("_")}
          showConfirmed={showConfirmed}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalTriggerButton = styled(Button)`
  margin: 0;
  width: 150px;
  margin-left: 15px;
`;

const TitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 25px;

  svg {
    margin-right: 25px;
  }
`;
const HypeAuditorStateCell = styled.span<{ $state }>`
  color: ${({ $state = "" }) => {
    if ($state === "error") {
      return palette.primary.red;
    } else if ($state?.indexOf("pending") > -1) {
      return palette.secondary.gold;
    } else if ($state === "completed") {
      return palette.secondary.green;
    } else {
      return "initial";
    }
  }};
`;
