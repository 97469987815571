import { type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { faList, faPeople } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Tab, Tabs } from "@mui/material";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { ContentContainer } from "lib/styled";

import { ContactFormData } from "./applicants/ContactFormData";
import { CreatorList } from "./creators/CreatorList";
import { ListManagement } from "./management/ListManagement";
import { Iframe } from "./preview/Iframe";
import { SearchTab } from "./search/SearchTab";

const Root = styled.div`
  margin-top: 20px;
  position: relative;
  display: grid;
  gap: 1%;
  grid-template-columns: 49.5% 49.5%;
  width: 100%;

  > .left {
    > .fixed-pos {
      position: sticky;
      left: 0;
      top: 20px;
    }
  }

  > .right {
    > .card {
      width: 100%;
      margin-bottom: 18px;
      padding: 12px;
      padding-top: 0;

      > .tab {
        margin-top: 18px;
      }
    }
  }
`;

export const Curation: FC = () => {
  useDocumentTitle("Curation");
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTab = (() => {
    if (location.pathname.includes("applicants")) return "applicants";
    return location.pathname.includes("list-management") ? "list-management" : "search";
  })();

  const [usernameFilter, setUsernameFilter] = useState<string>("");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  // visibleUserIds=null => there is no list selected
  // visibleUserIds=[] => there are no results or no creators on the list
  const [visibleUserIds, setVisibleUserIds] = useState<number[] | null>(null);

  const onChangeTab = (path: typeof selectedTab) => {
    setSelectedUserId(null);
    setVisibleUserIds([]);
    navigate(`/curation/${path}`);
  };

  // clear invalid selection (after deletion)
  useEffect(() => {
    if (!visibleUserIds || !selectedUserId) return;
    if (!visibleUserIds.includes(selectedUserId)) {
      setSelectedUserId(null);
    }
  }, [selectedUserId, visibleUserIds]);

  return (
    <ContentContainer>
      <Root style={selectedTab === "applicants" ? { gridTemplateColumns: "100%" } : {}}>
        {selectedTab !== "applicants" && (
          <div className="left">
            <div className="fixed-pos">
              <Iframe selectedUserId={selectedUserId} />
            </div>
          </div>
        )}
        <div className="right">
          <Card className="card">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTab}
                onChange={(_, newValue) => onChangeTab(newValue)}
                variant="fullWidth"
                style={{ minHeight: 40 }}
              >
                <Tab
                  label="List management"
                  icon={<FontAwesomeIcon icon={faList} />}
                  iconPosition="start"
                  value="list-management"
                  style={{ minHeight: 40, height: 40 }}
                />
                <Tab
                  label="Search"
                  icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                  iconPosition="start"
                  value="search"
                  style={{ minHeight: 40, height: 40 }}
                />
                <Tab
                  label="Applicants"
                  icon={<FontAwesomeIcon icon={faPeople} />}
                  iconPosition="start"
                  value="applicants"
                  style={{ minHeight: 40, height: 40 }}
                />
              </Tabs>
            </Box>

            <div className="tab">
              <Routes>
                <Route
                  path="list-management/:listId?"
                  element={<ListManagement setVisibleUserIds={setVisibleUserIds} />}
                />
                <Route
                  path="search"
                  element={
                    <SearchTab setVisibleUserIds={setVisibleUserIds} username={usernameFilter} />
                  }
                />
                <Route path="applicants" element={<ContactFormData />} />
                <Route path="*" element={<Navigate to="list-management" />} />
              </Routes>
            </div>
          </Card>
          {selectedTab !== "applicants" && (
            <Card className="card">
              <CreatorList
                userIds={visibleUserIds}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                usernameFilter={usernameFilter}
                setUsernameFilter={setUsernameFilter}
                showListSelector={selectedTab === "search"}
                showPlatform={selectedTab === "search"}
              />
            </Card>
          )}
        </div>
      </Root>
    </ContentContainer>
  );
};
