import type { FC } from "react";

import styled from "@emotion/styled";
import { faArrowRotateLeft, faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { MultipleSelect, Select } from "@relatable/ui/Select";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useSearchParams } from "@relatable/ui/hooks/useSearchParams";
import { Autocomplete } from "components/ui/Autocomplete";
import { useMeQuery } from "hooks/generated";
import { PLATFORM_LABELS, PROJECT_TYPES } from "src/lib/constants";

import { useMarketsQuery } from "@relatable/gql/generated";
import { useVerticalsQuery } from "../generated";
import type { OverviewQuery } from "./generated";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
`;

export const OverviewHeader: FC<{
  data: OverviewQuery | undefined;
}> = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const meQuery = useMeQuery();

  const { archived, markets, verticals, projectGoal, platform, ...params } = useSearchParams<{
    archived?: string;
    teamMemberAccountId?: string;
    markets?: string;
    verticals?: string;
    projectGoal?: string;
    platform?: string;
  }>();
  const teamMemberAccountId = params.teamMemberAccountId || String(meQuery.data?.me?.admin?.id);

  const verticalsQuery = useVerticalsQuery();
  const marketsQuery = useMarketsQuery();

  const handleAddSearchParam = (args: Record<string, string | null>) => {
    const p = new URLSearchParams(location.search);
    Object.entries(args).forEach(([key, value]) => {
      if (value === null) {
        p.delete(key);
      } else {
        p.set(key, value);
      }
    });

    if (!String(p)) return "";

    return `?${p}`;
  };

  const handleGetUrl = (args: Record<string, string | null>) =>
    `/overview${handleAddSearchParam(args)}`;

  return (
    <Root>
      <div style={{ display: "grid", gridTemplateColumns: "auto 15% 15% 15% 15%", gap: 20 }}>
        <Autocomplete
          label="Team member"
          value={teamMemberAccountId === "all" ? null : teamMemberAccountId}
          options={data?.accounts.map(a => ({ id: String(a.id), label: a.full_name || "" })) || []}
          onChange={v =>
            navigate(
              handleGetUrl({
                teamMemberAccountId: v === null ? "all" : v
              })
            )
          }
        />
        <MultipleSelect
          label="Markets"
          size="medium"
          value={markets?.split(",") || []}
          onChange={v => navigate(handleGetUrl({ markets: String(v) }))}
          options={
            marketsQuery.data?.market.map(m => ({ label: m.name, value: String(m.id) })) || []
          }
        />

        <MultipleSelect
          label="Verticals"
          size="medium"
          value={verticals?.split(",") || []}
          onChange={v => navigate(handleGetUrl({ verticals: String(v) }))}
          options={
            verticalsQuery.data?.vertical.map(m => ({ label: m.name, value: String(m.id) })) || []
          }
        />

        <Select
          label="Goals"
          options={Object.values(PROJECT_TYPES)}
          value={projectGoal}
          onChange={v => navigate(handleGetUrl({ projectGoal: v || null }))}
        />
        <Select
          label="Platforms"
          hideNone
          options={[
            {
              label: "All platforms",
              value: null
            },
            ...Object.entries(PLATFORM_LABELS).map(([pl, label]) => ({
              label,
              value: pl
            }))
          ]}
          value={platform}
          onChange={v => navigate(handleGetUrl({ platform: v || null }))}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "right", gap: 20 }}>
        <Checkbox
          label="Show archived"
          checked={Boolean(archived)}
          onChange={c => navigate(handleGetUrl({ archived: c ? "true" : null }))}
        />
        <Link to="/overview">
          <Button
            icon={<FontAwesomeIcon icon={faArrowRotateLeft} />}
            variant="outlined"
            onClick={() => null}
          >
            Reset filters
          </Button>
        </Link>
        <Link to="/project/new">
          <Button icon={<FontAwesomeIcon icon={faCirclePlus} />} onClick={() => null}>
            New project
          </Button>
        </Link>
      </div>
    </Root>
  );
};
