import { type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ListSelector } from "components/ListSelector";
import { Popup } from "components/ui/Popup";

import {
  CreatorSearchOptionsDocument,
  CreatorsInListDocument,
  HypeauditorListsDocument,
  ListsOfCreatorDocument,
  SearchListsDocument,
  useCreatorsInListQuery
} from "../generated";
import { CampaignList } from "./CampaignList";
import { CreateList } from "./CreateList";
import { ImportHypeAuditorList } from "./ImportHypeAuditorList";
import { ImportInternalList } from "./ImportInternalList";
import { ListCards } from "./ListCards";
import { ManualAdd } from "./ManualAdd";
import { Bookmarklet } from "./bookmarklet/Bookmarklet";
import { useUpdateListMutation } from "./generated";

const Root = styled.div`
  > .import {
    margin: 13px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${palette.gray[10]};
    border: 1px solid ${palette.tertiary.red};
    border-radius: 10px;
    width: 100%;
    padding: 9px 22px;
    > p {
      font-size: 16px;
      margin: 0;
    }
  }

  > .footer {
    display: flex;
    justify-content: space-between;
    > .right {
      display: flex;
      gap: 10px;
      align-items: center;
      > .link {
        color: ${palette.gray.black};
      }
    }
  }
`;

export const ListManagement: React.FC<{
  setVisibleUserIds: (v: number[] | null) => void;
}> = ({ setVisibleUserIds }) => {
  const { listId } = useParams<{ listId: string }>();
  const navigate = useNavigate();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

  const { data } = useCreatorsInListQuery({
    variables: { listId: listId ? Number(listId) : 0 },
    skip: !listId
  });

  useEffect(() => {
    if (!listId) {
      setVisibleUserIds(null);
    } else if (data) {
      setVisibleUserIds(data.users_in_lists.filter(u => u.user?.id).map(u => u.user?.id as number));
    }
  }, [setVisibleUserIds, data, listId]);

  return (
    <Root>
      <ListSelector
        listId={Number(listId)}
        onChange={newListId => navigate(`/curation/list-management/${newListId ?? ""}`)}
      />

      {!listId && <CreateList />}

      {data?.list?.id && (
        <>
          <ListCards data={data} />

          <CampaignList data={data} />

          <div className="import">
            <p>Import users or lists from</p>
            <div>
              <ImportHypeAuditorList internalListId={data.list.id} />
              <ImportInternalList
                platform={data.list.platform as Platform_Enum}
                internalListId={data.list.id}
              />
            </div>
          </div>

          <div className="footer">
            <ManualAdd listId={data.list.id} platform={data.list.platform as Platform_Enum} />
            <span className="right">
              {data?.list && (
                <>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsEditPopupOpen(true)}
                    icon={faPen}
                  />
                  {isEditPopupOpen && (
                    <EditListNamePopup
                      currentName={data.list.name || ""}
                      handleClose={() => setIsEditPopupOpen(false)}
                      listId={data.list.id}
                    />
                  )}
                </>
              )}
              <Link
                className="link"
                to={`/hypeauditor/${data.list.platform}/aggregated?listIds=${data.list.id}`}
              >
                <FontAwesomeIcon icon={faFileCsv} />
              </Link>
              <Bookmarklet
                listId={data.list.id}
                listName={data.list.name ?? ""}
                platform={data.list.platform as Platform_Enum}
              />
            </span>
          </div>
        </>
      )}
    </Root>
  );
};

const EditListNamePopup: FC<{ listId: number; handleClose(): void; currentName: string }> = ({
  listId,
  handleClose,
  currentName
}) => {
  const [cachedName] = useState(currentName);
  const [newName, setNewName] = useState(currentName);

  const [mutate] = useUpdateListMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      ListsOfCreatorDocument,
      CreatorSearchOptionsDocument,
      CreatorsInListDocument,
      HypeauditorListsDocument,
      SearchListsDocument
    ],
    onCompleted: handleClose
  });

  return (
    <Popup
      actionLabel="Update name"
      onClose={handleClose}
      onAction={() => mutate({ variables: { id: listId, set: { name: newName } } })}
      disabledAction={!newName || newName === currentName}
    >
      <TextInput label="Current name" disabled value={cachedName} style={{ marginTop: 10 }} />
      <TextInput label="New name" value={newName} required onChange={setNewName} />
    </Popup>
  );
};
