import type { FC } from "react";

import styled from "@emotion/styled";
import { faChartSimple, faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { useUpdateCampaignUserMutation } from "modules/campaign/generated";
import { COLLABORATION_SCORE_LABELS, CONTENT_QUALITY_SCORE_LABELS } from "src/lib/constants";

import { StatsSectionItem } from "../UserInfo/StatsSectionItem";
import { UserCampaignsDocument, type UserCampaignsQuery, type UserQuery } from "../generated";
import { CampaignComment } from "./CampaignComment";
import { CampaignImages } from "./CampaignImages";

const Root = styled.div`
  margin-left: 3%;
  margin-top: 16px;
  width: 95%;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 2%;

  > .left {
    > .scores {
      display: flex;
      justify-content: space-between;
      gap: 14px;

      > .score {
        padding: 12px;
        width: 50%;
        border: 1px solid ${palette.gray[10]};
        border-radius: 10px;
      }
    }

    > .images {
      margin-top: 10px;
    }
  }

  > .right {
    padding: 18px 12px;
    border: 1px solid ${palette.gray[10]};
    border-radius: 10px;
  }
`;

export const CampaignDetails: FC<{
  user: NonNullable<UserQuery["users_by_pk"]>;
  campaignUser: NonNullable<UserCampaignsQuery["campaign_user"][number]>;
}> = ({ user, campaignUser }) => {
  const snackbar = useSnackbar();

  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => {
      snackbar.success("Score was saved successfully");
    },
    refetchQueries: [UserCampaignsDocument],
    awaitRefetchQueries: true
  });

  return (
    <Root>
      <div className="left">
        <div className="scores">
          <div className="score">
            <StatsSectionItem
              title="Collaboration Score"
              value={campaignUser.collaboration_score?.toString() || "-"}
              icon={faChartSimple}
              editable
              editLabel="Set a collaboration score"
              editOptions={COLLABORATION_SCORE_LABELS.map((label, i) => ({
                value: `${i + 1}`,
                label: `${i + 1} - ${label}`
              }))}
              onEdit={value =>
                updateCampaignUser({
                  variables: {
                    _set: { collaboration_score: Number(value) },
                    campaignUserId: campaignUser.id
                  }
                })
              }
            />
          </div>
          <div className="score">
            <StatsSectionItem
              title="Content Quality Score"
              value={campaignUser.content_quality_score?.toString() || "-"}
              icon={faSparkles}
              editable
              editLabel="Set a content quality score"
              editOptions={CONTENT_QUALITY_SCORE_LABELS.map((label, i) => ({
                value: `${i + 1}`,
                label: `${i + 1} - ${label}`
              }))}
              onEdit={value =>
                updateCampaignUser({
                  variables: {
                    _set: { content_quality_score: Number(value) },
                    campaignUserId: campaignUser.id
                  }
                })
              }
            />
          </div>
        </div>
        <div className="images">
          <CampaignImages user={user} campaignUser={campaignUser} />
        </div>
      </div>
      <div className="right">
        <CampaignComment campaignUser={campaignUser} />
      </div>
    </Root>
  );
};
