import type { FC } from "react";

import styled from "@emotion/styled";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import { useStoredState } from "@relatable/ui/hooks/useStoredState";
import { useParams } from "react-router-dom";

import { ActionButtons } from "./ActionButtons";
import { PostsTable } from "./PostsTable";
import { ReelsTable } from "./ReelsTable";
import { Stats } from "./Stats";

export const CampaignPosts: FC = () => {
  const params = useParams<{ campaignStub: string }>();
  const [statisticsExpanded, setStatisticsExpanded] = useStoredState("statistics-expanded", false);

  const campaignStubs = (params.campaignStub ?? "").split(",");

  return (
    <Root>
      <Accordion
        expanded={statisticsExpanded}
        onChange={(_, expanded) => setStatisticsExpanded(expanded)}
        variant="outlined"
      >
        <StyledAccordionSummary expandIcon={<FontAwesomeIcon icon={faArrowDown} />}>
          Post & Reel statistics
        </StyledAccordionSummary>
        <AccordionDetails>
          <Stats campaignStubs={campaignStubs} />
        </AccordionDetails>
      </Accordion>

      <Divider />

      {campaignStubs.length === 1 && <ActionButtons campaignStub={campaignStubs[0]} />}

      <h2>Posts</h2>
      <PostsTable campaignStubs={campaignStubs} />

      <h2>Reels</h2>
      <ReelsTable campaignStubs={campaignStubs} />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;
