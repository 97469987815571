import { type FC, Fragment, useState } from "react";

import { faLink } from "@fortawesome/pro-regular-svg-icons";
import { faArrowToTop, faSyncAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Link as MUILink } from "@mui/material";
import { YYYY_MM_DD, timeFromNow } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { DateInput } from "@relatable/ui/DateInput";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { Link } from "react-router-dom";

import { Card } from "components/ui/Card";

import type { CampaignClientSheetQuery } from "./generated";

export const ClientSheet: FC<{
  sheetLogs: CampaignClientSheetQuery["campaign_client_sheet"];
  loading: boolean;
  onSave: (url: string) => void;
  onSync: () => void;
  setDueDate: (dueDate: string) => void;
  setMinCreators: (minCreators: number) => void;
  campaign: { id: number; listId: number | undefined | null };
}> = props => {
  const {
    onSave,
    onSync,
    loading,
    sheetLogs,
    setDueDate: setDueDateBackend,
    setMinCreators: setMinCreatorsBackend,
    campaign
  } = props;

  const sheetUrl = sheetLogs.find(log => log.url !== null)?.url ?? "";

  const [url, setUrl] = useState("");
  const renderSheetLinkForm = () => {
    return (
      <>
        <Box my={2}>
          <TextInput
            style={{ width: "100%", marginBottom: 10 }}
            placeholder="Paste Google Spreadsheet link here"
            value={url}
            onChange={setUrl}
          />
          <Button
            icon={<FontAwesomeIcon icon={faSyncAlt} />}
            variant="contained"
            color="secondary"
            size="full"
            disabled={!url || loading}
            onClick={() => onSave(url)}
          >
            Publish Spreadsheet
          </Button>
        </Box>
        {url ? (
          <Note label="Publishing a spreadsheet cannot be undone.">
            Please ensure that this is the right spreadsheet before publishing. However, you can
            edit & resync the attached spreadsheet in the future.
          </Note>
        ) : null}
      </>
    );
  };

  const renderSyncForm = () => {
    return (
      <>
        <p>
          If you need to add more creators to the list or more data points, please update the
          spreadsheet and push the sync button below.
        </p>

        <Grid container>
          <Grid item xs={8}>
            <Box my={2}>
              <Button
                icon={<FontAwesomeIcon icon={faArrowToTop} />}
                variant="outlined"
                color="secondary"
                size="full"
                disabled={loading}
                onClick={() => onSync()}
              >
                Sync spreadsheet updates to Creator List
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const savedDueDate =
    sheetLogs.find(log => log.due_date !== null)?.due_date ?? new Date().toISOString();

  const [dueDate, setDueDate] = useState(savedDueDate);

  const savedMinimumCreators = sheetLogs.find(log => log.min_creators !== null)?.min_creators ?? 50;

  const [minCreators, setMinCreators] = useState(savedMinimumCreators);
  const onMinCreatorsChange = (value: string) => {
    const num = Number(value);
    if (!Number.isNaN(num) && num >= 0) {
      setMinCreators(num);
      setMinCreatorsBackend(num);
    }
  };

  const renderLogItem = (
    item: CampaignClientSheetQuery["campaign_client_sheet"][number],
    index: number
  ) => {
    const actions: Record<"sync" | "set_url", string> = {
      sync: "synced",
      set_url: "created"
    } as const;

    return (
      <Fragment key={item.created_at + index}>
        {index > 0 && <br />}
        <span>
          {`Creator List successfully ${actions[item.action]} ${timeFromNow(item.created_at)}.`}
        </span>
      </Fragment>
    );
  };

  const [logCollapsed, setLogCollapsed] = useState(true);
  const renderFooter = () => {
    if (!sheetLogs) return null;

    const shownLogs = sheetLogs.filter(item => ["sync", "set_url"].includes(item.action));
    const slicedLog = logCollapsed ? shownLogs.slice(0, 2) : shownLogs;

    return (
      <>
        {slicedLog.map((logItem, index) => renderLogItem(logItem, index))}
        {slicedLog.length < shownLogs.length ? (
          <>
            <br />
            <MUILink href="#" color="inherit" onClick={() => setLogCollapsed(false)}>
              ...show {shownLogs.length - slicedLog.length} more activities
            </MUILink>
          </>
        ) : null}
      </>
    );
  };

  return (
    <Card footer={renderFooter()}>
      <Box mt={1}>
        <Grid container justifyContent="space-between">
          <Grid item xs={8}>
            <h2>Creator List spreadsheet</h2>
            <span style={{ paddingTop: 12, display: "inline-block", paddingBottom: 6 }}>
              Ready to sync your spreadsheet with great creator potentials?
            </span>
            <ul style={{ listStyle: "circle", paddingLeft: 20, paddingBottom: 12 }}>
              <li>
                First,{" "}
                <Link
                  to={`/hypeauditor/instagram/aggregated?campaignIds=${campaign.id}&listIds=${campaign.listId}`}
                >
                  build your spreadsheet.
                </Link>
              </li>
              <li>
                Second, customize your spreadsheet in google spreadsheets within{" "}
                <Link
                  to="https://docs.google.com/document/d/1TvHJvxbJ_Lf2VGdubDdqojdozGiB9Q2c5d64akdDSKA"
                  target="_blank"
                  rel="noreferrer"
                >
                  the Creator List guidelines
                </Link>
              </li>
              <li>Finally, upload that spreadsheet here and publish.</li>
            </ul>
          </Grid>
          {sheetUrl ? (
            <Grid item xs={4}>
              <Button
                icon={<FontAwesomeIcon icon={faLink} />}
                variant="contained"
                size="full"
                href={sheetUrl}
                target="_blank"
              >
                View spreadsheet
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      {sheetUrl ? renderSyncForm() : renderSheetLinkForm()}
      <Box
        display="flex"
        gap={20}
        mx={-7}
        mt={3}
        px={7}
        py={2}
        border={1}
        borderBottom={0}
        borderColor={palette.gray[40]}
      >
        <DateInput
          style={{ flexGrow: 1 }}
          label="Due date (displayed on list)"
          value={YYYY_MM_DD(new Date(dueDate))}
          onChange={v => {
            setDueDate(v);
            setDueDateBackend(v);
          }}
        />
        <TextInput
          style={{ flexGrow: 1 }}
          label="Minimum amount of creators needed for list approval"
          type="number"
          value={String(minCreators)}
          onChange={onMinCreatorsChange}
        />
      </Box>
    </Card>
  );
};
