import { useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { faCommentAlt, faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

interface CaptionEditorProps {
  text: string;
  numComments?: number;
  updateCaptionLoading: boolean;
  onUpdateCaption?: (caption: string) => void;
}

export const CaptionEditor: React.FC<CaptionEditorProps> = props => {
  const { text, numComments = 0, updateCaptionLoading, onUpdateCaption } = props;

  const [editingCaption, setEditingCaption] = useState(false);
  const [captionValue, setCaptionValue] = useState(text);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setCaptionValue(text);
  }, [text]);

  const handleEditClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setEditingCaption(true);
    setTimeout(() => textareaRef.current?.focus(), 100);
  };

  const handleSaveCaption = () => {
    if (captionValue !== text) {
      onUpdateCaption?.(captionValue);
    }
    setEditingCaption(false);
  };

  const renderCaptionField = () => {
    if (editingCaption || updateCaptionLoading) {
      return (
        <div className="editor">
          <textarea
            ref={textareaRef}
            readOnly={updateCaptionLoading}
            value={captionValue}
            onChange={e => setCaptionValue(e.target.value)}
            onBlur={() => handleSaveCaption()}
          />
        </div>
      );
    }
    return text;
  };

  return (
    <Root>
      <div className="header">
        <div className="left">
          Caption
          {numComments > 0 ? (
            <>
              <FontAwesomeIcon icon={faCommentAlt} style={{ margin: "0 6px 0 12px" }} />
              {numComments}
            </>
          ) : null}
        </div>
        <div className="right">
          {!editingCaption ? (
            <Button variant="text" onClick={handleEditClick} style={{ minWidth: "auto" }}>
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          ) : null}
        </div>
      </div>
      <div className="text">{renderCaptionField()}</div>
    </Root>
  );
};

const Root = styled.div`
  margin: 0;
  background: ${palette.gray.white};
  border: 1px solid ${palette.gray[10]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    color: ${palette.gray[60]};
    border-bottom: 1px solid ${palette.gray[10]};
    a {
      color: inherit;
    }
  }

  > .text {
    padding: 10px 15px;
    white-space: pre-wrap;
    color: ${palette.gray[80]};
    flex-grow: 1;

    > .editor {
      display: flex;
      flex-direction: column;
      height: 100%;

      > textarea {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        outline: none;
        padding: 10px;
        border: 1px solid ${palette.gray[20]};

        &:read-only {
          background: ${palette.gray[10]};
          border: 0;
        }
      }
    }
  }
`;
