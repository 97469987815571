import { TIMELINE_GROUP_WEIGHT } from "@relatable/helpers/constants";

export const DEFAULT_TIMELINE: { name: string }[][] = [
  [
    { name: "Start-up meetings" },
    { name: "Create brief" },
    { name: "Create influencer list" },
    { name: "Onboarding" }
  ],
  [
    { name: "Logistics (optional task)" },
    { name: "Content creation" },
    { name: "Content approval" }
  ],
  [{ name: "Live" }],
  [
    { name: "Paid (optional task)" },
    { name: "Statistics" },
    { name: "Create report" },
    { name: "Report meeting" }
  ]
] as const;

export const TIMELINE_GROUP_STARTS = TIMELINE_GROUP_WEIGHT.map((_, index) =>
  TIMELINE_GROUP_WEIGHT.slice(0, index).reduce((acc, curr) => acc + curr, 0)
);
