import { type FC, useState } from "react";

import { faMessageLines } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "@mui/material";
import { prettifyDate } from "@relatable/helpers";
import { Avatar } from "@relatable/ui/Avatar";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";

import { Section, SectionSubtitle } from "../user.styled";
import { EditSectionIcon } from "./EditSectionIcon";

const MAX_CHARS = 500;
export const CommentSection: FC<{
  title: string;
  avatarUrl?: string | null;
  authorName?: string | null;
  updatedAt: string | null;
  text: string | null;
  onSave?: (t: string) => void;
}> = ({ text, title, avatarUrl, authorName, updatedAt, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [commentText, setCommentText] = useState(text || "");

  const sectionRef = useOnOutsideClick(() => setIsEditing(false));

  const isAuthorDefined = avatarUrl !== undefined && authorName !== undefined;
  return (
    <Section ref={sectionRef}>
      <div style={{ display: "flex", alignItems: "center", gap: 10, height: 24 }}>
        <FontAwesomeIcon color={palette.gray[50]} icon={faMessageLines} />
        <SectionSubtitle style={{ fontWeight: "bold", color: "black", marginRight: "auto" }}>
          {title}
        </SectionSubtitle>
        {isAuthorDefined && <Avatar size={24} src={avatarUrl} />}
        <SectionSubtitle style={{ fontWeight: "bold" }}>
          {authorName} {prettifyDate(updatedAt)}
        </SectionSubtitle>

        {onSave && <EditSectionIcon isEditing={isEditing} onToggle={() => setIsEditing(p => !p)} />}
      </div>
      <Divider style={{ margin: "10px 0", opacity: 0.4 }} />
      {isEditing ? (
        <>
          <TextInput
            value={commentText}
            placeholder="Write a comment here…"
            onChange={setCommentText}
            multiline
            style={{ width: "100%" }}
            inputProps={{
              style: {
                width: "100%",
                marginBottom: "auto",
                height: 120,
                overflow: "auto",
                fontSize: 12
              }
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
            <p style={{ color: palette.gray[50], fontSize: 12 }}>
              {commentText.length < MAX_CHARS ? (
                <>
                  <b>{MAX_CHARS - commentText.length} </b>
                  <span>characters remaining of {MAX_CHARS}</span>
                </>
              ) : (
                <>
                  <b>{commentText.length - MAX_CHARS} </b>
                  <span>characters too many! max: {MAX_CHARS}</span>
                </>
              )}
            </p>
            <Button
              onClick={() => {
                onSave?.(commentText);
                setIsEditing(false);
              }}
              disabled={!commentText || commentText.length >= MAX_CHARS}
              color="primary"
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <div
          style={{
            height: 120,
            overflow: "auto",
            padding: 10,
            fontSize: 12,
            color: text ? "initial" : palette.gray[50]
          }}
        >
          {text || "No comment yet…"}
        </div>
      )}
    </Section>
  );
};
