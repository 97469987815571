import type { FC } from "react";

import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type AddFields, Table } from "@relatable/ui/Table";
import { Link, useParams } from "react-router-dom";

import { useMessageOverridesQuery } from "./generated";

const MESSAGE_LIST = [
  "campaign-participation-confirmed",
  "campaign-participation-confirmed-youtube",
  "campaign-participation-approved"
].sort((a, b) => a.localeCompare(b));

export const MessageOverride: FC = () => {
  const { campaignStub } = useParams<{ campaignStub: string }>();

  const { data, loading } = useMessageOverridesQuery({
    variables: { campaignStub: campaignStub ?? "" },
    skip: !campaignStub
  });

  const overrides: Record<string, false | string> = data?.campaigns?.[0]?.message_override ?? {};

  return (
    <div>
      <h2>Message overrides</h2>
      <p>
        It is possible on a campaign basis override, or disable, specific &quot;built-in&quot;
        messages like campaign-participation-confirmed. To override with a message with a custom
        one, select and existing campaign message or create a new one.
      </p>
      <Table
        tableId="campaign-message-overrides"
        loading={loading}
        columns={[
          {
            field: "messageId",
            headerName: "Type"
          },
          {
            field: "value",
            headerName: "Message",
            renderCell: ({ row, value }) => {
              if (value === false) return "⛔️ Disabled";
              return (
                <Link to={`/campaign/${campaignStub}/messages/edit/${value ?? row.messageId}`}>
                  {value ?? "Default"}
                </Link>
              );
            }
          },
          {
            field: "actions",
            headerName: "",
            renderCell: ({ row }) => (
              <span>
                <Link to={`${row.messageId}/edit`}>
                  <FontAwesomeIcon icon={faPencil} />
                </Link>
              </span>
            )
          }
        ]}
        rows={MESSAGE_LIST.map(message => {
          const r = {
            key: message,
            messageId: message,
            value: overrides[message]
          };
          return r as AddFields<typeof r, "actions">;
        })}
      />
    </div>
  );
};
