import type { FC } from "react";

import styled from "@emotion/styled";
import { faRobot } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";

const getRole = (username: string, role: string) => {
  if (role === "assistant" || role === "system") {
    return (
      <span>
        <FontAwesomeIcon icon={faRobot} style={{ marginRight: 10 }} />
        Relatable AI
      </span>
    );
  }
  return username;
};

export const Message: FC<{
  username: string;
  userRole: string;
  content: string;
  loading?: boolean;
}> = ({ username, userRole, content, loading }) => {
  return (
    <Root>
      <strong>{getRole(username, userRole)}:</strong>
      <pre>
        {content}
        {loading && "..."}
      </pre>
    </Root>
  );
};

const Root = styled.div`
  padding: 10px;

  > strong {
    color: ${palette.primary.red};
  }

  > pre {
    text-wrap: wrap;
    margin: 6px 0;
  }
`;
