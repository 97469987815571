import { type FC, useCallback, useMemo, useState } from "react";

import { Navigate, Route, Routes, useParams } from "react-router-dom";

import { ErrorPage } from "components/Error";

import { ContentOverviewContainer } from "./ContentOverview/ContentOverviewContainer";
import { OverviewContainer } from "./Overview/OverviewContainer";
import { filterUserContentStatus, transformContentStatus } from "./Overview/common/reshape_data";
import { useContentApprovalOverviewQuery } from "./Overview/generated";
import type { Filter } from "./Overview/types";

export const ContentApproval: FC = () => {
  // overview needs to be fetched here in order to enable stepping on items on the content page
  const { campaignStub } = useParams<{ campaignStub: string }>();

  const [filters, setFilters] = useState<Filter>({
    type: "all",
    state: "all",
    dateInterval: "all"
  });

  const { data, error, loading } = useContentApprovalOverviewQuery({
    variables: { stub: campaignStub ?? "" },
    skip: !campaignStub
  });
  const {
    campaigns: [campaign] = []
  } = data ?? {};

  const usersContentStatus = useMemo(
    () => (data?.campaigns[0] ? transformContentStatus(data?.campaigns[0]) : []),
    [data]
  );

  const workspaceUrl = [
    process.env.CLIENT_PORTAL_URL,
    "client",
    campaign?.project?.clients_hubspot_company_id,
    "p",
    campaign?.project?.stub,
    "c",
    campaignStub,
    "approval?is_admin=true"
  ].join("/");

  const filteredUsersContentStatus = useMemo(
    () =>
      usersContentStatus
        ? filterUserContentStatus(usersContentStatus, {
            filter: filters
          })
        : [],
    [usersContentStatus, filters]
  );

  const handleFiltersChange = useCallback(setFilters, [setFilters]);

  if (error) {
    return <ErrorPage message={error.message} />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <OverviewContainer
            usersContentStatus={usersContentStatus}
            filteredUsersContentStatus={filteredUsersContentStatus}
            loading={loading}
            campaign={campaign}
            workspaceUrl={workspaceUrl}
            filters={filters}
            onSetFilters={handleFiltersChange}
          />
        }
      />

      <Route
        path="/:campaignUserId/:contentSettingId"
        element={
          <ContentOverviewContainer filteredUsersContentStatus={filteredUsersContentStatus || []} />
        }
      />

      <Route element={<Navigate to={`/campaign/${campaignStub}`} />} />
    </Routes>
  );
};
