import { type FC, type ReactElement, useRef, useState } from "react";

import { type IconDefinition, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, MenuItem, Menu as MenuMui, Typography } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { Link } from "react-router-dom";

export interface MenuOption {
  label: string;
  href?: string;
  target?: "_blank";
  onClick?(): void;
  variant?: "danger" | "disabled";
  divider?: boolean;
  hide?: boolean;
  icon?: IconDefinition;
  renderOption?: (option: MenuOption) => JSX.Element;
}

export const Menu: FC<{
  options: MenuOption[];
  shouldStayOpenOnClick?: boolean;
  renderTriggerComponent?: (p: { onClick(): void }) => ReactElement;
}> = ({ options, shouldStayOpenOnClick, renderTriggerComponent }) => {
  const anchorRef = useRef<HTMLSpanElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      {renderTriggerComponent?.({
        onClick() {
          setOpen(true);
        }
      }) || (
        <Button variant="text" onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={faCaretDown} />
        </Button>
      )}

      <span ref={anchorRef} />

      {open && (
        <MenuMui anchorEl={anchorRef.current} open onClose={() => setOpen(false)}>
          {options.map(option => {
            if (option.hide) return null;

            const disabled = option.variant === "disabled";

            const renderItemContent = () => {
              if (option.renderOption) return option.renderOption(option);
              const label = (
                <Typography color={option.variant === "danger" ? "red" : "black"}>
                  {option.icon ? (
                    <FontAwesomeIcon icon={option.icon} style={{ marginRight: 6 }} />
                  ) : null}
                  {option.label}
                </Typography>
              );

              if (!option.href) return label;

              return (
                <Link
                  to={option.href || ""}
                  target={option.target}
                  style={{ display: "block", width: "100%", padding: "6px 16px" }}
                >
                  {label}
                </Link>
              );
            };

            return [
              <MenuItem
                key={option.label}
                disabled={disabled}
                onClick={() => {
                  option.onClick?.();
                  if (!shouldStayOpenOnClick) {
                    setOpen(false);
                  }
                }}
                {...(option.href ? { style: { padding: 0 } } : {})}
              >
                {renderItemContent()}
              </MenuItem>,
              ...(option.divider ? [<Divider key={`divider-${option.label}`} />] : [])
            ];
          })}
        </MenuMui>
      )}
    </>
  );
};
