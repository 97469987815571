import type { FC } from "react";

import { faBackward } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { TextInput } from "@relatable/ui/TextInput";

function validateHTML(htmlString: string) {
  const parser = new DOMParser();
  const html = htmlString.replace(/<br>/g, "<br />");
  const doc = parser.parseFromString(`<p>${html}</p>`, "application/xml");
  const errorNode = doc.querySelector("parsererror");
  return !errorNode;
}

function normalizeHTMLCharacters(htmlString: string) {
  if (!htmlString) return htmlString;

  // replace “smart quotes“ with "normal quotes"
  // eslint-disable-next-line quotes
  return htmlString.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}

export const TextFieldEditor: FC<{
  value: string;
  onChange: (html: string) => void;
  onRichText: () => void;
}> = ({ value, onChange, onRichText }) => {
  const validHTML = validateHTML(value ?? "");

  return (
    <>
      <Button onClick={() => onRichText()} tooltipText="Back to rich text editor">
        <FontAwesomeIcon icon={faBackward} />
      </Button>
      <br />
      <br />

      {!validHTML && (
        <p style={{ color: "red" }}>Warning: You have invalid HTML markup in the body!</p>
      )}

      <TextInput
        label="Body"
        multiline
        value={value ?? ""}
        onChange={html => onChange(normalizeHTMLCharacters(html))}
        inputProps={{ style: { height: 250, overflow: "auto" } }}
        style={{ width: "100%", border: validHTML ? "unset" : "1px solid red" }}
      />
    </>
  );
};
