import { useState } from "react";

import styled from "@emotion/styled";
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
  faCommentAlt,
  faLink
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select } from "@mui/material";
import { prettifyDate } from "@relatable/helpers";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";
import { Link, useParams } from "react-router-dom";

import { getPlatformIcon } from "src/lib/campaigns";
import { StateBadge } from "src/modules/campaign/ContentApproval/common/StateBadge";
import { getTypeLabel } from "src/modules/campaign/ContentApproval/common/utils";

import type { ContentOverviewSettingsQuery } from "../generated";

interface HeaderProps {
  userLabel: string;
  userLink: string;
  profilePicture: string;
  numComments: number;
  type: string;
  link: string;
  platform: NonNullable<ContentOverviewSettingsQuery["setting"]>["campaign"]["platform"];
  state?: CONTENT_APPROVAL_STATE | null;
  publishDateMin: string;
  publishDateMax: string;
  filter: "all" | "relatable" | "client";
  onChangeFilter: (filter: "all" | "relatable" | "client") => void;
  onGoToPrevious?: () => void;
  onGoToNext?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  userLabel,
  userLink,
  profilePicture,
  numComments,
  type,
  link,
  platform,
  state,
  publishDateMin,
  publishDateMax,
  filter,
  onChangeFilter,
  onGoToPrevious,
  onGoToNext
}) => {
  // used to hide avatar if the thumbnail is not valid anymore
  const [avatarShown, setAvatarShown] = useState(true);
  const { campaignStub } = useParams<{ campaignStub: string }>();

  return (
    <Root>
      <div className="left">
        <Block>
          <Link to={`/campaign/${campaignStub}/approval`} style={{ marginRight: 20 }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          <strong>
            <div className="line">
              {profilePicture && avatarShown ? (
                <img
                  className="avatar"
                  src={profilePicture}
                  onError={() => setAvatarShown(false)}
                  alt="Avatar"
                />
              ) : null}
              @{userLabel}
              <Link to={userLink} target="_blank" rel="noreferrer">
                <FontAwesomeIcon style={{ marginLeft: 5 }} icon={getPlatformIcon(platform)} />
              </Link>
            </div>
          </strong>
        </Block>
        <Block>
          <strong>{getTypeLabel({ type, platform })}</strong>
        </Block>
        <Block>
          <StateBadge showIcon state={state} />
        </Block>
        <Block>
          <FontAwesomeIcon icon={faCalendar} style={{ marginRight: 5 }} />
          {prettifyDate(publishDateMin)} - {prettifyDate(publishDateMax)}
        </Block>
        {link ? (
          <Block>
            <Link to={link} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faLink} style={{ marginRight: 5 }} /> 1
            </Link>
          </Block>
        ) : null}
      </div>
      <div className="right">
        <Block>
          <StyledSelect
            variant="outlined"
            value={filter}
            onChange={e => onChangeFilter(e.target.value as HeaderProps["filter"])}
          >
            <MenuItem value="all">All reviews</MenuItem>
            <MenuItem value="relatable">Relatable Review</MenuItem>
            <MenuItem value="client">Client Review</MenuItem>
          </StyledSelect>
        </Block>
        <Block>
          <FontAwesomeIcon icon={faCommentAlt} style={{ marginRight: 5 }} /> {numComments}
        </Block>
        <Block>
          <NavigationArrow
            href="#"
            className={!onGoToPrevious ? "disabled" : ""}
            onClick={e => {
              e.preventDefault();
              if (onGoToPrevious) onGoToPrevious();
            }}
            style={{ marginRight: 25 }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </NavigationArrow>
          <NavigationArrow
            href="#"
            className={!onGoToNext ? "disabled" : ""}
            onClick={e => {
              e.preventDefault();
              if (onGoToNext) onGoToNext();
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </NavigationArrow>
        </Block>
      </div>
    </Root>
  );
};

const Root = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${palette.gray.white};
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  width: calc(100% + 60px);
  height: 56px;
  margin: 0 -30px;
  margin-bottom: 25px;
  border: 1px solid ${palette.gray[10]};
  border-left: 0;
  border-right: 0;

  > .left {
    display: flex;
    justify-content: center;
    position: relative;
  }

  > .right {
    display: flex;
    border-left: 0;
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${palette.gray[10]};
  border-top: 0;
  border-bottom: 0;
  padding: 8px 16px;
  color: ${palette.gray[60]};

  a {
    color: inherit;
    display: flex;
    align-items: center;
  }

  strong {
    color: ${palette.gray[80]};

    > .line {
      display: flex;
      align-items: center;
    }
  }

  &:first-of-type {
    border-left: 0;
  }

  &:last-of-type {
    border-right: 0;
  }

  .avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 20px;
    margin-right: 15px;
  }
`;

const StyledSelect = styled(Select)`
  && {
    height: 40px;
    border-radius: 0;
    border: 1px solid ${palette.gray[40]};
    background: ${palette.gray[10]};

    > .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
`;

const NavigationArrow = styled.a`
  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;
