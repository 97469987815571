import { type FC, useState } from "react";

import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { JsonViewer } from "@textea/json-viewer";

import { Popup } from "components/ui/Popup";

export const CustomDataCell: FC<{ data }> = ({ data }) => {
  const [isPopup, setIsPopup] = useState(false);
  const entries = Object.entries(data || {});
  return (
    <>
      <FontAwesomeIcon
        icon={faCircleInfo}
        onClick={() => {
          if (!entries.length) return;
          setIsPopup(true);
        }}
        color={entries.length ? palette.secondary.blue : palette.gray[50]}
        style={{ cursor: "pointer" }}
      />
      {isPopup && (
        <Popup maxWidth="lg" onClose={() => setIsPopup(false)} title="Custom data">
          {entries.map(([key, value]) => (
            <Note key={key} variant="info" label={key}>
              <JsonViewer value={value as object} displayDataTypes={false} displaySize={false} />
            </Note>
          ))}
        </Popup>
      )}
    </>
  );
};
