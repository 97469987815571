import type { FC } from "react";

import styled from "@emotion/styled";
import { faArrowUpRightFromSquare, faBell, faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prettifyDate } from "@relatable/helpers";
import { CONTENT_TYPES } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";

import { CONTENT_APPROVAL_STATE_MANAGER_LABELS } from "modules/campaign/ContentApproval/common/utils";

import type { Filter } from "../types";

export const Toolbar: FC<{
  workspaceUrl: string;
  uniqueDateIntervals: string[];
  onDownload: (() => void) | null;
  pushNotificationToClient: () => void;
  notifyClientLoading: boolean;
  onSetFilters: (f: Filter) => void;
  filters: Filter;
}> = ({
  workspaceUrl,
  uniqueDateIntervals,
  onDownload,
  pushNotificationToClient,
  notifyClientLoading,
  filters,
  onSetFilters
}) => {
  const parsedIntervals = uniqueDateIntervals.map(interval => {
    const [min, max] = interval.split(",");
    return { min, max, value: interval };
  });

  return (
    <Root>
      <Select
        value={filters.type}
        onChange={v => onSetFilters({ ...filters, type: v || "" })}
        label="Content type"
        options={[
          { label: "All", value: "all" },
          ...Object.values(CONTENT_TYPES).map(type => ({ label: type.label, value: type.value }))
        ]}
        style={{ flexGrow: 1 }}
      />
      <Select
        value={filters.state}
        onChange={v => onSetFilters({ ...filters, state: v || "" })}
        label="Content state"
        options={[
          { label: "All", value: "all" },
          ...Object.keys(CONTENT_APPROVAL_STATE_MANAGER_LABELS).map(value => ({
            label: CONTENT_APPROVAL_STATE_MANAGER_LABELS[value],
            value
          }))
        ]}
        style={{ flexGrow: 1 }}
      />
      <Select
        value={filters.dateInterval}
        onChange={v => onSetFilters({ ...filters, dateInterval: v || "" })}
        label="Scheduled dates"
        options={[
          { label: "All", value: "all" },
          ...parsedIntervals.map(({ min, max, value }) => ({
            label: `${prettifyDate(min)} - ${prettifyDate(max)}`,
            value
          }))
        ]}
        style={{ flexGrow: 1 }}
      />
      <div className="right-column">
        {onDownload ? (
          <Button icon={<FontAwesomeIcon icon={faDownload} />} onClick={onDownload}>
            Download
          </Button>
        ) : null}
        <Button
          icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          href={workspaceUrl}
          target="_blank"
        >
          View Client Workspace
        </Button>
        <Button
          icon={<FontAwesomeIcon icon={faBell} />}
          onClick={pushNotificationToClient}
          disabled={notifyClientLoading}
        >
          Push notification to client
        </Button>
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .right-column {
    display: flex;
    gap: 20px;
  }

  @media only screen and (max-width: 568px) {
    flex-direction: column;
    align-items: center;
  }

  .MuiInput-formControl {
    background: ${palette.gray[10]};
  }

  .MuiInputLabel-formControl {
    margin-top: -16px;
  }
`;
