import { type FC, useState } from "react";

import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import type { Campaign_User_State_Enum, Platform_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Note } from "@relatable/ui/Note";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Popup } from "components/ui/Popup";
import { CAMPAIGN_USER_STATE_DISPLAY_NAMES, CAMPAIGN_USER_STATE_TYPES } from "lib/constants";
import { useSendEmailToCampaignUserMutation } from "modules/generated";
import { useSlack } from "src/hooks/useSlack";

import { CampaignParticipantsDocument } from "../Participants/generated";
import { useUpdateCampaignUserStateMutation } from "../generated";

export const StateCell: FC<{
  state?: Campaign_User_State_Enum | null;
  campaignUserId?: number | null;
  campaignId: number;
  userLabel: string;
  campaignTitle: string;
  platform: Platform_Enum | null | undefined;
}> = ({ state, campaignUserId, campaignId, userLabel, campaignTitle, platform }) => {
  const snackbar = useSnackbar();
  const [isPopup, setIsPopup] = useState(false);
  const [selectedState, setSelectedState] = useState(state);
  const [emailNotification, setEmailNotification] = useState(true);
  const [slackNotification, setSlackNotification] = useState(true);

  const [updateCampaignUserState] = useUpdateCampaignUserStateMutation({
    onCompleted: () => snackbar.success("User has been successfully updated"),

    refetchQueries: [CampaignParticipantsDocument],
    awaitRefetchQueries: true
  });

  const { sendSlackNotification } = useSlack();
  const [sendEmail] = useSendEmailToCampaignUserMutation();

  if (typeof campaignUserId !== "number") {
    return <Typography color="red">Not in the campaign</Typography>;
  }

  const notificationsStatus = selectedState === "approved" || selectedState === "confirmed";
  return (
    <>
      <Button variant="text" onClick={() => setIsPopup(true)}>
        <span style={{ width: "100%", textAlign: "left", textDecoration: "underline" }}>
          {state ? CAMPAIGN_USER_STATE_DISPLAY_NAMES[state] : "Not set"}
        </span>
      </Button>

      {isPopup && (
        <Popup
          actionLabel="Change to selected state"
          actionIcon={<FontAwesomeIcon icon={faSave} />}
          title="Change campaign user's state"
          onClose={() => setIsPopup(false)}
          onAction={async () => {
            if (typeof campaignId !== "number") throw new Error("Missing campaignId");
            if (typeof campaignUserId !== "number") throw new Error("Missing campaignUserId");
            if (!platform) throw new Error("Missing platform");

            const emailTemplate: Record<"approved" | "confirmed", Record<Platform_Enum, string>> = {
              approved: {
                instagram: "campaign-participation-approved",
                youtube: "campaign-participation-approved",
                tiktok: "campaign-participation-approved",
                snapchat: "campaign-participation-approved"
              },
              confirmed: {
                instagram: "campaign-participation-confirmed",
                youtube: "campaign-participation-confirmed-youtube",
                tiktok: "campaign-participation-confirmed",
                snapchat: "campaign-participation-confirmed"
              }
            };

            await Promise.all([
              updateCampaignUserState({
                variables: {
                  campaignUserId,
                  state: selectedState ?? null,
                  appendedLog: {
                    state_log: { [selectedState ?? "null"]: new Date().toISOString() }
                  }
                }
              }),
              slackNotification &&
                notificationsStatus &&
                sendSlackNotification({
                  variables: {
                    campaignId,
                    channel: "campaigns-onboarding",
                    text: `Yay, *@${userLabel}* just has been updated manually to *${CAMPAIGN_USER_STATE_DISPLAY_NAMES[selectedState]}* for participation in *${campaignTitle}*.`
                  }
                }),
              emailNotification &&
                notificationsStatus &&
                sendEmail({
                  variables: {
                    campaignUserId,
                    template: emailTemplate[selectedState][platform]
                  }
                })
            ]);
            setIsPopup(false);
          }}
        >
          <Select
            label="Selected state"
            onChange={setSelectedState}
            value={selectedState}
            options={CAMPAIGN_USER_STATE_TYPES.map(label => ({
              label: CAMPAIGN_USER_STATE_DISPLAY_NAMES[label],
              value: label
            }))}
          />
          {notificationsStatus && (
            <Checkbox
              checked={emailNotification}
              onChange={setEmailNotification}
              label="Trigger email notification"
            />
          )}
          {notificationsStatus && (
            <Checkbox
              checked={slackNotification}
              onChange={setSlackNotification}
              label="Trigger slack notification"
            />
          )}
          {selectedState === "approved" && (
            <Note variant="info" label="Automatic payment">
              When approving a creator you complete the payment
            </Note>
          )}
        </Popup>
      )}
    </>
  );
};
