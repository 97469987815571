import type { FC } from "react";

import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { Switch } from "@relatable/ui/Switch";

export const UpdateStats: FC<{
  onUpdate(): Promise<any>;
  lockStats?: {
    lockedAt: string | null | undefined;
    onToggle(v: boolean): void;
    isTokenValid: boolean;
  };
}> = ({ onUpdate, lockStats }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        variant="text"
        icon={<FontAwesomeIcon icon={faArrowsRotate} />}
        onAsyncClick={onUpdate}
      >
        Update
      </Button>

      {lockStats && (
        <>
          <span style={{ width: 1, height: 30, background: palette.gray[70], opacity: 0.2 }} />
          <Switch
            color={lockStats.isTokenValid ? "success" : "warning"}
            onChange={v => lockStats.onToggle(!v)}
            value={!lockStats.lockedAt}
            tooltip={
              lockStats.lockedAt
                ? "Statistics LOCKED"
                : lockStats.isTokenValid
                  ? "API statistics ON"
                  : "Manual statistics ON"
            }
          />
        </>
      )}
    </div>
  );
};
