import { type FC, useCallback, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { getUserLabel } from "@relatable/helpers/user";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import type { CreatorListItemsQuery } from "../generated";
import { CreatorListItemHeader } from "./CreatorListItemHeader";
import { CreatorListItemStats } from "./CreatorListItemStats";

const Root = styled.div<{ $selected?: boolean; $deleting?: boolean }>`
  position: relative;
  margin-top: 15px;
  border-radius: 10px;
  padding: 8px 12px 12px 12px;
  min-height: 95px;
  text-align: ${({ $deleting }) => ($deleting ? "center" : "left")};

  border: ${({ $selected, $deleting }) =>
    $selected
      ? `1px solid ${palette.secondary.blue}`
      : $deleting
        ? `1px solid ${palette.primary.red}`
        : 0};

  background: ${({ $selected, $deleting }) =>
    $selected ? palette.tertiary.blue : $deleting ? palette.tertiary.red : palette.gray.white};

  &:nth-of-type(odd) {
    background: ${({ $selected, $deleting }) =>
      $selected ? palette.tertiary.blue : $deleting ? palette.tertiary.red : palette.gray[10]};
  }

  > .undo-button {
    margin-top: 10px;
  }
`;

const SNotes = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(255 237 237 / 95%);
  top: 0;
  left: 0;
  color: ${palette.secondary};
  font-weight: bold;
  padding-left: 30px;
  padding-right: 10px;
  color: ${palette.gray.black};
  border: 1px dotted ${palette.primary.red};
`;
const RemoveButton = styled(Button)`
  position: absolute;
  left: -17px;
  top: 50%;
  z-index: 3;
  margin-top: -15px;
  min-width: 30px;
  padding: 8px;
  border-radius: 15px;
  margin-left: 7px;
  font-size: 14px;
`;
export const CreatorListItem: FC<{
  user: CreatorListItemsQuery["users"][number];
  selected: boolean;
  onSelect: () => void;
  onRemove?: () => Promise<void>;
  showListSelector?: boolean;
  showPlatform?: boolean;
}> = ({ user, selected, onSelect, onRemove, showListSelector, showPlatform }) => {
  const snackbar = useSnackbar();
  const [deleteTimer, setDeleteTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const onRemoveHandler = useCallback(async () => {
    if (!onRemove) return;

    const campaignUsers = user.campaign_users;
    if (campaignUsers?.some(cu => cu.state)) {
      snackbar.error(
        "Cannot remove user which already has a state in one of the campaigns. If you are sure to delete the user you can update the state to 'none' on the participants page first and then try again."
      );
      return;
    }

    setDeleteTimer(
      setTimeout(async () => {
        setLoadingDelete(true);
        await onRemove();
      }, 5000)
    );
  }, [snackbar, user, onRemove]);

  useEffect(() => {
    if (!selected) return () => null;
    const handler = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        onRemoveHandler();
      }
    };

    window.addEventListener("keydown", handler);
    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, [selected, onRemoveHandler]);

  const onCancelDelete = () => {
    if (deleteTimer) {
      clearTimeout(deleteTimer);
    }
    setDeleteTimer(null);
  };

  if (!user) return null;

  if (deleteTimer) {
    return (
      <Root key={user.id} $deleting style={{ paddingTop: 12 }}>
        @{getUserLabel(user)} is being removed from the list. Made a mistake?
        <br />
        {!loadingDelete ? (
          <Button className="undo-button" onClick={onCancelDelete}>
            Click here to undo
          </Button>
        ) : (
          <CircularProgress size={30} style={{ marginTop: 12 }} />
        )}
      </Root>
    );
  }

  // we only check the most recent campaign, sorting happened on gql already
  const lowRatedUser =
    user.campaign_users[0]?.collaboration_score === 1 ? user.campaign_users[0] : null;

  return (
    <div style={{ position: "relative" }}>
      <Root key={user.id} $selected={selected && !lowRatedUser} onClick={() => onSelect()}>
        <CreatorListItemHeader
          user={user}
          showListSelector={showListSelector}
          showPlatform={showPlatform}
          lowRatedUser={lowRatedUser}
        />
        {lowRatedUser && <SNotes>{getUserLabel(user)} is rejected by the production team.</SNotes>}

        <CreatorListItemStats user={user} />
      </Root>
      {(selected || lowRatedUser) && onRemove && (
        <RemoveButton color="primary" className="remove-button" onClick={() => onRemoveHandler()}>
          <FontAwesomeIcon icon={faTrash} color="white" />
        </RemoveButton>
      )}
    </div>
  );
};
