import { type FC, useEffect, useState } from "react";

import { faSpinner, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";
import { TextInputWithIcon } from "@relatable/ui/TextInput";

import { CURRENCIES } from "src/lib/constants";

const useExchangeRateField = ({ currency }: { currency: string | null | undefined }) => {
  const [rates, setRates] = useState<{
    [key: string]: {
      alphaCode: string;
      code: string;
      date: string;
      inverseRate: number;
      name: string;
      numericCode: string;
      rate: number;
    };
  }>();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    try {
      setIsLoading(true);
      fetch("https://www.floatrates.com/daily/sek.json").then(r => r.json().then(setRates));
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const rate =
    currency === CURRENCIES.SEK
      ? 1
      : rates && Object.values(rates).find(r => r.code === currency)?.rate;
  return {
    rate: rate || null,
    isLoading,
    error
  };
};

export const ExchangeRate: FC<{
  currency: string | null | undefined;
  onChange(v: number): void;
}> = ({ currency, onChange }) => {
  const { error, isLoading, rate } = useExchangeRateField({ currency });

  useEffect(() => {
    if (!rate) return;
    onChange(rate);
  }, [rate, onChange]);

  return (
    <div>
      <TextInputWithIcon
        disabled
        label="Exchange rate SEK"
        type="number"
        onChange={() => null}
        value={String(rate)}
        icon={
          isLoading || error ? (
            <FontAwesomeIcon
              color={isLoading ? palette.gray[80] : palette.primary.red}
              icon={isLoading ? faSpinner : faTimesCircle}
              {...(error && { title: error })}
            />
          ) : null
        }
      />
    </div>
  );
};
