import styled from "@emotion/styled";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Root = styled.div`
  padding: 40px;

  > h3 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 60px;

    > .icon {
      display: block;
      margin: 20px auto;
      font-size: 40px;
    }
  }

  > ol {
    > li {
      padding: 10px 0;
    }
  }
`;

export const Help: React.FC = () => {
  return (
    <Root>
      <h3>
        <FontAwesomeIcon className="icon" icon={faCircleExclamation} />
        Preview not available
      </h3>
      <p>Follow the these steps to fix it:</p>
      <ol>
        <li>
          Install the{" "}
          <Link to="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">
            Firefox web browser
          </Link>
          . If you already have it,{" "}
          <Link
            to="https://support.mozilla.org/en-US/kb/refresh-firefox-reset-add-ons-and-settings"
            target="_blank"
            rel="noreferrer"
          >
            reset it to default settings
          </Link>
          .
        </li>
        <li>
          Open{" "}
          <Link to="https://instagram.com" target="_blank" rel="noreferrer">
            instagram.com
          </Link>{" "}
          and log in with your Instagram account created for work. <strong>Do not use</strong> your
          personal Instagram account.
        </li>
        <li>
          Install the{" "}
          <Link
            to="https://addons.mozilla.org/en-US/firefox/addon/allow-sso-iframes"
            target="_blank"
            rel="noreferrer"
          >
            browser extension
          </Link>
          . This disables some security features in Firefox, so make sure you{" "}
          <strong>only use the browser for creator curation</strong> while the extension is
          activated.
        </li>
        <li>
          Open the{" "}
          <Link to={`${process.env.CAMPAIGN_BASE_URL}/curation`} target="_blank" rel="noreferrer">
            Campaign Manager app
          </Link>{" "}
          and log in with your Relatable account.
        </li>
        <li>
          Click on the shield icon near the URL and <strong>disable</strong> Enhanced Tracking
          Protection.
        </li>
        <li>Refresh the page.</li>
      </ol>
    </Root>
  );
};
