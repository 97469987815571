import { type FC, useState } from "react";

import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import { Popup } from "components/ui/Popup";
import { useUpdateCampaignPostMutation } from "modules/campaign/Content/generated";

import { CampaignPostsTableDocument, useUpdateCampaignReelMutation } from "./generated";

export const NoteCell: FC<{
  postId?: number;
  reelId?: number;
  note: string;
}> = props => {
  const snackbar = useSnackbar();
  const [isPopup, setIsPopup] = useState(false);
  const [note, setNote] = useState(props.note || "");

  const [updatePost] = useUpdateCampaignPostMutation({
    onCompleted: () => {
      snackbar.success("The note has been successfully updated");
      setIsPopup(false);
    },
    onError: () => {
      snackbar.error("Something went wrong");
    },
    refetchQueries: [CampaignPostsTableDocument],
    awaitRefetchQueries: true
  });

  const [updateReel] = useUpdateCampaignReelMutation({
    onCompleted: () => {
      snackbar.success("The note has been successfully updated");
      setIsPopup(false);
    },
    onError: () => {
      snackbar.error("Something went wrong");
    },
    refetchQueries: [CampaignPostsTableDocument],
    awaitRefetchQueries: true
  });

  return (
    <>
      {note || "-"}
      &nbsp;
      <FontAwesomeIcon
        icon={faEdit}
        color={props.note ? palette.secondary.blue : palette.gray[50]}
        onClick={() => setIsPopup(true)}
        style={{ cursor: "pointer" }}
      />
      {isPopup && (
        <Popup
          title="Prod note"
          onClose={() => setIsPopup(false)}
          actionLabel="Save"
          onAction={() => {
            if (props.postId) {
              updatePost({
                variables: { postId: props.postId, _set: { notes: note } }
              });
            }
            if (props.reelId) {
              updateReel({ variables: { reelId: props.reelId, set: { notes: note } } });
            }
          }}
        >
          <TextInput multiline value={note} onChange={setNote} label="Note" />
        </Popup>
      )}
    </>
  );
};
