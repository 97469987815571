import type { FC } from "react";

import styled from "@emotion/styled";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { faBookmark } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { palette } from "@relatable/ui/Palette";

import { getBookmarkletScript } from "./script";

const Root = styled.div`
  text-align: right;
  > span {
    > a {
      color: ${palette.gray[60]};
    }
  }
`;

export const Bookmarklet: FC<{ listId: number; listName: string; platform: Platform_Enum }> = ({
  listId,
  listName,
  platform
}) => {
  const URLScript = getBookmarkletScript({ listId, platform });

  const iconHTML = icon(faBookmark).html[0];

  const bookmarkletHTML = [
    `<a href="javascript:${window.encodeURIComponent(URLScript)}">`,
    `${iconHTML}`,
    `<span style="display: none">Add to "${listName}" list</span>`,
    "</a>"
  ].join("");

  return (
    <Root>
      <Tooltip title="Bookmarklet - Drag this icon to the bookmarks bar">
        <span
          // React throws errors for javascript: urls when used without dangerouslySetInnerHTML
          // biome-ignore lint/security/noDangerouslySetInnerHtml:
          dangerouslySetInnerHTML={{
            __html: bookmarkletHTML
          }}
        />
      </Tooltip>
    </Root>
  );
};
