import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faAdd } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TIMELINE_GROUP_WEIGHT } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import type { TimelineItem } from ".";
import { TimelineRow } from "./TimelineRow";

export const TimelineGroup: FC<{
  groupItems: TimelineItem[];
  onAdd: (groupId: number) => Promise<void>;
  onInputChange: (
    item: TimelineItem,
    field: keyof TimelineItem,
    value: TimelineItem[typeof field]
  ) => void;
  onDelete: (item: TimelineItem) => void;
  onMoveUp: (item: TimelineItem) => void;
}> = ({ groupItems, onAdd, onInputChange, onDelete, onMoveUp }) => {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const groupWeight = TIMELINE_GROUP_WEIGHT[groupItems[0].group];
  if (groupWeight === undefined) throw new Error("Invalid group");
  const percentage = groupWeight / 100 / groupItems.length;

  const handleAdd = async () => {
    setLoadingAdd(true);
    await onAdd(groupItems[0].group);
    setLoadingAdd(false);
  };

  return (
    <Root>
      <div className="rows">
        {groupItems.map(item => (
          <TimelineRow
            key={item.id}
            item={item}
            percentage={percentage}
            onInputChange={onInputChange}
            canDelete={groupItems.length > 1}
            onDelete={onDelete}
            canMoveUp={groupItems.indexOf(item) > 0}
            onMoveUp={onMoveUp}
          />
        ))}

        <Button
          icon={<FontAwesomeIcon icon={faAdd} />}
          variant="text"
          isLoading={loadingAdd}
          className="add-button"
          onClick={handleAdd}
        >
          Add item
        </Button>
      </div>
    </Root>
  );
};

const Root = styled.div`
  margin-bottom: 20px;

  > .rows {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 14px 18px;
    align-items: start;
    border: 1px solid ${palette.gray[20]};

    > .add-button {
      display: flex;
    }
  }
`;
