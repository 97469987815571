import { useState } from "react";

import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import { prettifyNumber } from "@relatable/helpers";
import { Table, type TableColumn } from "@relatable/ui/Table";

import type { RowReturnValue } from "../TiktokReport/utils/rows";

const extendWithPercentageSign = (str?: string | number) => {
  if (!str) return str ?? "";
  if (typeof str === "string" && str.endsWith("%")) return str;
  return `${str}%`;
};

export const SectionTable: React.FC<{
  sectionName: string;
  rows: RowReturnValue[];
  defaultOpen?: boolean;
  showHeaders?: boolean;
}> = ({ sectionName, rows, defaultOpen = true, showHeaders = true }) => {
  const [open, setOpen] = useState(defaultOpen);
  const headerValues = rows.reduce<string[]>(
    (acc, row) => Array.from(new Set([...acc, ...Object.keys(row)])),
    []
  );

  const tableRows = open
    ? rows.map(row => ({
        key: row.name ?? Date.now().toString(), // sometimes name is missing
        name: row.name,
        size: row.size,
        value: row.value,
        percentage: row.percentage
      }))
    : [];

  const columns: TableColumn<typeof tableRows>[] = [
    {
      field: "name",
      headerName: sectionName
    }
  ];

  if (open && headerValues.includes("size")) {
    columns.push({
      field: "size",
      headerName: "Est. size",
      renderCell: ({ value }) => (typeof value === "string" ? value : prettifyNumber(value))
    });
  }

  if ((open && headerValues.includes("value")) || headerValues.includes("percentage")) {
    columns.push({
      field: "percentage",
      headerName: "Percentage",
      renderCell: ({ row, value }) =>
        value ? extendWithPercentageSign(value) : <em>{row.value}</em>
    });
  }

  return (
    <Accordion expanded={open} onChange={() => setOpen(prev => !prev)}>
      {showHeaders && (
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
          style={{ padding: "0 20px" }}
        >
          <Typography>{sectionName}</Typography>
        </AccordionSummary>
      )}

      <Table
        containerStyles={{ marginBottom: 40 }}
        tableId="hypeauditor-section-table"
        rows={tableRows}
        columns={columns}
      />
    </Accordion>
  );
};
