import type { FC } from "react";

import { faFilter } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemIcon } from "@mui/material";
import type { Campaign_User_State_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";

import { Menu } from "components/ui/Menu";
import {
  CAMPAIGN_USER_ALL_DISPLAY_NAMES,
  CAMPAIGN_USER_EXTENDED_STATE_TYPES
} from "src/lib/constants";
import type { CampaignUserExtendedState } from "src/typings/global";

export const FilterStateMenu: FC<{
  userStates: (Campaign_User_State_Enum | null | undefined)[];
  userStateFilterBy: CampaignUserExtendedState[];
  resetUserStateFilterBy: () => void;
  toggleUserStateFilterBy: (v: CampaignUserExtendedState) => void;
}> = ({ userStates, userStateFilterBy, resetUserStateFilterBy, toggleUserStateFilterBy }) => {
  const stateCounts: Partial<Record<CampaignUserExtendedState, number>> = (userStates || []).reduce(
    (acc, row) => {
      if (row === undefined) {
        acc.not_in_the_campaign++;
        return acc;
      }
      if (row === null) {
        acc.not_set++;
        return acc;
      }

      if (!acc[row]) acc[row] = 0;
      acc[row]++;
      return acc;
    },
    { not_in_the_campaign: 0, not_set: 0 }
  );

  return (
    <Menu
      shouldStayOpenOnClick
      renderTriggerComponent={({ onClick }) => (
        <Button
          icon={<FontAwesomeIcon icon={faFilter} />}
          variant={userStateFilterBy.length === 0 ? "outlined" : "contained"}
          onClick={onClick}
        >
          Filter by state
        </Button>
      )}
      options={(() => [
        {
          label: `All [${userStates.length}]`,
          onClick: () => resetUserStateFilterBy(),
          renderOption: option => (
            <>
              <ListItemIcon>
                {userStateFilterBy.length === 0 ? <FontAwesomeIcon icon={faCheck} /> : null}
              </ListItemIcon>
              {option.label}
            </>
          )
        },
        ...CAMPAIGN_USER_EXTENDED_STATE_TYPES.map(state => {
          return {
            label: `${CAMPAIGN_USER_ALL_DISPLAY_NAMES[state]} [${stateCounts[state]}]`,
            hide: !stateCounts[state],
            onClick: () => toggleUserStateFilterBy(state),
            renderOption: option => (
              <>
                <ListItemIcon>
                  {userStateFilterBy.includes(state) ? <FontAwesomeIcon icon={faCheck} /> : null}
                </ListItemIcon>
                {option.label}
              </>
            )
          };
        })
      ])()}
    />
  );
};
