import styled from "@emotion/styled";
import { faArrowDown, faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBefore } from "@relatable/helpers";
import {
  clientApproved,
  creatorDone,
  managerApproved,
  normalizeState
} from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import type { ContentOverviewProps } from "../ContentOverview";
import type { ContentMediaQuery } from "../generated";
import { ContentDisplay } from "./ContentDisplay";
import { ContentPieceState } from "./ContentPieceState";

interface ContentProps {
  media: ContentMediaQuery["medias"][0];
  moveUp: () => void;
  moveDown: () => void;
  index: number;
  numSlides: number;
  numComments: number;
  filter: "all" | "relatable" | "client";
  loading: boolean;
  onUpdateState?: ContentOverviewProps["onUpdateState"];
  onWriteMessage?: (target: "creator" | "client", text: string) => void;
  updateCaptionLoading: boolean;
  onUpdateCaption?: (caption: string) => void;
  onDelete?: () => void;
}

export const Content: React.FC<ContentProps> = ({
  media,
  moveUp,
  moveDown,
  index,
  numSlides,
  numComments,
  filter,
  loading,
  onUpdateState,
  onWriteMessage,
  updateCaptionLoading,
  onUpdateCaption,
  onDelete
}) => {
  const onMoveUp = (e: React.MouseEvent) => {
    e.preventDefault();
    moveUp();
  };

  const onMoveDown = (e: React.MouseEvent) => {
    e.preventDefault();
    moveDown();
  };

  // it shouldn't show the content item if the client filtering is active and client state is "N/A"
  if (filter === "client") {
    if (
      !creatorDone(media?.content_preapproval_logs[0]?.state) ||
      !managerApproved(media?.content_preapproval_logs[0]?.state)
    ) {
      return null;
    }
  }

  const getHeaderVariant = () => {
    const state = normalizeState(media.content_preapproval_logs[0]?.state);

    if (filter === "all") {
      if (
        state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED ||
        state === CONTENT_APPROVAL_STATE.CLIENT_REJECTED
      ) {
        return "rejected";
      }
      if (state === CONTENT_APPROVAL_STATE.MANAGER_REVIEWING) return null;
      return "approved";
    } else if (filter === "client") {
      if (state === CONTENT_APPROVAL_STATE.CLIENT_REJECTED) return "rejected";
      if (clientApproved(state)) return "approved";
      return null;
    } else if (filter === "relatable") {
      if (state === CONTENT_APPROVAL_STATE.MANAGER_REJECTED) return "rejected";
      if (managerApproved(state)) return "approved";
      return null;
    }
    return null;
  };

  const mergedLogs = media
    ? [...media.logs, ...(media.caption?.logs ?? [])].sort((a, b) =>
        isBefore(b.created_at, a.created_at) ? -1 : 1
      )
    : [];

  const renderMoveIcons = () => {
    if (numSlides < 2) return null;
    const canMoveUp = filter !== "client" && moveUp;
    const canMoveDown = filter !== "client" && moveDown;

    const tooltipTextInfo =
      filter === "client" ? " The order can be changed only when the filter is inactive." : "";

    return (
      <div className="icons">
        <Button
          className={canMoveUp ? "button" : "button disabled"}
          onClick={canMoveUp ? onMoveUp : () => null}
          tooltipText={`Move up${tooltipTextInfo}`}
          variant="text"
          style={{ padding: 0, minWidth: "auto" }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>

        <Button
          className={canMoveDown ? "button" : "button disabled"}
          onClick={canMoveDown ? onMoveDown : () => null}
          tooltipText={`Move down${tooltipTextInfo}`}
          variant="text"
          style={{ padding: 0, minWidth: "auto" }}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </Button>
      </div>
    );
  };

  return (
    <Root>
      <div className="left">
        {renderMoveIcons()}
        <ContentDisplay
          key={media.id}
          url={media.url || ""}
          thumbnail={media.thumbnail || ""}
          isVideo={Boolean(media.isVideo)}
          index={index}
          numSlides={numSlides}
          numComments={numComments}
          loading={loading}
          caption={media.caption?.text || ""}
          variant={getHeaderVariant()}
          updateCaptionLoading={updateCaptionLoading}
          onUpdateCaption={onUpdateCaption}
          filenameHistory={(media.filename_history as string[]) || []}
        />
      </div>
      <div className="right">
        {!loading ? (
          <ContentPieceState
            captionText={media.caption?.text || ""}
            legacyInfluencerComment={media.legacy_influencer_comment}
            logs={mergedLogs}
            filter={filter}
            state={normalizeState(media.content_preapproval_logs[0]?.state)}
            onWriteMessage={onWriteMessage}
            onDelete={media.deleted ? undefined : onDelete}
            onUpdateState={
              onUpdateState
                ? (author, newState) =>
                    onUpdateState({
                      author,
                      type: "media",
                      id: media.id,
                      newState
                    })
                : undefined
            }
          />
        ) : null}
      </div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  margin-bottom: 25px;

  > .left {
    width: 50%;
    background: ${palette.gray[10]};
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    position: relative;

    > .icons {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 16px;
      .button {
        color: ${palette.gray[60]};
        margin-bottom: 30px;
        font-size: 16px;

        &.disabled {
          color: ${palette.gray[20]};
          cursor: default;
        }
      }
    }
  }

  > .right {
    width: 50%;
    padding: 0 25px;
    border: 1px solid ${palette.gray[10]};
    border-left: 0;
  }
`;
