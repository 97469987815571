import { faInstagram, faSnapchat, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { getPlatformLabel } from "@relatable/gql";
import type { Platform_Enum } from "@relatable/gql/generated-base";

import { PLATFORMS } from "./constants";

export const getPlatformIcon = (platform: Platform_Enum | null | undefined) => {
  switch (platform) {
    case "instagram":
      return faInstagram;
    case "youtube":
      return faYoutube;
    case "tiktok":
      return faTiktok;
    case "snapchat":
      return faSnapchat;
    default:
      throw new Error(`Invalid platform ${platform}`);
  }
};

export const platformOptions = PLATFORMS.map(value => ({
  label: getPlatformLabel(value),
  value
}));
