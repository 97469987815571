import type { FC } from "react";

import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";

export const EditSectionIcon: FC<{ isEditing: boolean; onToggle(): void }> = ({
  isEditing,
  onToggle
}) => {
  if (isEditing) {
    return (
      <FontAwesomeIcon
        style={{ cursor: "pointer", width: 14 }}
        color={palette.primary.red}
        icon={faCheck}
        onClick={onToggle}
      />
    );
  }

  return (
    <FontAwesomeIcon
      style={{ cursor: "pointer", width: 14 }}
      color={palette.gray[50]}
      icon={faPen}
      onClick={onToggle}
    />
  );
};
