import { useMemo } from "react";

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { getContentAggregateState, prettifyDate } from "@relatable/helpers";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { getPlatformIcon } from "src/lib/campaigns";

import { StateBadge } from "../common/StateBadge";
import { getTypeLabel } from "../common/utils";
import { ContentApprovalProgress } from "./ContentApprovalProgress";
import { Toolbar } from "./common/Toolbar";
import { type UserContentStatus, getProgressData } from "./common/reshape_data";
import type { Filter } from "./types";

export const Overview: React.FC<{
  platform: Platform_Enum | null | undefined;
  onDownload: () => void;
  onRowClick: (row: { userId: number; contentSettingId: number }) => void;
  usersContentStatus: UserContentStatus[];
  filteredUsersContentStatus: UserContentStatus[];
  workspaceUrl: string;
  pushNotificationToClient: () => void;
  onSetFilters: (f: Filter) => void;
  filters: Filter;
  notifyClientLoading: boolean;
}> = ({
  platform,
  onDownload,
  onRowClick,
  usersContentStatus,
  filteredUsersContentStatus,
  workspaceUrl,
  pushNotificationToClient,
  notifyClientLoading,
  onSetFilters,
  filters
}) => {
  const {
    uniqueParticipantsCount,
    approvedContentCount,
    submittedContentCount,
    approvedParticipantsCount
  } = useMemo(() => getProgressData(usersContentStatus), [usersContentStatus]);

  const uniqueDateIntervals = Array.from(
    new Set(usersContentStatus.map(row => `${row.publish_date_min},${row.publish_date_max}`))
  ).sort();

  const rows = filteredUsersContentStatus.map(row => {
    const state = (() => {
      const itemStates: (string | null | undefined)[] = [];
      if (row.content_approval_state?.content_preapproval_caption) {
        itemStates.push(
          row.content_approval_state.content_preapproval_caption.content_preapproval_logs[0]?.state
        );
      }
      if (
        Array.isArray(row.content_approval_state?.campaign_preapproval_content_medias) &&
        row.content_approval_state?.campaign_preapproval_content_medias.length > 0
      ) {
        itemStates.push(
          ...row.content_approval_state.campaign_preapproval_content_medias.map(
            m => m.content_preapproval_logs[0]?.state
          )
        );
      }
      return getContentAggregateState(itemStates);
    })();

    return {
      key: `${row.user_id}${row.content_setting_id}`,
      userLabel: row.userLabel,
      userLink: row.userLink,
      type: row.type,
      state,
      publishDateMin: row.publish_date_min,
      publishDateMax: row.publish_date_max,
      userId: row.user_id,
      contentId: row.content_id,
      contentSettingId: row.content_setting_id,
      contentSettingName: row.contentSettingName,
      isSubHeader: false
    };
  });

  const groupedRows = rows.reduce<Record<number, typeof rows>>((acc, item) => {
    if (!item.contentSettingId) return acc;
    return {
      ...acc,
      [item.contentSettingId]: acc[item.contentSettingId]
        ? [...acc[item.contentSettingId], item]
        : [item]
    };
  }, {});

  const columns: TableColumn<typeof rows>[] = [
    {
      field: "userLabel",
      headerName: "Participant",
      renderCell: ({ value, row }) =>
        row.isSubHeader ? (
          <span>
            <span style={{ fontWeight: "bold", fontSize: 18, marginRight: 10 }}>
              {row.contentSettingName}
            </span>

            <span>
              {row.publishDateMax === row.publishDateMin
                ? prettifyDate(row.publishDateMin)
                : `${prettifyDate(row.publishDateMin)} : ${prettifyDate(row.publishDateMax)}`}
            </span>
          </span>
        ) : (
          <>
            {value}
            <StyledLink onClick={e => e.stopPropagation()} to={row.userLink} target="_blank">
              <FontAwesomeIcon style={{ marginLeft: 5 }} icon={getPlatformIcon(platform)} />
            </StyledLink>
          </>
        )
    },
    {
      field: "type",
      headerName: "Content type",
      renderCell: ({ value, row }) =>
        row.isSubHeader ? null : getTypeLabel({ type: value, platform })
    },
    {
      field: "state",
      headerName: "Content state",
      renderCell: ({ value, row }) =>
        row.isSubHeader ? null : (
          <div style={{ display: "inline-block" }}>
            <StateBadge state={value} />
          </div>
        )
    }
  ];

  return (
    <Root>
      <TopBarContainer>
        <ContentApprovalProgress
          approvedParticipantsCount={approvedParticipantsCount}
          participantsCount={uniqueParticipantsCount}
          approvedContentCount={approvedContentCount}
          totalContentCount={usersContentStatus.length}
          submittedContentCount={submittedContentCount}
        />
        <Toolbar
          workspaceUrl={workspaceUrl}
          uniqueDateIntervals={uniqueDateIntervals}
          onDownload={platform === "instagram" ? onDownload : null}
          pushNotificationToClient={pushNotificationToClient}
          notifyClientLoading={notifyClientLoading}
          onSetFilters={onSetFilters}
          filters={filters}
        />
      </TopBarContainer>

      <Table
        tableId="content-approval-overview"
        stickyColumn
        rows={Object.entries(groupedRows).flatMap(([id, r], index) => {
          // @ts-ignore
          const subHeader: (typeof r)[number] = {
            key: id,
            isSubHeader: true,
            contentSettingName: r[0].contentSettingName || `Batch: ${index + 1}`,
            publishDateMin: r[0].publishDateMin,
            publishDateMax: r[0].publishDateMax
          };

          return [subHeader, ...r];
        })}
        columns={columns}
        onRowClick={row =>
          onRowClick({
            userId: row.userId,
            contentSettingId: Number(row.contentSettingId)
          })
        }
      />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBarContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
