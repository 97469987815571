import { type FC, useState } from "react";

import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { faCopy } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Popup } from "components/ui/Popup";

import { CampaignDropdown } from "../common/CampaignDropdown";
import {
  TimelineDocument,
  useDeleteAllTimelineItemsMutation,
  useInsertTimelineRowsMutation,
  useTimelineByCampaignIdLazyQuery
} from "./generated";

export const ReplaceTimelinePopup: FC<{ campaignId: number }> = ({ campaignId }) => {
  const snackbar = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(null);

  const [deleteTimeline] = useDeleteAllTimelineItemsMutation();
  const [getTimeline] = useTimelineByCampaignIdLazyQuery();
  const [insertTimelineRows] = useInsertTimelineRowsMutation({
    awaitRefetchQueries: true,
    refetchQueries: [TimelineDocument]
  });

  const handleClose = () => {
    setOpen(false);
    setSelectedCampaignId(null);
  };

  const handleReplace = async () => {
    if (!selectedCampaignId || !campaignId) {
      snackbar.error("Please select a campaign");
      return;
    }

    if (selectedCampaignId === campaignId) {
      snackbar.error("Cannot copy the same campaign");
      return;
    }

    const { data: srcItems } = await getTimeline({ variables: { campaignId: selectedCampaignId } });
    const campaign = srcItems?.campaign[0];
    if (!campaign) throw new Error("Cannot find campaign");

    const timeline = srcItems?.campaign[0].campaign_timeline;

    if (!timeline?.length) {
      snackbar.error("There is nothing to copy");
      return;
    }

    await deleteTimeline({ variables: { campaignId } });
    await insertTimelineRows({
      variables: {
        objects: timeline.map(t => ({
          campaign_id: campaignId,
          name: t.name,
          end_date: t.end_date,
          group: t.group,
          order: t.order
        }))
      }
    });
    snackbar.success("The timeline was replaced");
    handleClose();
  };

  return (
    <>
      <Button icon={<FontAwesomeIcon icon={faCopy} />} size="medium" onClick={() => setOpen(true)}>
        Replace timeline
      </Button>

      {open && (
        <Popup
          onClose={handleClose}
          actionLabel="Replace timeline"
          actionIcon={<FontAwesomeIcon icon={faSave} />}
          onAction={handleReplace}
          title="Replace timeline"
          allowOverflow
        >
          <CampaignDropdown
            campaignId={selectedCampaignId}
            onSelect={cId => setSelectedCampaignId(cId)}
            label="Source campaign"
          />
          <Alert variant="filled" severity="warning">
            Warning! The current timeline <strong>will be deleted</strong>.
          </Alert>
        </Popup>
      )}
    </>
  );
};
