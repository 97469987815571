import type { FC, ReactElement } from "react";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";

import { Section, SectionSubtitle, SectionValue } from "../user.styled";

export const MiniStatBox: FC<{
  value: string | number | undefined | null;
  title: string;
  icon: IconProp | string;
  rightAppend?: ReactElement;
}> = ({ value, title, icon, rightAppend }) => (
  <Section
    style={{
      padding: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }}
  >
    <div style={{ display: "flex", flexDirection: "column" }}>
      <SectionSubtitle>{title}</SectionSubtitle>
      <SectionValue>{value || "-"}</SectionValue>
    </div>
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      {typeof icon === "string" ? (
        <span style={{ fontSize: 30 }}>{icon}</span>
      ) : (
        <FontAwesomeIcon size="2x" color={palette.gray[50]} icon={icon} />
      )}
      {rightAppend}
    </div>
  </Section>
);
