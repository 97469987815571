import { type FC, useRef, useState } from "react";

import styled from "@emotion/styled";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  FormGroup,
  Card as MaterialCard,
  Popover,
  TextField
} from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useNavigate } from "react-router-dom";

import { platformOptions } from "lib/campaigns";

import { SearchListsDocument, useCreateListMutation } from "../generated";

const Root = styled.div`
  padding: 24px 0;
  text-align: center;
  > .separator {
    border-top: 1px solid ${palette.gray[20]};
    margin-bottom: 20px;
    &:after {
      content: "OR";
      display: block;
      margin: 0 auto;
      margin-top: -10px;
      background: ${palette.gray.white};
      width: 50px;
      color: ${palette.gray[20]};
    }
  }
`;

const Card = styled(MaterialCard)`
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const CreateList: FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [platform, setPlatform] = useState<Platform_Enum>("instagram");

  const [createList, { loading }] = useCreateListMutation({
    onCompleted: () => snackbar.success("List was created"),
    onError: error => snackbar.error(error.message),
    refetchQueries: [SearchListsDocument]
  });

  const onSave = async () => {
    const res = await createList({
      variables: { object: { name: title, platform, type: "campaign" } }
    });
    const newListId = res.data?.insert_lists_one?.id;
    if (!newListId) return;

    setOpen(false);
    navigate(`/curation/list-management/${newListId ?? ""}`);
  };

  return (
    <Root ref={rootRef}>
      <div className="separator" />
      <Button icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => setOpen(true)}>
        Create a new list
      </Button>
      <Popover
        open={open}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Card>
          {loading ? (
            <CircularProgress />
          ) : (
            <FormGroup>
              <TextField
                size="small"
                label="List name"
                value={title}
                onChange={e => setTitle(e.target.value)}
                inputProps={{ style: { height: "100%" } }}
                style={{ marginBottom: 15 }}
              />
              <Select
                hideNone
                label="Platform"
                size="small"
                onChange={newPlatform => newPlatform && setPlatform(newPlatform)}
                value={platform}
                options={platformOptions}
                style={{ marginBottom: 15 }}
              />
              <Button
                disabled={title.length < 3}
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => onSave()}
              >
                Create list
              </Button>
            </FormGroup>
          )}
        </Card>
      </Popover>
    </Root>
  );
};
