import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnableClientReviewAndSendEmailsMutationVariables = Types.Exact<{
  projectStub: Types.Scalars['String']['input'];
}>;


export type EnableClientReviewAndSendEmailsMutation = { __typename?: 'Mutation', setProjectReadyForClientReview?: void | null };


export const EnableClientReviewAndSendEmailsDocument = gql`
    mutation enableClientReviewAndSendEmails($projectStub: String!) {
  setProjectReadyForClientReview(projectStub: $projectStub)
}
    `;
export type EnableClientReviewAndSendEmailsMutationFn = Apollo.MutationFunction<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>;

/**
 * __useEnableClientReviewAndSendEmailsMutation__
 *
 * To run a mutation, you first call `useEnableClientReviewAndSendEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableClientReviewAndSendEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableClientReviewAndSendEmailsMutation, { data, loading, error }] = useEnableClientReviewAndSendEmailsMutation({
 *   variables: {
 *      projectStub: // value for 'projectStub'
 *   },
 * });
 */
export function useEnableClientReviewAndSendEmailsMutation(baseOptions?: Apollo.MutationHookOptions<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>(EnableClientReviewAndSendEmailsDocument, options);
      }
export type EnableClientReviewAndSendEmailsMutationHookResult = ReturnType<typeof useEnableClientReviewAndSendEmailsMutation>;
export type EnableClientReviewAndSendEmailsMutationResult = Apollo.MutationResult<EnableClientReviewAndSendEmailsMutation>;
export type EnableClientReviewAndSendEmailsMutationOptions = Apollo.BaseMutationOptions<EnableClientReviewAndSendEmailsMutation, EnableClientReviewAndSendEmailsMutationVariables>;