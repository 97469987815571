import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faCaretDown, faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { faLaptopMobile, faLinkSimple } from "@fortawesome/pro-regular-svg-icons";
import { faMessageDots } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Tooltip } from "@mui/material";
import { getUserLabel, prettifyNumber } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { CAMPAIGN_USER_STATE_DISPLAY_NAMES } from "lib/constants";

import type { UserCampaignsQuery, UserQuery } from "../generated";
import { Section, SectionSubtitle, SectionValue } from "../user.styled";
import { CampaignDetails } from "./CampaignDetails";

const StyledSection = styled(Section)<{
  $state?: keyof typeof CAMPAIGN_USER_STATE_DISPLAY_NAMES | null;
}>`
  margin-bottom: 30px;
  padding: 0 10px 12px 10px;
  position: relative;

  > .header {
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;

    .comment-badge {
      margin-left: auto;
      margin-right: 10px;
      margin-top: 5px;
      color: ${palette.gray.white};
      background: ${palette.gray[30]};
      width: 30px;
      height: 30px;
      display: inline-block;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      &.active {
        background: ${palette.secondary.green};
      }
    }

    > h3 {
      padding: 12px;
      font-size: 12px;
      > a {
        color: ${palette.gray.black};
      }
    }

    > .state {
      background: ${({ $state }) =>
        ["approved", "confirmed"].includes($state || "")
          ? palette.primary.green
          : palette.primary.red};
      text-transform: uppercase;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 100px;
      color: ${palette.gray.white};
      height: 28px;
      margin-top: 6px;
    }
  }

  > .stats {
    position: relative;
    display: grid;
    gap: 0.5%;
    grid-template-columns: 2.5% 12% 14.5% 14.5% 14.5% 14.5% 12% 12%;
    padding-top: 8px;

    > .icon {
      min-width: 0;
      > svg {
        width: 20px;
        height: 20px;
      }
    }

    > .stats-item {
      display: flex;
      flex-direction: column;
      padding: 5px 13px;
      border: 1px solid ${palette.gray[10]};
      border-radius: 10px;
    }

    > .payment-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -6px;
      font-size: 14px;
      color: ${palette.gray[60]};
    }
  }
  .lowrated-bg {
    position: absolute;
    background: rgb(255 237 237 / 95%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
`;

const formatDate = (ts: number | string | null | undefined) => {
  if (!ts) return "-";
  const date = new Date(ts);
  const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  return `${month}/${year.slice(-2)}`;
};

export const CampaignSection: FC<{
  user: NonNullable<UserQuery["users_by_pk"]>;
  campaignUser: NonNullable<UserCampaignsQuery["campaign_user"][number]>;
}> = ({ user, campaignUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { campaign } = campaignUser;
  const isLowRatedUser = campaignUser.collaboration_score === 1;
  return (
    <StyledSection
      style={isLowRatedUser ? { border: `1px dotted ${palette.primary.red}` } : {}}
      $state={campaignUser.state}
    >
      {isLowRatedUser && (
        <div className="lowrated-bg">{getUserLabel(user)} is rejected by the production team.</div>
      )}
      <div className="header">
        <h3>
          <FontAwesomeIcon icon={faLaptopMobile} style={{ marginRight: 10 }} />
          <Link to={`/campaign/${campaign.stub}`}>{campaign.title}</Link>
        </h3>

        {isLowRatedUser && (
          <Tooltip title={campaignUser.notes ?? ""}>
            <span className={`comment-badge ${campaignUser.notes ? "active" : ""}`}>
              <FontAwesomeIcon icon={faMessageDots} />
            </span>
          </Tooltip>
        )}

        <div className="state" style={{ opacity: isLowRatedUser ? 0.2 : 1 }}>
          {campaignUser.state ? CAMPAIGN_USER_STATE_DISPLAY_NAMES[campaignUser.state] : "Not set"}
        </div>
      </div>
      <Divider />
      <div className="stats">
        <Button className="icon" variant="text" onClick={() => setIsOpen(prev => !prev)}>
          <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} />
        </Button>
        {[
          {
            title: "Date",
            value: formatDate(
              campaign.campaign_content_settings_aggregate.aggregate?.max?.publish_date_max
            )
          },
          {
            title: "Global Pay",
            value: `${prettifyNumber(campaignUser.global_pay)} SEK`
          },
          {
            title: "Salary",
            value: `${prettifyNumber(campaignUser.local_salary_pay)} ${campaignUser.currency}`
          },
          {
            title: "CPM*",
            value: campaignUser.cpm
              ? `${prettifyNumber(campaignUser.cpm)} ${campaignUser.currency}`
              : "-"
          },
          {
            title: "Invoice",
            value: `${prettifyNumber(campaignUser.local_invoice_pay)} ${campaignUser.currency}`
          },
          {
            title: "# of posts",
            value: campaign.number_of_posts
          },
          {
            title: "Pay. state",
            value: campaignUser.payment_state.replaceAll("_", " "),
            narrow: true
          }
        ].map(stat => (
          <div key={stat.title} className="stats-item">
            <SectionSubtitle>{stat.title}</SectionSubtitle>
            <SectionValue
              style={
                stat.narrow
                  ? {
                      fontSize: 8,
                      marginTop: "auto",
                      marginBottom: "auto",
                      paddingRight: 10
                    }
                  : {}
              }
            >
              {stat.value}
            </SectionValue>
          </div>
        ))}
        <Link
          to={`${process.env.ONBOARD_BASE_URL}/payments/${campaignUser.campaign.stub}/${user.idEncoded}`}
          target="_blank"
          rel="noreferrer"
          className="payment-icon"
        >
          <FontAwesomeIcon icon={faLinkSimple} />
        </Link>
      </div>
      {isOpen && <CampaignDetails user={user} campaignUser={campaignUser} />}
    </StyledSection>
  );
};
