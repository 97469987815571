import { type FC, useState } from "react";

import { faArrowDown, faArrowUp, faCheck, faFile, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { truncateFileName } from "@relatable/helpers";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";
import { useParams } from "react-router-dom";

import { Popup } from "components/ui/Popup";
import { UploadInput } from "components/ui/UploadInput";

import { useSnapchatUserNamesQuery } from "./generated";
import { parseSnapchatUrl, useUploadSnap } from "./useUploadSnap";

type FileStatus = "uploading" | "awaiting upload" | "uploaded";

const StatusIcon: FC<{ status: FileStatus }> = ({ status }) => {
  if (status === "uploaded") {
    return (
      <FontAwesomeIcon color={palette.secondary.green} icon={faCheck} style={{ marginRight: 5 }} />
    );
  }
  if (status === "uploading") return <CircularProgress size={10} style={{ marginRight: 5 }} />;
  return null;
};

export const UploadSnapPopup: FC<{ onClose(): void; onUpload(): void }> = ({
  onClose,
  onUpload
}) => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [videoUrl, setVideoUrl] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [files, setFiles] = useState<{ content: File; status: FileStatus }[]>([]);

  const uploadSnap = useUploadSnap();

  const { data } = useSnapchatUserNamesQuery({ variables: { stub: campaignStub ?? "" } });
  const campaign = data?.campaign[0];
  const users = (campaign?.campaign_users || [])?.map(({ id: campaignUserId, user }) => ({
    id: user?.id || "",
    username: user?.user_snapchat?.username || "",
    campaignUserId
  }));

  const handleSubmit = async () => {
    setFiles(files.map(file => ({ ...file, status: "uploading" })));

    const selectedUser = users.find(u => u.id === selectedUserId);
    if (!selectedUser) throw Error("User is required!");

    // to keep the order of uploading files we cannot use Promise.all
    for (const index in files) {
      const file = files[index];

      await uploadSnap({
        fileType: file.content.type,
        file: file.content,
        videoUrl,
        fileName: file.content.name,
        username: selectedUser.username,
        campaignUserId: selectedUser.campaignUserId,
        userSnapchatId: selectedUserId ?? 0
      });

      setFiles(prev =>
        prev.map(prevFile =>
          prevFile.content.name + prevFile.content.lastModified ===
          file.content.name + file.content.lastModified
            ? { ...file, status: "uploaded" }
            : prevFile
        )
      );
    }

    onUpload();
    onClose();
  };

  const match = videoUrl ? parseSnapchatUrl(videoUrl) : null;

  return (
    <Popup
      allowOverflow
      maxWidth="sm"
      disabledAction={!files.length}
      onAction={handleSubmit}
      actionLabel="Submit"
      title="Upload Snap"
      onClose={onClose}
    >
      <Select
        required
        hideNone
        label="User (approved & confirmed)"
        value={selectedUserId || ""}
        onChange={v => setSelectedUserId(Number(v))}
        options={users.map(user => ({
          value: user.id,
          label: user.username || ""
        }))}
      />

      <TextInput
        value={videoUrl}
        onChange={(v: string) => {
          setVideoUrl(v);
        }}
        label="Snap Video URL (optional)"
      />

      <UploadInput
        multiple
        accept={["videos", "images"]}
        onChange={f =>
          setFiles(f.map(file => ({ content: file, status: "awaiting upload" as const })))
        }
      />

      {videoUrl && !match?.shortcode ? (
        <Note style={{ width: 490 }} variant="error" label="Invalid Snapchat URL!">
          Invalid Snapchat URL!
        </Note>
      ) : null}

      <Typography>
        It is important to upload the files in chronological order. You can use the buttons bellow
        to reorder the files if the order is wrong.
      </Typography>

      {files.map((file, index) => (
        <Grid
          justifyContent="space-between"
          container
          spacing={4}
          key={file.content.name + file.content.lastModified}
        >
          <Grid item>
            <Chip
              onDelete={() => null}
              deleteIcon={<StatusIcon status={file.status} />}
              label={truncateFileName(file.content.name)}
              icon={<FontAwesomeIcon icon={faFile} />}
            />
          </Grid>

          <Grid item>
            <ButtonGroup>
              <Button
                variant="text"
                disabled={index === 0}
                onClick={() => {
                  const newList = [...files];
                  const upperEl = files[index - 1];
                  newList[index] = upperEl;
                  newList[index - 1] = file;
                  setFiles(newList);
                }}
                endIcon={
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    color={index === 0 ? palette.gray[50] : palette.secondary.blue}
                  />
                }
              >
                Up
              </Button>
              <Button
                variant="text"
                disabled={index === files.length - 1}
                onClick={() => {
                  const newList = [...files];
                  const lowerEl = files[index + 1];
                  newList[index] = lowerEl;
                  newList[index + 1] = file;
                  setFiles(newList);
                }}
                endIcon={
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    color={index === files.length - 1 ? palette.gray[50] : palette.secondary.blue}
                  />
                }
              >
                Down
              </Button>
              <Button
                onClick={() =>
                  setFiles(prev =>
                    prev.filter(
                      f =>
                        f.content.name !== file.content.name &&
                        f.content.lastModified !== file.content.lastModified
                    )
                  )
                }
                endIcon={<FontAwesomeIcon color={palette.primary.red} icon={faTrash} />}
                variant="text"
              >
                Delete
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ))}
    </Popup>
  );
};
