import { type FC, useState } from "react";

import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BITLY_DOMAINS } from "@relatable/helpers/constants";
import { rndLongString } from "@relatable/helpers/index";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useDebounce } from "@relatable/ui/hooks/useDebounce";

import { Popup } from "components/ui/Popup";
import { useLinksQuery, useUpsertLinkMutation } from "modules/links/generated";
import {
  extractRltToKey,
  prettifyLinkTargetURL,
  validateRltKey,
  validateURL,
  withHttps
} from "modules/links/util";
import { CopyIcon } from "modules/user/UserInfo/CopyIcon";

import { CampaignOverviewDocument } from "../Overview/generated";
import { CampaignParticipantsDocument } from "../Participants/generated";
import { useUpdateCampaignUserMutation } from "../generated";

export const TrackingCell: FC<{
  username: string;
  campaignUserId?: number;
  tracking?: { shortlinks?: string[] };
}> = ({ username, campaignUserId, tracking }) => {
  const snackbar = useSnackbar();
  const shortLinks = Array.isArray(tracking?.shortlinks)
    ? tracking?.shortlinks.map(s => s.trim())
    : [];

  const [open, setOpen] = useState(false);

  const [type, setType] = useState<"rlt.to" | "bit.ly">("rlt.to");
  const [key, setKey] = useState(() => rndLongString(4));
  const [url, setUrl] = useState("");

  const debouncedKey = useDebounce(key, 300);
  const { data: existingRltLinks } = useLinksQuery({
    variables: { where: { key: { _eq: debouncedKey } } },
    skip: !open || type !== "rlt.to" || !debouncedKey
  });
  const existingLink = existingRltLinks?.link_clicks?.[0];

  const rltKeys = shortLinks.map(s => extractRltToKey(s)).filter(Boolean) as string[];

  const { data: linkData } = useLinksQuery({
    variables: { where: { key: { _in: rltKeys } } },
    skip: !open
  });

  const [upsertLink, { loading: loadingUpsertLink }] = useUpsertLinkMutation({
    onError(err) {
      snackbar.error(err.message);
    }
  });

  const [updateCampaignUser, { loading: loadingCampaignUserUpdate }] =
    useUpdateCampaignUserMutation({
      onCompleted: () => {
        setOpen(false);
        snackbar.success("The link has been successfully updated");
      },
      onError: () => {
        snackbar.error("Something went wrong");
      }
    });

  const handleAddBitly = async () => {
    if (!campaignUserId) return;
    if (!url.startsWith("https://") || !BITLY_DOMAINS.some(base => url.includes(base))) {
      snackbar.error("Invalid Bitly URL");
      return;
    }

    await updateCampaignUser({
      variables: {
        campaignUserId,
        _set: { tracking: { shortlinks: [...shortLinks, url] } }
      },
      refetchQueries: [CampaignOverviewDocument, CampaignParticipantsDocument],
      awaitRefetchQueries: true
    });
    setUrl("");
  };

  const handleAddRltTo = async () => {
    if (!campaignUserId) return;

    if (rltKeys.includes(key)) {
      snackbar.error("This link is already added to the creator");
      return;
    }

    if (!validateRltKey(key)) {
      snackbar.error(
        "Invalid URL key format. Only lowercase letters, numbers and `_` `-` characters are allowed"
      );
      return;
    }

    if (existingLink?.key !== key) {
      if (!validateURL(withHttps(url))) {
        snackbar.error("Invalid client URL");
        return;
      }

      await upsertLink({
        variables: {
          set: {
            key,
            target_url: withHttps(url)
          }
        }
      });
    }

    await updateCampaignUser({
      variables: {
        campaignUserId,
        _set: { tracking: { shortlinks: [...shortLinks, `https://rlt.to/${key}`] } }
      },
      refetchQueries: [CampaignOverviewDocument, CampaignParticipantsDocument],
      awaitRefetchQueries: true
    });

    setKey(rndLongString(4));
    setUrl("");
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faLink}
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
        color={shortLinks.length ? palette.secondary.blue : palette.gray[50]}
      />

      {open && (
        <Popup
          actionLabel="Add new link"
          actionIcon={<FontAwesomeIcon icon={faSave} />}
          onAction={type === "bit.ly" ? handleAddBitly : handleAddRltTo}
          title="Tracking"
          onClose={() => setOpen(false)}
          disabledAction={loadingUpsertLink || loadingCampaignUserUpdate}
        >
          {shortLinks.length > 0 && (
            <div>
              <h3>Links of @{username}</h3>
              <div style={{ padding: "10px 0" }}>
                {shortLinks.map(s => {
                  const rltKey = extractRltToKey(s);
                  const link = linkData?.link_clicks.find(l => l.key === rltKey);
                  return (
                    <div key={s}>
                      <CopyIcon text={s} />
                      {s} {link ? `(${prettifyLinkTargetURL(link.target_url)})` : ""}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <h3>Add new link</h3>

          <Select
            hideNone
            label="Type"
            onChange={v => setType((v ?? "rlt.to") as "rlt.to" | "bit.ly")}
            value={type}
            options={[
              { label: "rlt.to", value: "rlt.to" },
              { label: "bit.ly", value: "bit.ly" }
            ]}
          />

          {type === "rlt.to" ? (
            <>
              <TextInput
                required
                label="Relatable shortlink key"
                value={key ?? ""}
                onChange={v => setKey(v)}
              />
              <TextInput
                required
                disabled={existingLink?.key === key}
                label="Client URL"
                value={existingLink?.target_url ?? url}
                onChange={v => setUrl(v)}
              />
            </>
          ) : (
            <TextInput required label="Bitly URL" value={url} onChange={v => setUrl(v)} />
          )}
        </Popup>
      )}
    </div>
  );
};
