import { type FC, useCallback, useEffect, useMemo } from "react";

import { faCog, faLeft, faRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, debounce } from "@mui/material";
import { MMM_DD_YYYY, YYYY_MM_DD, addDays, isDateValid } from "@relatable/helpers/date";
import { Button } from "@relatable/ui/Button";
import { useNavigate, useParams } from "react-router-dom";

import { useMeQuery } from "hooks/generated";

import { HoursTable } from "./HoursTable";
import {
  useAccountActiveProjectsQuery,
  useAccountTimeRecordsQuery,
  useDeleteTimeTrackingMutation,
  useInsertTimeTrackingMutation
} from "./generated";

const dateToUrlParams = (date: Date) => YYYY_MM_DD(date).replace(/-/g, "/");

export const Hours: FC = () => {
  const { year, month, day } = useParams<{ year: string; month: string; day: string }>();
  const navigate = useNavigate();
  const { data: meData } = useMeQuery();
  const accountId = meData?.me?.admin?.id ?? 0;

  const dateStr = `${year}-${month}-${day}`;

  useEffect(() => {
    if (!isDateValid(dateStr)) {
      navigate(`/time-reporting/my-hours/${dateToUrlParams(new Date())}`);
    }
  }, [navigate, dateStr]);

  const { data: activeProjects, loading: activeProjectsLoading } = useAccountActiveProjectsQuery({
    variables: { accountId },
    skip: !accountId
  });

  const date = useMemo(() => (isDateValid(dateStr) ? new Date(dateStr) : null), [dateStr]);

  const {
    data: timeRecords,
    loading: timeRecordsLoading,
    refetch: refetchTimeRecords
  } = useAccountTimeRecordsQuery({
    variables: {
      accountId,
      date: dateStr ?? ""
    },
    skip: !accountId || !date,
    fetchPolicy: "no-cache"
  });

  const [upsertHours] = useInsertTimeTrackingMutation();
  const [deleteTimeTrackingRecord] = useDeleteTimeTrackingMutation();

  const updateHours = useCallback(
    async ({ projectId, hours }: { projectId: number; hours: string }) => {
      if (hours === "") return {};

      return upsertHours({
        variables: {
          object: {
            account_id: accountId,
            project_id: projectId,
            date: dateStr,
            hours: hours as unknown as number // intentionally pass number as a string to not have floating point precision issues
          }
        }
      });
    },
    [accountId, upsertHours, dateStr]
  );

  const debouncedUpdateHours = useMemo(() => debounce(updateHours, 400), [updateHours]);

  const handleAddProject = async (projectId: number) => {
    await updateHours({ projectId, hours: "0" });
    await refetchTimeRecords();
  };

  const handleDeleteProject = async (projectId: number) => {
    await deleteTimeTrackingRecord({ variables: { projectId, date: dateStr, accountId } });
    await refetchTimeRecords();
  };

  return (
    <div
      style={{
        display: "inline-block",
        background: "white",
        padding: 20,
        minHeight: 100,
        width: 600
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          position: "relative"
        }}
      >
        <Button
          icon={<FontAwesomeIcon icon={faLeft} />}
          onClick={() => navigate(`/time-reporting/my-hours/${dateToUrlParams(addDays(date, -1))}`)}
        >
          Previous date
        </Button>
        <span style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
          {date?.toLocaleDateString("en-us", { weekday: "long" })}, {MMM_DD_YYYY(date)}
        </span>
        <Button
          icon={<FontAwesomeIcon icon={faRight} />}
          disabled={addDays(date, 1) > new Date()}
          onClick={() => navigate(`/time-reporting/my-hours/${dateToUrlParams(addDays(date, 1))}`)}
        >
          Next date
        </Button>
      </div>

      {[0, 6].includes(date?.getDay() ?? -1) && (
        <Alert variant="filled" severity="warning" style={{ marginBottom: 20 }}>
          This date falls on the weekend.
        </Alert>
      )}
      {date && !activeProjectsLoading && !timeRecordsLoading ? (
        <HoursTable
          activeProjects={activeProjects?.projects ?? []}
          timeRecords={timeRecords?.time_tracking ?? []}
          onUpdateHours={debouncedUpdateHours}
          onAddProject={handleAddProject}
          onDeleteProject={handleDeleteProject}
        />
      ) : (
        <FontAwesomeIcon icon={faCog} size="2x" spin />
      )}
    </div>
  );
};
