import type { FC } from "react";

import styled from "@emotion/styled";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { getCampaignProgress, numberWithCommas, prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { getProjectPayoutBudget } from "lib/calculations";
import type { OverviewQuery } from "modules/overview/generated";
import type { InfluencerMarketingBudget } from "src/typings/global";

import { Dropdown } from "./Dropdown";

export const ProjectToolbar: FC<{
  agreement_url?: string;
  influencerMarketingBudget: InfluencerMarketingBudget;
  legacyTimeTracking: OverviewQuery["clients"][number]["projects"][number]["project_time_tracking"];
  totalHours: number;
  partialCampaigns: Array<
    OverviewQuery["clients"][number]["projects"][number]["campaigns_aggregate"]["nodes"][number]
  >;
  project_team_members: OverviewQuery["clients"][number]["projects"][number]["project_team_members"];
  proposal_url?: string;
  stub: string;
  target_reach?: number;
  target_views?: number;
}> = ({
  agreement_url,
  influencerMarketingBudget,
  legacyTimeTracking,
  totalHours,
  partialCampaigns,
  project_team_members = [],
  proposal_url,
  stub,
  target_reach = 0,
  target_views = 0
}) => {
  const documents = [
    ...(agreement_url ? [{ href: agreement_url, label: "Agreement" }] : []),
    ...(agreement_url ? [{ href: proposal_url, label: "Proposal" }] : [])
  ];

  const payoutBudget = getProjectPayoutBudget(
    influencerMarketingBudget.target_margin,
    influencerMarketingBudget.revenue_allocation
  );

  const totalReach = target_reach + target_views;
  const clientCPM = influencerMarketingBudget.revenue_allocation / (totalReach / 1000);
  const payoutCPM = payoutBudget / (totalReach / 1000);

  const progress = (() => {
    const campaignProgress =
      partialCampaigns?.map(c => getCampaignProgress(c.campaign_timeline) ?? 0) ?? [];
    const progressSum = campaignProgress.reduce((acc, p) => acc + p, 0);

    return campaignProgress.length === 0
      ? "-"
      : prettifyNumber(progressSum / campaignProgress.length, { percentages: true });
  })();

  return (
    <Root>
      <InfoContainer>
        <InfoLine>
          <InfoColumn>
            <InfoLabel>Payout budget</InfoLabel>
            <InfoValue>{numberWithCommas(Math.round(payoutBudget))} SEK</InfoValue>
          </InfoColumn>

          <InfoColumn>
            <InfoLabel>Target reach</InfoLabel>
            <InfoValue>{numberWithCommas(totalReach)}</InfoValue>
          </InfoColumn>

          <InfoColumn>
            <InfoLabel>Client CPM</InfoLabel>
            <InfoValue>
              {Number.isFinite(clientCPM) ? `${numberWithCommas(Math.round(clientCPM))} SEK` : "-"}
            </InfoValue>
          </InfoColumn>

          <InfoColumn>
            <InfoLabel>Payout CPM</InfoLabel>
            <InfoValue>
              {Number.isFinite(payoutCPM) ? `${numberWithCommas(Math.round(payoutCPM))} SEK` : "-"}
            </InfoValue>
          </InfoColumn>

          <InfoColumn>
            <InfoLabel>Est. payout margin</InfoLabel>
            <InfoValue>
              {prettifyNumber(influencerMarketingBudget.target_margin, { percentages: true })}
            </InfoValue>
          </InfoColumn>
        </InfoLine>
        <InfoLine>
          {legacyTimeTracking?.wrike_effort_allocated !== null && (
            <InfoColumn>
              <InfoLabel>Effort allocated</InfoLabel>
              <InfoValue>
                {numberWithCommas(Math.round(legacyTimeTracking?.wrike_effort_allocated ?? 0))} h
                (frozen)
              </InfoValue>
            </InfoColumn>
          )}

          {(legacyTimeTracking?.wrike_time_spent ?? legacyTimeTracking?.hours_logged) !== null && (
            <InfoColumn>
              <InfoLabel>Time spent</InfoLabel>
              <InfoValue>
                {numberWithCommas(
                  Math.round(
                    legacyTimeTracking?.wrike_time_spent ?? legacyTimeTracking?.hours_logged ?? 0
                  )
                )}{" "}
                h (frozen)
              </InfoValue>
            </InfoColumn>
          )}

          {legacyTimeTracking?.money_spent_sek !== null && (
            <InfoColumn>
              <InfoLabel>Internal costs</InfoLabel>
              <InfoValue>
                {numberWithCommas(Math.round(legacyTimeTracking?.money_spent_sek ?? 0))} SEK
                (frozen)
              </InfoValue>
            </InfoColumn>
          )}

          <InfoColumn>
            <InfoLabel>Progress</InfoLabel>
            <InfoValue>
              <span>{progress}</span>
            </InfoValue>
          </InfoColumn>

          <InfoColumn>
            <InfoLabel>Time spent</InfoLabel>
            <InfoValue>
              <span>{prettifyNumber(totalHours ?? 0)} h</span>
            </InfoValue>
          </InfoColumn>
        </InfoLine>
      </InfoContainer>

      <ActionContainer>
        {documents.length > 0 && (
          <DropdownContainer>
            <span>Documents ({documents.length})</span>
            <Dropdown>
              {documents.map(d => (
                <li key={d.href}>
                  <Link to={d.href ?? ""} target="_blank" rel="noreferrer">
                    {d.label}
                  </Link>
                </li>
              ))}
            </Dropdown>
          </DropdownContainer>
        )}
        {project_team_members.length > 0 && (
          <DropdownContainer>
            <span>
              {project_team_members[0].account.full_name}{" "}
              {project_team_members.length - 1 > 0 && `(+${project_team_members.length - 1})`}
            </span>
            <Dropdown>
              {project_team_members.map(ptm => (
                <li key={ptm.id}>
                  <span>
                    {ptm.account.full_name}
                    <br />
                    <em>{ptm.account.title}</em>
                  </span>
                </li>
              ))}
            </Dropdown>
          </DropdownContainer>
        )}
        <IconButton component={Link} to={`/project/${stub}/edit`}>
          <FontAwesomeIcon icon={faCog} size="xs" />
        </IconButton>
      </ActionContainer>
    </Root>
  );
};

const Root = styled.div`
  padding: 10px 20px 20px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${palette.gray[20]};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InfoLine = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  margin-bottom: 8px;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  width: 117px;
`;

const InfoLabel = styled.span`
  font-size: 12px;
`;

const InfoValue = styled.span`
  font-weight: 700;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  align-items: center;

  > * {
    margin-left: 24px !important;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;
