import type { FC } from "react";

import styled from "@emotion/styled";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Divider } from "@mui/material";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { useStoredState } from "@relatable/ui/hooks/useStoredState";
import { useParams } from "react-router-dom";

import type { CampaignUserExtendedState } from "src/typings/global";

import { Loader } from "@relatable/ui/Loader";
import { ActionButtons } from "./ActionsButtons";
import { ParticipantsStats } from "./ParticipantsStats";
import { ParticipantsTable } from "./ParticipantsTable";
import { useCampaignParticipantsQuery } from "./generated";
import { filterCampaignParticipantsData } from "./helpers";

export const ParticipantList: FC = () => {
  useDocumentTitle("Participants");

  const [statisticsExpanded, setStatisticsExpanded] = useStoredState("statistics-expanded", false);

  const params = useParams<{ campaignStub: string }>();
  const campaignStubs = (params.campaignStub ?? "").split(",");

  const [userStateFilterBy, setUserStateFilterBy] = useStoredState<CampaignUserExtendedState[]>(
    `participant-filter-${params.campaignStub}`,
    []
  );

  const { data } = useCampaignParticipantsQuery({
    variables: { campaignStubs }
  });

  const filteredData = filterCampaignParticipantsData(data, userList => {
    if (userStateFilterBy.length === 0) return true; // inactive filter (all users shown)
    const state = userList.campaign_user?.state;
    if (!state) return userStateFilterBy.includes("not_set");
    return userStateFilterBy.includes(state);
  });

  const hasContentSettings = data?.campaigns?.every(
    c => (c.campaign_content_settings_aggregate.aggregate?.count || 0) > 0
  );

  return (
    <Root>
      <Accordion
        expanded={statisticsExpanded}
        onChange={(_, expanded) => setStatisticsExpanded(expanded)}
        variant="outlined"
      >
        <StyledAccordionSummary expandIcon={<FontAwesomeIcon icon={faArrowDown} />}>
          Campaign statistics
        </StyledAccordionSummary>
        <AccordionDetails>
          <ParticipantsStats campaignStubs={campaignStubs} />
        </AccordionDetails>
      </Accordion>

      <Divider />

      {data ? (
        hasContentSettings ? (
          <>
            {campaignStubs.length === 1 && (
              <ActionButtons
                userStateFilterBy={userStateFilterBy}
                resetUserStateFilterBy={() => setUserStateFilterBy([])}
                toggleUserStateFilterBy={state =>
                  setUserStateFilterBy(prev => {
                    if (prev.includes(state)) {
                      return prev.filter(p => p !== state);
                    }
                    return [...prev, state];
                  })
                }
                campaignStub={campaignStubs[0]}
                data={data}
              />
            )}

            <ParticipantsTable data={filteredData} />
          </>
        ) : (
          <Root>
            <Alert color="error" title="Missing content settings!">
              In order to keep all the numbers in order we require content-settings with correct
              dates. Without content-settings we won&apos;t be able to create or schedule payments!
            </Alert>
          </Root>
        )
      ) : (
        <Loader />
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    flex-direction: row-reverse;
    gap: 10px;
  }
`;
