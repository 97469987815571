import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { TIMELINE_GROUP_WEIGHT } from "@relatable/helpers/constants";
import { FontAwesomeIcon } from "@relatable/ui/icons";

import type { TimelineItem } from ".";
import { ReplaceTimelinePopup } from "./ReplaceTimelinePopup";
import { TimelineGroup } from "./TimelineGroup";

export const TimelineEditor: FC<{
  campaignId: number;
  items: TimelineItem[];
  saving: boolean;
  onAdd: (groupId: number) => Promise<TimelineItem>;
  onChange: (data: TimelineItem[]) => void;
}> = ({ campaignId, saving, items, onAdd, onChange }) => {
  const [groups, setGroups] = useState<TimelineItem[][]>(() =>
    TIMELINE_GROUP_WEIGHT.map((_, index) => items.filter(item => item.group === index))
  );

  const handleAddGroup = async (groupId: number) => {
    const newItem = await onAdd(groupId);

    const newGroups = [
      ...groups.slice(0, groupId),
      [...groups[groupId], newItem],
      ...groups.slice(groupId + 1)
    ];

    setGroups(newGroups);
  };

  const handleInputChange = (
    item: TimelineItem,
    field: keyof TimelineItem,
    value: TimelineItem[typeof field]
  ) => {
    const groupIndex = groups.findIndex(group => group.includes(item));
    if (groupIndex === -1) throw new Error("Cannot find group");

    const itemIndex = groups[groupIndex].findIndex(i => i === item);
    if (itemIndex === -1) throw new Error("Cannot find item");

    if (groups[groupIndex][itemIndex][field] === value) return;

    const newGroup = groups[groupIndex].map((g, i) =>
      i === itemIndex ? { ...g, [field]: value } : g
    );

    const newGroups = groups.map((g, i) => (i === groupIndex ? newGroup : g));

    setGroups(newGroups);
    onChange(newGroups.flat());
  };

  const handleDelete = (item: TimelineItem) => {
    const newGroups = groups.map(group => group.filter(g => g !== item));
    setGroups(newGroups);
    onChange(newGroups.flat());
  };

  const handleMoveUp = (item: TimelineItem) => {
    const newGroups = groups.map(group => {
      const itemIndex = group.indexOf(item);
      if (itemIndex === -1) return group;
      if (itemIndex === 0) throw new Error("Cannot move up first item");

      return [
        ...group.slice(0, itemIndex - 1),
        item,
        group[itemIndex - 1],
        ...group.slice(itemIndex + 1)
      ];
    });
    setGroups(newGroups);
    onChange(newGroups.flat());
  };

  return (
    <Root>
      {groups.map(
        groupItems =>
          groupItems.length > 0 && (
            <TimelineGroup
              key={groupItems[0].group}
              groupItems={groupItems}
              onAdd={handleAddGroup}
              onInputChange={handleInputChange}
              onDelete={handleDelete}
              onMoveUp={handleMoveUp}
            />
          )
      )}

      <ReplaceTimelinePopup campaignId={campaignId} />

      {saving && (
        <span style={{ padding: "10px", verticalAlign: "middle" }}>
          <FontAwesomeIcon icon={faSpinner} spin fontSize={26} />
        </span>
      )}
    </Root>
  );
};

const Root = styled.div`
  margin: 0;
`;
