import { type FC, useState } from "react";

import styled from "@emotion/styled";
import {
  faChartLineUp,
  faCog,
  faEnvelope,
  faFileArrowDown,
  faUserPlus
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Link } from "react-router-dom";

import { FilterStateMenu } from "modules/campaign/common/FilterStateMenu";
import type { CampaignUserExtendedState } from "src/typings/global";

import {
  CampaignParticipantsDocument,
  type CampaignParticipantsQuery,
  CampaignParticipantsStatsDocument,
  useAddUsersToCampaignMutation
} from "../generated";
import { SendEmailsPopup } from "./SendEmailsPopup";

export const ActionButtons: FC<{
  campaignStub: string;
  userStateFilterBy: CampaignUserExtendedState[];
  resetUserStateFilterBy: () => void;
  toggleUserStateFilterBy: (v: CampaignUserExtendedState) => void;
  data: CampaignParticipantsQuery | undefined;
}> = ({
  campaignStub,
  userStateFilterBy,
  resetUserStateFilterBy,
  toggleUserStateFilterBy,
  data
}) => {
  const snackbar = useSnackbar();

  const [isSendEmailPopup, setIsSendEmailPopup] = useState(false);

  const [addUsersToCampaign, addUsersToCampaignOptions] = useAddUsersToCampaignMutation({
    onCompleted: () => snackbar.success("Users has been successfully added"),
    onError: () => snackbar.error("Something went wrong"),
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsStatsDocument, CampaignParticipantsDocument]
  });

  const listUsersToInclude = data?.users_in_lists.filter(user => !user.campaign_user?.id) || [];

  return (
    <SActionButtons>
      <Link to={`/campaign/${campaignStub}/participants/statistics`}>
        <Button icon={<FontAwesomeIcon icon={faChartLineUp} />} onClick={() => null}>
          Statistics
        </Button>
      </Link>

      <Link to={`/campaign/${campaignStub}/manage/sheet`}>
        <Button icon={<FontAwesomeIcon icon={faCog} />} onClick={() => null}>
          Client workspace settings
        </Button>
      </Link>

      {listUsersToInclude?.length ? (
        <div>
          <Button
            isLoading={addUsersToCampaignOptions.loading}
            icon={<FontAwesomeIcon icon={faUserPlus} />}
            onClick={() =>
              addUsersToCampaign({
                variables: {
                  campaignStub,
                  userIds: listUsersToInclude.map(listUser => {
                    const id = listUser.user_id;
                    if (typeof id !== "number") throw new Error("user is missing id");
                    return id;
                  })
                }
              })
            }
          >
            Include all {listUsersToInclude.length} creators in the campaign
          </Button>
        </div>
      ) : null}

      <Button
        icon={<FontAwesomeIcon icon={faEnvelope} />}
        variant="outlined"
        onClick={() => setIsSendEmailPopup(true)}
      >
        Send campaign email
      </Button>

      <Button
        icon={<FontAwesomeIcon icon={faFileArrowDown} />}
        variant="outlined"
        onClick={() => {
          window.location.href = `${process.env.GRAPH_URL}/export/onboarding?campaigns=${campaignStub}`;
        }}
      >
        Export onboarding data
      </Button>

      <Button
        icon={<FontAwesomeIcon icon={faFileArrowDown} />}
        variant="outlined"
        onClick={() => {
          window.location.href = `${process.env.GRAPH_URL}/export/participants?campaigns=${campaignStub}`;
        }}
      >
        Export participant data
      </Button>

      <FilterStateMenu
        userStates={
          data?.users_in_lists.map(u => {
            if (!u.campaign_user) return undefined;
            return u.campaign_user.state;
          }) ?? []
        }
        userStateFilterBy={userStateFilterBy}
        toggleUserStateFilterBy={toggleUserStateFilterBy}
        resetUserStateFilterBy={resetUserStateFilterBy}
      />

      {isSendEmailPopup && data?.users_in_lists && (
        <SendEmailsPopup
          participants={data?.users_in_lists}
          onClose={() => setIsSendEmailPopup(false)}
        />
      )}
    </SActionButtons>
  );
};

const SActionButtons = styled.div`
  display: flex;
  gap: 20px;
`;
