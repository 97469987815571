import type { CampaignParticipantsQuery } from "./generated";

export const filterCampaignParticipantsData = (
  data?: CampaignParticipantsQuery,
  filterFn?: (user: CampaignParticipantsQuery["users_in_lists"][number]) => boolean
) => {
  if (!data) return data;

  let includedUsers = data.users_in_lists.filter(({ user, campaign_user }) => {
    if (!campaign_user) return true;
    if (!user?.excluded_from_lists) return true;
    return !(user.excluded_from_lists as number[]).some(id => id === campaign_user.campaign.list);
  });

  if (filterFn) {
    includedUsers = includedUsers.filter(user => filterFn(user));
  }

  return { ...data, users_in_lists: includedUsers };
};
