import { type FC, Fragment, useState } from "react";

import styled from "@emotion/styled";
import { faCheck, faDownload, faFolderOpen, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCampaignProgress, prettifyDate, prettifyNumber, unique } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { Table, type TableColumn, type TableRow } from "@relatable/ui/Table";
import { downloadCSV } from "@relatable/ui/TableCSVExport";
import { Link, useNavigate } from "react-router-dom";

import { useMarketsQuery } from "@relatable/gql/generated";
import { PeriodSelector } from "components/PeriodSelector";
import { PLATFORM_LABELS } from "lib/constants";

import { useAllCampaignsListQuery } from "./generated";

export const CampaignList: FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const [selectedCampaignStubs, setSelectedCampaignStubs] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<null | { start: string; end: string }>(null);
  const { data: markets } = useMarketsQuery();

  const { data: campaignList, loading: campaignListLoading } = useAllCampaignsListQuery({
    skip: !selectedPeriod,
    variables: {
      where: {
        campaign_content_settings: {
          publish_date_min: {
            _lte: selectedPeriod?.end
          },
          publish_date_max: {
            _gte: selectedPeriod?.start
          }
        }
      }
    }
  });

  const headers: TableColumn<typeof rows>[] = [
    {
      field: "campaignName",
      headerName: "Campaign",
      renderCell: ({ value, row }) => (
        <span style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            label=""
            checked={selectedCampaignStubs.includes(row.campaignStub)}
            style={{ marginRight: 0 }}
            onChange={e =>
              e
                ? setSelectedCampaignStubs(prev => unique([...prev, row.campaignStub]))
                : setSelectedCampaignStubs(prev => prev.filter(v => v !== row.campaignStub))
            }
          />
          <Link to={`/campaign/${row.campaignStub}`}>{value}</Link>
        </span>
      )
    },
    {
      field: "projectName",
      headerName: "Project",
      renderCell: ({ value, row }) =>
        value ? <Link to={`/project/${row.projectStub}/edit`}>{value}</Link> : "-"
    },
    {
      field: "campaignProgress",
      headerName: "Campaign progress",
      renderCell: ({ value }) => (
        <span style={{ whiteSpace: "nowrap" }}>{prettifyNumber(value, { percentages: true })}</span>
      )
    },
    {
      field: "projectProgress",
      headerName: "Project progress",
      renderCell: ({ value }) => (
        <span style={{ whiteSpace: "nowrap" }}>{prettifyNumber(value, { percentages: true })}</span>
      )
    },
    {
      field: "firstPostTs",
      headerName: "Start",
      renderCell: ({ value }) => <span style={{ whiteSpace: "nowrap" }}>{prettifyDate(value)}</span>
    },
    {
      field: "lastPostTs",
      headerName: "End",
      renderCell: ({ value }) => <span style={{ whiteSpace: "nowrap" }}>{prettifyDate(value)}</span>
    },
    {
      field: "platform",
      headerName: "Platform",
      renderCell: ({ value }) => PLATFORM_LABELS[value] ?? "-"
    },
    {
      field: "markets",
      headerName: "Markets",
      renderCell: ({ value }) => value.map(v => markets?.market.find(m => m.id === v)?.name ?? "-")
    },
    { field: "approvedCreatorCount", headerName: "Approved creators" },
    {
      field: "usableForMarketing",
      headerName: "Usable for marketing",
      renderCell: ({ value }) => (
        <FontAwesomeIcon
          icon={value ? faCheck : faTimes}
          style={{ color: value ? palette.primary.green : palette.primary.red }}
        />
      )
    },
    {
      field: "hasPaidAds",
      headerName: "Paid ads",
      renderCell: ({ value }) => (
        <FontAwesomeIcon
          icon={value ? faCheck : faTimes}
          style={{ color: value ? palette.primary.green : palette.primary.red }}
        />
      )
    },
    { field: "assetCount", headerName: "Assets" },
    {
      field: "budget",
      headerName: "Budget",
      renderCell: ({ value }) => `${prettifyNumber(value)} SEK`
    },
    {
      field: "projectAttachments",
      headerName: "Project links",
      renderCell: ({ value }) => (
        <div>
          {value.map(a => (
            <Fragment key={a.id}>
              <Link to={a.link} rel="noopener" target="_blank">
                {a.name}
              </Link>
              <br />
            </Fragment>
          ))}
        </div>
      )
    },
    {
      field: "briefUrl",
      headerName: "Brief",
      renderCell: ({ value }) =>
        value?.startsWith("http") ? (
          <Link to={value} target="_blank">
            Brief
          </Link>
        ) : (
          value ?? "-"
        )
    },
    {
      field: "proposalUrl",
      headerName: "Proposal",
      renderCell: ({ value }) =>
        value?.startsWith("http") ? (
          <Link to={value} target="_blank">
            Proposal
          </Link>
        ) : (
          value ?? "-"
        )
    },
    {
      field: "agreementUrl",
      headerName: "Agreement",
      renderCell: ({ value }) =>
        value?.startsWith("http") ? (
          <Link to={value} target="_blank">
            Agreement
          </Link>
        ) : (
          value ?? "-"
        )
    },
    {
      field: "attachmentUrl",
      headerName: "Attachment",
      renderCell: ({ value }) =>
        value?.startsWith("http") ? (
          <Link to={value} target="_blank">
            Attachment
          </Link>
        ) : (
          value ?? "-"
        )
    }
  ];

  const rows: TableRow[] =
    campaignList?.campaigns?.map(c => ({
      key: c.id,
      campaignStub: c.stub,
      campaignName: c.title,
      firstPostTs: c.campaign_content_settings_aggregate.aggregate?.min?.publish_date_min,
      lastPostTs: c.campaign_content_settings_aggregate.aggregate?.max?.publish_date_max,
      projectName: c.project?.name,
      projectStub: c.project?.stub,
      platform: c.platform,
      markets: c.market_ids,
      approvedCreatorCount: c.campaign_users.length,
      proposalUrl: c.project.proposal_url,
      agreementUrl: c.project.agreement_url,
      attachmentUrl: c.project.agreement_attachment_url,
      briefUrl: c.creative_brief_url,
      budget: c.max_budget ?? 0,
      assetCount: c.campaign_content_settings_aggregate.aggregate?.sum?.number_of_posts ?? 0,
      hasPaidAds: c.project.project_budgets.length > 0,
      usableForMarketing: c.project.can_use_for_marketing,
      projectAttachments: c.project.project_attachments,
      campaignProgress:
        getCampaignProgress(
          c.project.campaigns.find(c2 => c2.id === c.id)?.campaign_timeline || []
        ) ?? 0,
      projectProgress:
        c.project.campaigns.reduce(
          (acc, campaign) => acc + (getCampaignProgress(campaign.campaign_timeline) ?? 0),
          0
        ) / c.project.campaigns.length
    })) ?? [];

  return (
    <CampaignListContainer>
      <div className="header" style={{ marginBottom: 20 }}>
        <PeriodSelector startDate={2018} onSelect={setSelectedPeriod} />

        <Button
          icon={<FontAwesomeIcon icon={faFolderOpen} />}
          disabled={!selectedCampaignStubs.length}
          onClick={() => {
            navigate(`/campaign/${selectedCampaignStubs.join(",")}/participants`);
          }}
          style={{ marginLeft: 20 }}
        >
          Open aggregate participants
        </Button>

        <Button
          icon={<FontAwesomeIcon icon={faDownload} />}
          style={{ marginLeft: 20 }}
          onClick={() => {
            if (!selectedCampaignStubs.length) {
              snackbar.error("There are no campaigns selected!");
            } else {
              downloadCSV({
                filename: `campaign-list-${new Date().toISOString()}`,
                rows: rows.filter(row => selectedCampaignStubs.includes(row.campaignStub)),
                columns: headers
              });
            }
          }}
        >
          Export selected to CSV
        </Button>

        <Button
          icon={<FontAwesomeIcon icon={faDownload} />}
          style={{ marginLeft: 20 }}
          onClick={() => {
            downloadCSV({
              filename: `campaign-list-${new Date().toISOString()}`,
              rows,
              columns: headers
            });
          }}
        >
          Export all to CSV
        </Button>
      </div>

      <Table
        tableId="campaign-list"
        loading={campaignListLoading}
        columns={headers}
        rows={rows}
        sortable
        canSelectColumns
        stickyColumn
      />
    </CampaignListContainer>
  );
};

const CampaignListContainer = styled.div`
  display: flex;
  flex-direction: column;
  > .header {
    display: flex;
  }
`;
