import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { faLink, faUpload, faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, debounce } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import { UploadInput } from "components/ui/UploadInput";
import { useSignUploadUrlMutation } from "modules/generated";

import { useUpdateCampaignUserMutation } from "../generated";
import { CampaignOverviewDocument } from "./generated";

export const DynamicCell: FC<{
  customData;
  userKey: string;
  campaignUserId: number;
  fieldName: string;
}> = ({ campaignUserId, userKey, customData, fieldName }) => {
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(customData.fields?.[fieldName] || "");
  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => snackbar.success("User updated"),
    refetchQueries: [CampaignOverviewDocument],
    awaitRefetchQueries: true
  });

  const [signUploadUrlMutation] = useSignUploadUrlMutation({
    refetchQueries: [CampaignOverviewDocument],
    awaitRefetchQueries: true
  });

  const debouncedUpdateCampaignUser = useMemo(
    () =>
      debounce(
        (args: { customData; inputValue: string }) =>
          updateCampaignUser({
            variables: {
              campaignUserId,
              _set: {
                custom_data: {
                  ...args.customData,
                  fields: { ...(args.customData.fields || {}), [fieldName]: args.inputValue }
                }
              }
            }
          }),
        500
      ),
    [campaignUserId, fieldName, updateCampaignUser]
  );

  const handleFileChange = async files => {
    const file = files[0];
    if (!file) return;

    try {
      setLoading(true);
      const { data } = await signUploadUrlMutation({
        variables: {
          input: {
            fileName: `${userKey}/${file.name}`,
            fileType: file.type,
            prefix: "USER_ATTACHMENT"
          }
        }
      });

      if (!data?.signUploadUrl) {
        snackbar.error("Something went wrong when signing a file for the upload");
      } else {
        const { url, signedUploadUrl, signedDownloadUrl } = data.signUploadUrl;
        const response = await fetch(signedUploadUrl, {
          method: "PUT",
          body: file,
          headers: { "content-type": file?.type }
        });

        if (!response.ok) {
          snackbar.error("Something went wrong when uploading a file");
        } else {
          setInputValue(signedDownloadUrl);
          await debouncedUpdateCampaignUser({
            inputValue: url,
            customData
          });
        }
      }
    } catch {
      snackbar.error("Something went wrong when uploading a file");
    }
    setLoading(false);
  };

  const handleUpdateValue = (value: string) => {
    setInputValue(value);
    debouncedUpdateCampaignUser({
      inputValue: value,
      customData
    });
  };

  if (loading) {
    return (
      <Root>
        <CircularProgress size={20} />
      </Root>
    );
  }

  const isLink = inputValue.startsWith("http://") || inputValue.startsWith("https://");

  return (
    <Root>
      {isLink ? (
        <>
          <Button
            variant="text"
            tooltipText="Open link"
            target="_blank"
            onClick={() => handleUpdateValue("")}
            href={inputValue}
            style={{ minWidth: 36 }}
          >
            <FontAwesomeIcon icon={faLink} style={{ width: 30 }} />
          </Button>

          <Button
            variant="text"
            href="#"
            tooltipText="Remove link"
            onClick={() => handleUpdateValue("")}
            style={{ minWidth: 36 }}
          >
            <FontAwesomeIcon icon={faX} style={{ width: 20 }} color={palette.primary.red} />
          </Button>
        </>
      ) : (
        <>
          <TextInput onChange={handleUpdateValue} value={inputValue} style={{ minWidth: 150 }} />

          <UploadInput
            onChange={handleFileChange}
            customIcon={
              <FontAwesomeIcon icon={faUpload} style={{ width: 25 }} title="Upload file" />
            }
          />
        </>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
`;
