import {
  type Dispatch,
  Fragment,
  type SetStateAction,
  forwardRef,
  useImperativeHandle,
  useState
} from "react";

import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Platform_Enum, Project_Budget_Set_Input } from "@relatable/gql/generated-base";
import { sum } from "@relatable/helpers";
import { BUDGET_CATEGORIES } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";

import { LinearProgress } from "components/ui/LinearProgress";

import { Section, SubSection } from "../Section";
import {
  type ProjectDetailsQuery,
  useCreateProjectBudgetMutation,
  useDeleteProjectBudgetMutation,
  useUpdateProjectBudgetMutation
} from "../generated";
import { getChangedFields } from "../helpers";
import type { Budget, SectionRef } from "./types";

const getIsPlatformConflict = (platforms: Platform_Enum[], budgetType: string) =>
  (budgetType === BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value &&
    !platforms?.includes("instagram")) ||
  (budgetType === BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value &&
    !platforms?.includes("youtube")) ||
  (budgetType === BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value &&
    !platforms?.includes("tiktok")) ||
  (budgetType === BUDGET_CATEGORIES.INFLUENCER_MARKETING_SC.value &&
    !platforms?.includes("snapchat"));

export const BudgetSection = forwardRef<
  SectionRef,
  {
    project: ProjectDetailsQuery["projects"][number] | undefined;
    isSubmitting: boolean;
    budgets: Budget[];
    platforms: Platform_Enum[];
    onBudgetsChange: Dispatch<SetStateAction<Budget[]>>;
    totalInvoiceAmount: number | null;
  }
>(({ project, isSubmitting, budgets, platforms, onBudgetsChange, totalInvoiceAmount }, ref) => {
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);

  const [deleteBudget, deleteBudgetConfig] = useDeleteProjectBudgetMutation();
  const [createBudget, createBudgetConfig] = useCreateProjectBudgetMutation();
  const [updateBudget, updateBudgetConfig] = useUpdateProjectBudgetMutation();

  const validate = () => {
    const errors: string[] = [];
    if (budgets.some(budget => !budget.type)) errors.push("Budget is missing Activity*");
    setFieldErrors(errors);
    return errors;
  };

  const getChangedBudgets = () => {
    const budgetIdsToDelete: number[] = [];
    const budgetsToUpdate: { set: Project_Budget_Set_Input; projectBudgetId: number }[] = [];
    if (!project || !project.id) return { budgetIdsToDelete, budgetsToUpdate, budgetsToCreate: [] };

    project.project_budgets.forEach(async existing => {
      const budget = budgets.find(i => i.id === existing.id);
      if (!budget?.id) {
        budgetIdsToDelete.push(existing.id);
        return;
      }
      const set = getChangedFields({
        data: budget,
        initialData: existing
      });
      if (!Object.values(set).length) return;
      budgetsToUpdate.push({ set, projectBudgetId: budget.id });
    });

    return { budgetIdsToDelete, budgetsToUpdate, budgetsToCreate: budgets.filter(b => !b.id) };
  };

  const { budgetIdsToDelete, budgetsToCreate, budgetsToUpdate } = getChangedBudgets();

  const handleUpdate = async () => {
    if (validate().length) return;
    if (!project || !project.id) throw new Error("missing project");

    await Promise.all([
      ...budgetsToCreate.map(budget =>
        createBudget({
          variables: {
            object: {
              project_id: project.id,
              ...budget
            }
          }
        })
      ),
      ...budgetsToUpdate.map(({ projectBudgetId, set }) =>
        updateBudget({
          variables: {
            projectBudgetId,
            set
          }
        })
      ),
      ...budgetIdsToDelete.map(projectBudgetId => deleteBudget({ variables: { projectBudgetId } }))
    ]);
  };

  const isChanged = project
    ? Boolean(budgetIdsToDelete.length || budgetsToCreate.length || budgetsToUpdate.length)
    : false;

  const totalAllocated = Math.ceil(sum(budgets.map(b => b.revenue_allocation || 0)));
  const totalAllocatedMax = Math.round(
    (totalInvoiceAmount || 0) / (project?.project_invoicing_detail?.exchange_rate || 0)
  );

  const totalBudget = Math.ceil(
    budgets.reduce(
      (acc, item) =>
        acc +
        Math.round(
          (item.revenue_allocation || 0) * ((100 - (item.target_margin * 100 || 0)) / 100)
        ),
      0
    )
  );

  useImperativeHandle(ref, () => ({
    submit: handleUpdate,
    validate
  }));

  const isLoading =
    !project ||
    isSubmitting ||
    deleteBudgetConfig.loading ||
    createBudgetConfig.loading ||
    updateBudgetConfig.loading;

  return (
    <Section
      isChanged={isChanged}
      title="Budget"
      updatedAtNotImplemented
      updated_at={undefined}
      fieldErrors={fieldErrors}
      submitError={deleteBudgetConfig.error || createBudgetConfig.error || updateBudgetConfig.error}
      sidebar={
        <>
          <Note variant="info" label="Budgets related to the platforms">
            Each platform has dedicated influencer marketing budget type. Make sure to update the
            above Goals section to get up to date options in this section.
          </Note>

          {budgets.map(budget => {
            const renderErrorNote = (platform: Platform_Enum) => (
              <Note key={budget.id} variant="error" label="Invalid budget">
                {`You have budget dedicated to ${platform} platform when the project itself is not related to
                the ${platform}. Delete it or update the platforms in the section above.`}
              </Note>
            );
            if (
              budget.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value &&
              !platforms?.includes("instagram")
            ) {
              return renderErrorNote("instagram");
            }
            if (
              budget.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value &&
              !platforms?.includes("tiktok")
            ) {
              return renderErrorNote("tiktok");
            }
            if (
              budget.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value &&
              !platforms?.includes("youtube")
            ) {
              return renderErrorNote("youtube");
            }

            if (
              budget.type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_SC.value &&
              !platforms?.includes("snapchat")
            ) {
              return renderErrorNote("snapchat");
            }

            return null;
          })}
          {((platforms || []) as Platform_Enum[]).map(platform => {
            const renderErrorNote = (p: Platform_Enum) => (
              <Note key={platform} variant="error" label="Missing budget">
                {`You have selected ${p} platform in the section above but you're missing a budget dedicated to the ${p}. 
                Delete the ${p} platform in the section above or create a new budget in this section dedicated to ${p}`}
              </Note>
            );
            if (
              platform === "instagram" &&
              !budgets.some(({ type }) => type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value)
            ) {
              return renderErrorNote("instagram");
            }
            if (
              platform === "tiktok" &&
              !budgets.some(({ type }) => type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value)
            ) {
              return renderErrorNote("tiktok");
            }
            if (
              platform === "youtube" &&
              !budgets.some(({ type }) => type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value)
            ) {
              return renderErrorNote("youtube");
            }
            if (
              platform === "snapchat" &&
              !budgets.some(({ type }) => type === BUDGET_CATEGORIES.INFLUENCER_MARKETING_SC.value)
            ) {
              return renderErrorNote("snapchat");
            }

            return null;
          })}
        </>
      }
    >
      {budgets.map((budget, index) => {
        const handleChange = (partialData: Partial<typeof budget>) =>
          onBudgetsChange(prev =>
            prev.map((i, _index) => (index === _index ? { ...i, ...partialData } : i))
          );

        const budgetValue = Math.round(
          (budget.revenue_allocation || 0) * ((100 - (budget.target_margin * 100 || 0)) / 100)
        );
        const targetMargin = Math.round(budget.target_margin * 100 * 100) / 100;

        const canDelete = !budget.id || getIsPlatformConflict(platforms, budget.type);

        return (
          <Fragment key={index}>
            <SubSection>
              <Select
                required
                hideNone
                disabled={isLoading || Boolean(budget.id)}
                label="Activity"
                value={budget.type}
                onChange={type => type && handleChange({ type })}
                style={{ flexGrow: 1 }}
                options={Object.values(BUDGET_CATEGORIES).filter(({ value }) => {
                  if (budget.id) return true;
                  if (getIsPlatformConflict(platforms, value)) return false;
                  const cannotDuplicate = (
                    [
                      BUDGET_CATEGORIES.INFLUENCER_MARKETING_IG.value,
                      BUDGET_CATEGORIES.INFLUENCER_MARKETING_TT.value,
                      BUDGET_CATEGORIES.INFLUENCER_MARKETING_YT.value
                    ] as string[]
                  ).includes(value);
                  if (cannotDuplicate) {
                    // only allowed to be shown once, at the first occurence of the item
                    const firstIndex = budgets.findIndex(b => b.type === value);
                    if (firstIndex === -1) return true;
                    return index === firstIndex;
                  }
                  return true;
                })}
              />
              <TextInput
                required
                type="number"
                disabled={
                  isLoading || Boolean(budget.id && budget.type === BUDGET_CATEGORIES.AMPLIFY.value)
                }
                label="Revenue allocation (SEK)"
                value={String(Math.round(budget.revenue_allocation))}
                onChange={v => handleChange({ revenue_allocation: Math.round(Number(v)) })}
              />
            </SubSection>

            <SubSection>
              <TextInput
                required
                type="number"
                disabled={
                  isLoading || Boolean(budget.id && budget.type === BUDGET_CATEGORIES.AMPLIFY.value)
                }
                label="Target margin(%)"
                value={String(targetMargin)}
                onChange={v => handleChange({ target_margin: Math.round(Number(v)) / 100 })}
              />
              <TextInput
                type="number"
                label="Project budget"
                disabled={
                  isLoading || Boolean(budget.id && budget.type === BUDGET_CATEGORIES.AMPLIFY.value)
                }
                style={{ flexGrow: 1 }}
                value={String(budgetValue)}
                onChange={v => {
                  const diff = Number(v) - budgetValue;
                  if (Number.isNaN(diff)) return;
                  const weighted = (diff / (100 - targetMargin)) * 100;
                  const rounded = Math.round(budget.revenue_allocation + weighted);

                  handleChange({ revenue_allocation: rounded });
                }}
              />

              <FontAwesomeIcon
                icon={faTrash}
                style={{ cursor: "pointer" }}
                color={canDelete ? palette.primary.red : palette.gray[50]}
                onClick={() => {
                  if (!canDelete) return;
                  onBudgetsChange(prev => prev.filter((_, _index) => index !== _index));
                }}
              />
            </SubSection>
          </Fragment>
        );
      })}

      <div />
      <Button
        size="medium"
        icon={<FontAwesomeIcon icon={faAdd} />}
        style={{ margin: "auto" }}
        disabled={isLoading}
        onClick={() =>
          onBudgetsChange(prev => [...prev, { type: "", revenue_allocation: 0, target_margin: 0 }])
        }
      >
        Add budget
      </Button>

      <LinearProgress
        showLeft
        label="Total allocated"
        max={totalAllocatedMax}
        value={totalAllocated}
      />

      <SubSection>
        <TextInput
          disabled
          label="Project margin"
          onChange={() => null}
          value={
            totalAllocatedMax ? `${Math.round((1 - totalBudget / totalAllocatedMax) * 100)}%` : "0"
          }
        />
        <TextInput
          style={{ flexGrow: 1 }}
          disabled
          label="Total budget"
          value={String(totalBudget)}
          onChange={() => null}
        />
      </SubSection>
    </Section>
  );
});
