import { type FC, useState } from "react";

import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import { Popup } from "components/ui/Popup";

import { CampaignSnapchatStatsDocument, useUpdateSnapMutation } from "./generated";

export const SnapNoteCell: FC<{ campaignSnapId: number; note: string }> = props => {
  const snackbar = useSnackbar();
  const [isPopup, setIsPopup] = useState(false);
  const [note, setNote] = useState(props.note || "");
  const [updateSnap] = useUpdateSnapMutation({
    onCompleted: () => {
      snackbar.success("The note has been successfully updated");
      setIsPopup(false);
    },
    onError: () => {
      snackbar.error("Something went wrong");
    },
    refetchQueries: [CampaignSnapchatStatsDocument],
    awaitRefetchQueries: true
  });
  return (
    <>
      {note || "-"}
      &nbsp;
      <FontAwesomeIcon
        icon={faEdit}
        color={props.note ? palette.secondary.blue : palette.gray[50]}
        onClick={() => setIsPopup(true)}
        style={{ cursor: "pointer" }}
      />
      {isPopup && (
        <Popup
          onClose={() => setIsPopup(false)}
          actionLabel="Save"
          onAction={() =>
            updateSnap({
              variables: { id: props.campaignSnapId, set: { notes: note } }
            })
          }
          title="Prod note"
        >
          <TextInput multiline value={note} onChange={setNote} label="Note" />
        </Popup>
      )}
    </>
  );
};
