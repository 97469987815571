import type { FC } from "react";

import styled from "@emotion/styled";
import { faArrowRight as faArrowRightSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { normalizeState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

import { CONTENT_APPROVAL_STATE_MANAGER_LABELS } from "./utils";

interface BadgeProps {
  showIcon?: boolean;
  state?: CONTENT_APPROVAL_STATE | null;
}

export const StateBadge: FC<BadgeProps> = ({ showIcon, state }) => {
  return (
    <Root $state={normalizeState(state)}>
      {showIcon ? (
        <FontAwesomeIcon
          style={{ marginRight: 5, transform: "translateY(-1px)", fontSize: 11 }}
          icon={faArrowRightSolid}
        />
      ) : null}
      {CONTENT_APPROVAL_STATE_MANAGER_LABELS[normalizeState(state)]}
    </Root>
  );
};

const Root = styled.div<{ $state: CONTENT_APPROVAL_STATE }>`
  background: ${({ $state }) => {
    switch ($state) {
      case CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD:
      case CONTENT_APPROVAL_STATE.MANAGER_REJECTED:
        return palette.gray[10];
      case CONTENT_APPROVAL_STATE.MANAGER_REVIEWING:
        return palette.primary.red;
      case CONTENT_APPROVAL_STATE.CLIENT_REVIEWING:
        return palette.secondary.purple;
      case CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING:
        return palette.primary.green;
      case CONTENT_APPROVAL_STATE.CLIENT_REJECTED:
        return palette.primary.gold;
      case CONTENT_APPROVAL_STATE.CLIENT_APPROVED:
        return palette.primary.blue;
      default:
        throw new Error("Invalid state");
    }
  }};
  color: ${({ $state }) => {
    switch ($state) {
      case CONTENT_APPROVAL_STATE.CREATOR_INITIAL_UPLOAD:
      case CONTENT_APPROVAL_STATE.MANAGER_REJECTED:
        return palette.gray[90];
      default:
        return palette.gray.white;
    }
  }};
  padding: 4px 12px;
  border-radius: 16px;
`;
