import type { CSSProperties, FC } from "react";

import { faArrowLeft, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useNavigate } from "react-router-dom";

export const NavigationButton: FC<{
  className?: string;
  direction?: "back" | "forward";
  nextStateIsNotReact?: boolean;
  pathname?: string;
  style?: CSSProperties;
}> = ({ className, direction = "back", nextStateIsNotReact = false, pathname, style }) => {
  const navigate = useNavigate();

  let action: () => void;

  const isForwardDirection = direction === "forward";

  if (pathname) {
    action = nextStateIsNotReact
      ? // biome-ignore lint/suspicious/noAssignInExpressions:
        () => (window.location.pathname = pathname)
      : () => navigate(pathname);
  } else {
    action = isForwardDirection ? () => navigate(1) : () => navigate(-1);
  }

  const directionIcon = isForwardDirection ? faArrowRight : faArrowLeft;

  return (
    <Button
      style={{ marginBottom: 10, ...style }}
      onClick={action}
      className={className}
      variant="text"
    >
      <FontAwesomeIcon
        color={palette.gray.black}
        icon={directionIcon}
        style={{ marginRight: "10px" }}
      />
      {isForwardDirection ? "Forward" : "Back"}
    </Button>
  );
};
