import { forwardRef, useImperativeHandle, useState } from "react";

import { faAdd, faArrowsRotate, faLink, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Campaign } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Note } from "@relatable/ui/Note";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import { CURRENCIES } from "src/lib/constants";

import { Section, SubSection } from "../Section";
import { getChangedFields } from "../helpers";
import {
  useCampaignDetailsQuery,
  useDownloadBriefAttachmentMutation,
  useUpdateCampaignMutation
} from "./generated";
import type { SectionRef } from "./types";

export type AgreementFields = Pick<
  Campaign,
  | "open_to_join"
  | "agreement_penalty"
  | "product_range"
  | "agreement_attachment_url"
  | "creative_brief_url"
>;

export const AgreementSection = forwardRef<SectionRef<AgreementFields>, { campaignStub: string }>(
  ({ campaignStub }, ref) => {
    const [replacingWithBrief, setReplacingWithBrief] = useState(false);
    const [fields, setFields] = useState<Partial<AgreementFields>>({});
    const snackbar = useSnackbar();

    const { data, loading } = useCampaignDetailsQuery({
      variables: { stub: campaignStub },
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ campaigns }) => {
        const [c] = campaigns;
        setFields({
          open_to_join: c.open_to_join,
          agreement_penalty: c.agreement_penalty,
          product_range: c.product_range,
          agreement_attachment_url: c.agreement_attachment_url,
          creative_brief_url: c.creative_brief_url
        });
      }
    });

    const [downloadBriefAttachment] = useDownloadBriefAttachmentMutation();

    const [updateCampaignByIdMutation, updateCampaignByIdMutationOptions] =
      useUpdateCampaignMutation();

    const campaign = data?.campaigns?.[0];

    const changedFields = getChangedFields({ data: fields, initialData: campaign });
    const isChanged = campaign ? Boolean(Object.values(changedFields).length) : false;

    const handleUpdate = async () => {
      if (!campaign) throw new Error("Missing campaign");

      if (!isChanged) return;

      if (changedFields.agreement_attachment_url) {
        const url = new URL(changedFields.agreement_attachment_url);
        changedFields.agreement_attachment_url = url.origin + url.pathname;
      }

      await updateCampaignByIdMutation({
        variables: {
          id: campaign.id,
          set: {
            ...changedFields,
            updated_at: new Date().toISOString()
          }
        }
      });
    };

    useImperativeHandle(ref, () => ({
      submit: handleUpdate,
      validate: () => [],
      getFields: () => fields,
      setFields
    }));

    const handleFieldChange = (partialData: Partial<AgreementFields>) => {
      setFields(p => ({ ...p, ...partialData }));
    };

    const handleReplaceAttachmentWithBrief = async () => {
      const creativeBriefUrl = fields.creative_brief_url ?? "";

      if (!creativeBriefUrl) {
        snackbar.error("Creative brief is missing");
        return null;
      }

      const uploadRes = await downloadBriefAttachment({
        variables: {
          url: creativeBriefUrl
        }
      }).catch(err => {
        snackbar.error(err.message ?? "An error happened!");
        return null;
      });

      return uploadRes?.data?.downloadBriefAttachment?.url ?? null;
    };

    const handleReplaceWithBrief = async () => {
      setReplacingWithBrief(true);
      try {
        const url = await handleReplaceAttachmentWithBrief();
        if (url) {
          handleFieldChange({ agreement_attachment_url: url });
          snackbar.info(
            "An agreement's appendix successfully uploaded, don't forget to save the form"
          );
        }
      } finally {
        setReplacingWithBrief(false);
      }
    };

    const [penaltyValue, penaltyCurrency]: (string | undefined)[] =
      fields.agreement_penalty?.split(" ") || [];

    return (
      <Section
        title="Agreement"
        updated_at={undefined}
        updatedAtNotImplemented
        fieldErrors={[]}
        isChanged={isChanged}
        submitError={updateCampaignByIdMutationOptions.error}
        sidebar={
          <>
            <Note variant="info" label="Product range">
              If it’s agreed with the client to have a competitor clause with creators, please fill
              in agreed product areas that compete with the client. For example, Roborock is the
              client then fill in vacuum cleaners in the product range.
              <br /> If no such clause is agreed please leave the product range empty.
            </Note>

            <Note variant="info" label="How to update an agreement?">
              If a talent already has opened an agreement they need to re-submit the previous step
              again in order to be able to see the changes
            </Note>

            <Note variant="info" label="Brief">
              Brief is attached to the creator&apos;s agreement.
            </Note>

            {!fields.agreement_attachment_url && !loading && (
              <Note variant="error" label="Missing brief">
                This campaign misses an agreement&apos;s brief. Please upload one or else creators
                won&apos;t be able to see the agreements.
              </Note>
            )}
          </>
        }
      >
        <TextInput
          label="Link to creative brief"
          value={fields.creative_brief_url || ""}
          onChange={v => handleFieldChange({ creative_brief_url: v })}
        />

        <Button
          variant="contained"
          style={{ width: "100%" }}
          isLoading={replacingWithBrief}
          onClick={handleReplaceWithBrief}
          icon={
            <FontAwesomeIcon icon={fields?.agreement_attachment_url ? faArrowsRotate : faAdd} />
          }
        >
          {fields?.agreement_attachment_url ? "Refresh brief" : "Add brief"}
        </Button>

        <TextInput
          disabled={loading}
          label="Product range"
          value={fields.product_range || ""}
          onChange={v => handleFieldChange({ product_range: v })}
        />

        <SubSection>
          <Select
            disabled={loading}
            style={{ width: "100%" }}
            label="Penalty"
            value={Number(penaltyValue)}
            options={[500, 1000, 5000, 10000, 50000, 100000].map(v => ({
              label: String(v),
              value: v
            }))}
            onChange={v => {
              if (!v) {
                handleFieldChange({ agreement_penalty: null });
                return;
              }
              handleFieldChange({
                agreement_penalty: fields.agreement_penalty
                  ? fields.agreement_penalty.replace(penaltyValue, String(v))
                  : `${v} SEK`
              });
            }}
          />

          <Select
            hideNone
            disabled={loading}
            style={{ width: "100%" }}
            label="Penalty currency"
            value={penaltyCurrency}
            options={Object.values(CURRENCIES).map(currency => ({
              label: currency,
              value: currency
            }))}
            onChange={v => {
              if (!v) return;
              handleFieldChange({
                agreement_penalty: fields.agreement_penalty
                  ? fields.agreement_penalty.replace(penaltyCurrency, v)
                  : `5000 ${v}`
              });
            }}
          />
        </SubSection>

        <SubSection>
          <Button
            variant="outlined"
            icon={<FontAwesomeIcon icon={faLink} />}
            style={{ width: "100%" }}
            disabled={!fields.agreement_attachment_url}
            onClick={() => {
              if (fields.agreement_attachment_url) {
                window.open(fields.agreement_attachment_url, "_blank")?.focus();
              }
            }}
          >
            Review attached brief
          </Button>

          <Button
            style={{ width: "100%" }}
            variant="outlined"
            icon={<FontAwesomeIcon icon={faTrash} />}
            disabled={!fields.agreement_attachment_url}
            onClick={() => handleFieldChange({ agreement_attachment_url: null })}
          >
            Delete brief
          </Button>
        </SubSection>

        <SubSection style={{ display: "flex", flexDirection: "column" }}>
          <Checkbox
            style={{ marginRight: "auto" }}
            disabled={loading}
            label="Onboarding open"
            checked={Boolean(fields.open_to_join)}
            onChange={v => handleFieldChange({ open_to_join: v })}
          />
        </SubSection>
      </Section>
    );
  }
);
