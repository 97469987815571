import styled from "@emotion/styled";
import { faCheckToSlot, faUserCheck, faXmarkToSlot } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clientApproved,
  clientReviewed,
  creatorDone,
  getContentAggregateState,
  managerApproved,
  managerReviewed,
  normalizeState
} from "@relatable/helpers/approvalLogs";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import type {
  ContentMediaQuery,
  ContentOverviewQuery,
  ContentOverviewSettingsQuery
} from "../generated";

interface BottomFeedbackBarProps {
  activeContent: ContentOverviewQuery["activeContents"][number] | null;
  medias: ContentMediaQuery["medias"];
  contentSetting: ContentOverviewSettingsQuery["setting"];
  onNotifyCreator: () => void;
  onAcceptClientReview: () => void;
}

export const BottomFeedbackBar: React.FC<BottomFeedbackBarProps> = ({
  activeContent,
  medias,
  contentSetting,
  onNotifyCreator,
  onAcceptClientReview
}) => {
  if (!contentSetting || !activeContent) return null;

  const allContentStates = (() => {
    const states: CONTENT_APPROVAL_STATE[] = [];

    if (activeContent.new_caption) {
      states.push(normalizeState(activeContent.new_caption.content_preapproval_logs[0]?.state));
    }

    medias
      ?.filter(m => !m.deleted)
      .forEach(media => {
        states.push(normalizeState(media.content_preapproval_logs[0]?.state));
      });

    return states;
  })();

  const isEverythingReviewedInternally = allContentStates.every(s => managerReviewed(s));
  const isEverythingReviewedByClient = allContentStates.every(s => clientReviewed(s));

  const isEnoughContentApprovedByClient = (() => {
    const approvedMedia =
      medias?.filter(
        media => !media.deleted && clientApproved(media.content_preapproval_logs[0]?.state)
      ) ?? [];
    return approvedMedia.length >= contentSetting.number_of_posts;
  })();

  const stateOverview = (() => {
    const itemStates: (string | null | undefined)[] = [];
    if (activeContent.new_caption) {
      itemStates.push(activeContent.new_caption.content_preapproval_logs[0]?.state);
    }
    if (activeContent.campaign_preapproval_content_medias.length > 0) {
      itemStates.push(
        ...activeContent.campaign_preapproval_content_medias.map(
          m => m.content_preapproval_logs[0]?.state
        )
      );
    }

    return getContentAggregateState(itemStates);
  })();

  return (
    <Root>
      <div className="left">
        <Button
          icon={<FontAwesomeIcon icon={faXmarkToSlot} />}
          disabled={!isEverythingReviewedInternally}
          variant="outlined"
          onClick={onNotifyCreator}
          tooltipText={
            isEverythingReviewedInternally
              ? ""
              : "All content needs to be reviewed in order to proceed"
          }
        >
          <strong>Notify creator via email</strong>
        </Button>
        <Button
          icon={<FontAwesomeIcon icon={faCheckToSlot} />}
          style={{ marginLeft: 10 }}
          variant="contained"
          color="success"
          onClick={onAcceptClientReview}
          tooltipText={clientApproved(stateOverview) ? "Already approved" : undefined}
          disabled={!creatorDone(stateOverview) || clientApproved(stateOverview)}
        >
          Mark content ready without client&apos;s approval
        </Button>
      </div>

      <div className="right">
        {managerApproved(stateOverview) && !clientReviewed(stateOverview) ? (
          <span style={{ marginRight: 15 }}>Send notification email from main dashboard.</span>
        ) : null}

        <SendButton
          icon={<FontAwesomeIcon icon={faUserCheck} />}
          disabled={!(clientApproved(stateOverview) && isEverythingReviewedByClient)}
          onClick={onAcceptClientReview}
          tooltipText={(() => {
            if (clientApproved(stateOverview)) {
              return "Already approved";
            }
            if (!isEverythingReviewedByClient) {
              return "All content needs to be reviewed by the Client in order to proceed";
            }
            if (!isEnoughContentApprovedByClient) {
              return "Warning: The Client didn't review enough content. Check content settings of the campaign.";
            }
            return "";
          })()}
        >
          Accept Client&apos;s review and mark as ready
        </SendButton>
      </div>
    </Root>
  );
};

const Root = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: ${palette.gray.white};
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background: ${palette.gray.white};
  border: 1px solid ${palette.gray[20]};
  margin: 0 -30px;

  > .left {
    text-wrap: nowrap;
  }

  > .right {
    color: ${palette.gray[60]};
    text-wrap: nowrap;
  }
`;

const SendButton = styled(Button)`
  margin-left: 10px;
`;
