import type { FC } from "react";

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPlatformLabel } from "@relatable/gql";
import {
  getUserLabel,
  getUserLink,
  getUserPlatform,
  getUserProfilePicture
} from "@relatable/helpers/user";
import { Avatar } from "@relatable/ui/Avatar";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

import { getPlatformIcon } from "lib/campaigns";

import type { CreatorListItemsQuery } from "../generated";
import { CreatorListItemButtons } from "./CreatorListItemButtons";

const Root = styled.header`
  display: flex;
  z-index: 2;
  position: relative;
  justify-content: space-between;

  > .left {
    position: relative;
    padding-left: 35px;

    > .profile-picture {
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background: ${palette.gray[20]};
    }

    > .username {
      display: block;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
    }

    > .details {
      font-size: 12px;
      color: ${palette.gray[60]};

      > a {
        color: inherit;
      }
    }
  }
`;

const countryNames = new Intl.DisplayNames(["en"], { type: "region" });

export const CreatorListItemHeader: FC<{
  user: CreatorListItemsQuery["users"][number];
  showListSelector?: boolean;
  showPlatform?: boolean;
  lowRatedUser: CreatorListItemsQuery["users"][number]["campaign_users"][number] | null;
}> = ({ user, showListSelector, showPlatform, lowRatedUser }) => {
  const platformName = getUserPlatform(user);

  return (
    <Root>
      <div className="left">
        <span style={{ opacity: lowRatedUser ? 0.2 : 1 }} className="profile-picture">
          <Avatar src={getUserProfilePicture(user)} size={30} />
        </span>

        <Link
          style={{
            fontWeight: "bold",
            color: lowRatedUser ? palette.gray.black : palette.primary.red
          }}
          to={getUserLink(user)}
          target="_blank"
          rel="noreferrer"
          className="username"
        >
          {getUserLabel(user)}
        </Link>

        <span style={{ opacity: lowRatedUser ? 0.1 : 1 }} className="details">
          {Boolean(user.country) && <span>{countryNames.of(user.country as string)}</span>}
          {user.country && showPlatform ? ", " : ""}
          {showPlatform && (
            <Link to={getUserLink(user)} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={getPlatformIcon(platformName)} />{" "}
              {getPlatformLabel(platformName)}
            </Link>
          )}
        </span>
      </div>

      <CreatorListItemButtons
        lowRatedUser={lowRatedUser}
        user={user}
        showListSelector={showListSelector}
      />
    </Root>
  );
};
