import type { FC } from "react";

import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";

import { useCopyToClipboard } from "hooks/useCopyToClipboard";

export const CopyIcon: FC<{ text: string | number | null | undefined }> = ({ text }) => {
  const { copyToClipboard } = useCopyToClipboard();

  return (
    <FontAwesomeIcon
      onClick={() => {
        if (!text) return;
        copyToClipboard(text);
      }}
      color={palette.gray[50]}
      icon={faCopy}
      style={{ cursor: text ? "pointer" : "not-allowed", marginLeft: "auto", marginRight: 10 }}
    />
  );
};
