import { type FC, useState } from "react";

import { useApolloClient } from "@apollo/client";
import styled from "@emotion/styled";
import { faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";
import { faDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "@mui/material";
import {
  getUserLabel,
  getUserLink,
  getUserPlatform,
  getUserProfilePicture
} from "@relatable/helpers/user";
import { Avatar } from "@relatable/ui/Avatar";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { Link } from "react-router-dom";

import { animateOpacity } from "lib/styled";
import { useUpdateUserMutation } from "modules/generated";
import { getPlatformIcon } from "src/lib/campaigns";

import { UserDocument, type UserQuery, useUpdateUserDataMutation } from "../generated";
import { Section } from "../user.styled";
import { CopyIcon } from "./CopyIcon";
import { EditSectionIcon } from "./EditSectionIcon";
import { getProfileExtraFields } from "./ProfileSectionExtraFields";

const StyledTextInput = styled(TextInput)`
  width: 100%;
  margin: auto;
  animation: ${animateOpacity} 200ms ease;
`;

const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${animateOpacity} 200ms ease;
`;

export const ProfileSection: FC<{
  user: NonNullable<UserQuery["users_by_pk"]>;
}> = ({ user }) => {
  const snackbar = useSnackbar();
  const apolloClient = useApolloClient();

  const email = user.email || null;

  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [emailToUpdate, setEmailToUpdate] = useState(email);
  const [firstNameToUpdate, setFirstNameToUpdate] = useState(user.first_name);

  const [updateUser, { loading }] = useUpdateUserMutation({
    refetchQueries: [UserDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      snackbar.success("User updated successfully");
      setIsEditing(false);
    },
    onError: () => apolloClient.refetchQueries({ include: [UserDocument] })
  });

  const [updateUserData, updateUserDataOptions] = useUpdateUserDataMutation({
    refetchQueries: [UserDocument],
    awaitRefetchQueries: true,
    onCompleted: () => {
      snackbar.success("User updated successfully");
    },
    onError: () => snackbar.error("User failed to update. Maybe there is no valid business token.")
  });

  const handleSubmit = async () => {
    if (email === emailToUpdate && user.first_name === emailToUpdate) {
      setIsEditing(false);
      return;
    }

    await updateUser({
      variables: {
        id: user.id,
        set: {
          updated_at: new Date().toISOString(),
          email: emailToUpdate,
          first_name: firstNameToUpdate
        }
      }
    });
  };

  const platform = getUserPlatform(user);

  let fields = [
    {
      header: "First name",
      value: (isEditing ? firstNameToUpdate : user.first_name) || "",
      onChange: setFirstNameToUpdate
    },
    {
      header: "Last name",
      value: user.user_profile?.last_name
    },
    { header: "Username", value: getUserLabel(user) },
    {
      header: "Email",
      value: isEditing ? emailToUpdate : email,
      onChange: setEmailToUpdate
    },
    ...getProfileExtraFields({ user })
  ];

  const canShowMore = fields.length > 8 && !isEditing && !expanded;

  if (!expanded) {
    fields = fields.slice(0, 8);
  }

  return (
    <Section style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <div style={{ display: "flex", gap: 10, alignItems: "center", width: "100%" }}>
        <Avatar size={40} src={getUserProfilePicture(user) || null} />
        <Link to={getUserLink(user)} target="_blank" rel="noreferrer">
          <FontAwesomeIcon
            color={palette.gray[50]}
            style={{ fontSize: 20 }}
            icon={getPlatformIcon(platform)}
          />
        </Link>
        <h2 style={{ fontWeight: 500, marginRight: "auto" }}>
          {user?.first_name || getUserLabel(user) || ""}
        </h2>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          color={palette.gray[50]}
          icon={faArrowsRotate}
          spin={updateUserDataOptions.loading}
          onClick={() =>
            updateUserData({
              variables: {
                params: {
                  ytUsername: user.user_youtube?.username,
                  ytChannelId: user.user_youtube?.channel_id,
                  igUsername: user.user_instagram?.username,
                  ttUsername: user.user_tiktok?.username,
                  scUsername: user.user_snapchat?.username
                }
              }
            })
          }
        />
        <EditSectionIcon isEditing={isEditing} onToggle={() => setIsEditing(p => !p)} />
      </div>
      <Divider />

      <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: 15 }}>
        {fields.map(i => {
          if (isEditing) {
            if (!i.onChange) return null;
            return (
              <StyledTextInput
                key={i.header}
                size="small"
                onChange={i.onChange}
                label={i.header}
                value={(i.value as string) || ""}
              />
            );
          }
          return (
            <ValueBox key={i.header}>
              <span style={{ color: palette.gray[50], fontSize: 12 }}>{i.header}</span>
              <div style={{ display: "flex" }}>
                <b style={{ maxWidth: 150, overflow: "hidden", textOverflow: "ellipsis" }}>
                  {i.value || "-"}
                </b>
                {typeof i.value === "string" ? <CopyIcon text={i.value} /> : null}
              </div>
            </ValueBox>
          );
        })}

        {isEditing && (
          <Button isLoading={loading} onClick={handleSubmit} color="secondary">
            Save
          </Button>
        )}
      </div>

      {canShowMore && (
        <Button onClick={() => setExpanded(true)} variant="text">
          <FontAwesomeIcon style={{ cursor: "pointer", marginRight: 6 }} icon={faDown} />
          Show more
        </Button>
      )}
    </Section>
  );
};
