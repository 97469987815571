import styled from "@emotion/styled";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";

interface ReviewBarIconProps {
  value: null | "approved" | "declined";
  inDropdown?: boolean;
  disabled?: boolean;
}

export const ReviewBarIcon: React.FC<ReviewBarIconProps> = props => {
  const { value, inDropdown, disabled } = props;

  if (value === null) {
    return (
      <Root>
        <span className="label">Remove review</span>
        <span className={`circle ${disabled ? "disabled" : value}`}>
          <FontAwesomeIcon icon={faX} />
        </span>
      </Root>
    );
  }

  return (
    <Root>
      {inDropdown ? <span className="label">Change to</span> : null}
      <span className={`circle ${disabled ? "disabled" : value}`}>
        <FontAwesomeIcon icon={value === "approved" ? faCheck : faTimes} />
      </span>
    </Root>
  );
};

const Root = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  > .label {
    margin-right: 10px;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 20px;
    background: ${palette.gray.whiteOff};
    color: ${palette.gray[60]};

    &.approved {
      background: ${palette.secondary.green};
      color: ${palette.gray.white};
    }

    &.declined {
      background: ${palette.secondary.gold};
      color: ${palette.gray.white};
    }
  }
`;
