import type { FC } from "react";

import styled from "@emotion/styled";
import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { palette } from "@relatable/ui/Palette";

const Root = styled.div`
  > ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 90%;
    margin: 0 auto;
    margin-top: 18px;

    > li {
      display: block;

      > .button {
        position: relative;
        border: 2px solid ${palette.gray[10]};
        border-radius: 20px;
        width: 100px;
        height: 100px;
        font-size: 42px;

        &.inactive {
          opacity: 0.3;
        }

        &.active {
          border-color: ${palette.primary.red};
        }

        > .number {
          position: absolute;
          right: 7px;
          bottom: 7px;
          font-size: 12px;
          color: ${palette.gray[40]};
        }
      }

      > .title {
        max-width: 100px;
        margin: 15px 0;
        height: 36px;
      }
    }
  }
`;

export const ScoreStep: FC<{
  steps: { value: number; title: string; icon: IconDefinition }[];
  value: number | null;
  onChange(value: number): void;
}> = ({ steps, value, onChange }) => {
  return (
    <Root>
      <ul>
        {steps.map(step => (
          <li key={step.value}>
            <Button
              variant="text"
              className={`button ${step.value === value ? "active" : "inactive"}`}
              onClick={() => onChange(step.value)}
            >
              <FontAwesomeIcon icon={step.icon} />
              <span className="number">{step.value}</span>
            </Button>
            <div className="title">{step.title}</div>
          </li>
        ))}
      </ul>
    </Root>
  );
};
