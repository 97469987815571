import type { FC } from "react";

import styled from "@emotion/styled";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Campaign_Story_Clip_Set_Input } from "@relatable/gql/generated-base";
import { Checkbox } from "@relatable/ui/Checkbox";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { TextInput } from "@relatable/ui/TextInput";

import { Loader } from "@relatable/ui/Loader";
import { ContentDisplay } from "modules/campaign/common/ContentDisplay";

import { type StoryQuery, useUpdateStoryClipMutation } from "./generated";

export const StoryClip: FC<{
  loading?: boolean;
  contentSetting: StoryQuery["contentSettings"][number];
  clip: StoryQuery["clips"][number];
  shortlinks?: string[] | null;
  isStoryApproved: boolean;
}> = ({ clip, contentSetting, isStoryApproved, loading, shortlinks = [] }) => {
  const [updateStoryClip] = useUpdateStoryClipMutation();

  const handleStoryUpdate = (set: Campaign_Story_Clip_Set_Input) => {
    const { id, ...clipToUpdate } = set;

    updateStoryClip({
      variables: {
        clipId: clip.id,
        _set: clipToUpdate
      },
      optimisticResponse: {
        __typename: "Mutation",
        update_campaign_story_clip: {
          __typename: "campaign_story_clip_mutation_response",
          returning: [{ ...clip, ...clipToUpdate }]
        }
      }
    });
  };

  if (loading) {
    return <Loader />;
  }

  const isClipApproved = Boolean(clip.content_setting_id);

  return (
    <div style={{ marginTop: 15, marginBottom: 15 }}>
      <Title>Clip created at {new Date(clip.created_at || "").toLocaleString()}</Title>
      <Container>
        <ContentDisplay
          key={clip.id}
          url={clip.url || ""}
          thumbnail={clip.thumbnail || clip.url || ""}
          status={isClipApproved ? "approved" : ""}
          isVideo={Boolean(clip.isVideo)}
        />
        <StoryStateContainer>
          <Checkbox
            style={{ marginLeft: 0 }}
            onChange={() =>
              handleStoryUpdate({
                content_setting_id: clip.content_setting_id ? null : contentSetting.id
              })
            }
            label={
              isClipApproved ? (
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  style={{ marginLeft: 10 }}
                  color={palette.primary.green}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faThumbsDown}
                  style={{ marginLeft: 10 }}
                  color={palette.secondary.gold}
                />
              )
            }
            checked={isClipApproved}
          />

          {isStoryApproved && isClipApproved ? (
            <>
              <TextInput
                label="Impressions"
                type="number"
                value={String(clip.impressions || "")}
                onChange={value => handleStoryUpdate({ impressions: Math.round(Number(value)) })}
              />

              <TextInput
                label="Actual reach"
                type="number"
                value={String(clip.actual_reach || "")}
                onChange={value => handleStoryUpdate({ actual_reach: Math.round(Number(value)) })}
              />

              <Select
                label="Tracking url"
                onChange={track_url => handleStoryUpdate({ track_url })}
                value={clip.track_url || ""}
                options={
                  Array.isArray(shortlinks)
                    ? shortlinks.map(url => ({
                        label: url,
                        value: url
                      }))
                    : []
                }
              />
            </>
          ) : null}
        </StoryStateContainer>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  margin: 20px 0px;
  padding-right: 50px;
`;

const Title = styled.h5`
  font-size: 1.34rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
  font-weight: 400;
  margin-bottom: 10px;
`;

const StoryStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
