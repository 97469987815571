import { type FC, useCallback, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "@mui/material";
import { palette } from "@relatable/ui/Palette";
import { TextInputWithIcon } from "@relatable/ui/TextInput";
import { useOnOutsideClick } from "@relatable/ui/hooks/useOnOutsideClick";
import { NavLink } from "react-router-dom";

import { type SearchResult, useSearch } from "./useSearch";

export const SearchBox: FC = () => {
  const [opened, setOpened] = useState(false);

  const handleDropdownClose = useCallback(() => setOpened(false), []);
  const dropdownRef = useOnOutsideClick(handleDropdownClose);

  const [getSearchResults, { loading, projects, campaigns, lists, users }] = useSearch();

  const handleTextChangeDebounced = useMemo(
    () =>
      debounce(async (text: string) => {
        await getSearchResults(text);
      }, 700),
    [getSearchResults]
  );

  const handleInput = (text: string) => {
    if (text.length > 0) {
      handleTextChangeDebounced(text);
      setOpened(true);
    }
  };

  const hasResults = projects.length + campaigns.length + lists.length + users.length > 0;

  const renderSearchResultPart = (title: string, searchResultRows: SearchResult[] = []) => {
    if (searchResultRows.length > 0) {
      return (
        <>
          <SearchEntriesLabel>{title}</SearchEntriesLabel>
          {searchResultRows.map(({ ...item }) => (
            <NavLink
              key={item.id}
              to={{ pathname: item.url, search: item.search }}
              state={{ from: "searchbox" }}
              className="search-entry"
              onClick={() => setOpened(false)}
            >
              {item.name}
            </NavLink>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <SearchContainer>
      <TextInputWithIcon
        style={{ marginTop: 10 }}
        {...(opened && { style: { borderRadius: "8px 0px" } })}
        {...(hasResults && { onClick: () => setOpened(true) })}
        onChange={handleInput}
        placeholder="Search"
        iconPosition="start"
        icon={<FontAwesomeIcon icon={faSearch} />}
      />
      {opened ? (
        <div ref={dropdownRef}>
          <SearchResults>
            {hasResults ? (
              <>
                {renderSearchResultPart("Projects", projects)}
                {renderSearchResultPart("Campaigns", campaigns)}
                {renderSearchResultPart("Lists", lists)}
                {renderSearchResultPart("Users", users)}
              </>
            ) : (
              <div style={{ marginTop: 20 }}>
                {loading ? "Loading results..." : "No results found..."}
              </div>
            )}
          </SearchResults>
        </div>
      ) : null}
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  position: relative;
`;

const SearchResults = styled.div`
  background-color: ${palette.gray.white};
  position: absolute;
  top: 100%;
  z-index: 100;
  right: 0;
  width: 350px;
  border-radius: 0px 0px 8px 8px;
  border-style: solid;
  border-width: 1px;
  padding: 16px;
  padding-top: 0px;
  overflow-y: scroll;
  max-height: 50vh;

  .search-entry {
    margin-top: 5px;
    color: ${palette.gray.black};
    display: block;
  }
`;

const SearchEntriesLabel = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
  margin-top: 20px;
`;
