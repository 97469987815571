import { useRef, useState } from "react";

import styled from "@emotion/styled";
import { faListCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CircularProgress,
  Button as MaterialButton,
  Card as MaterialCard,
  Popover
} from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import {
  ListsOfCreatorDocument,
  useAddUserToListMutation,
  useListsOfCreatorQuery,
  useSearchListsQuery
} from "../generated";
import { ListEditorItem } from "./ListEditorItem";

const Root = styled.div`
  display: inline-block;
`;

const Button = styled(MaterialButton)`
  && {
    height: 30px;
    border-radius: 15px;
    margin-left: 7px;
    padding: 0;
    min-width: 32px;
  }
`;

const Card = styled(MaterialCard)`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 480px;
  padding: 8px 16px;
  flex: 0 0 auto;

  > .scroll-container {
    flex-grow: 1;
    overflow-y: scroll;

    > h5 {
      margin-top: 15px;
      margin-bottom: 4px;
      font-size: 12px;
      color: ${palette.primary.red};

      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

export const ListEditor: React.FC<{
  userId: number;
  userLabel: string;
  platform: Platform_Enum;
}> = ({ userId, userLabel, platform }) => {
  const snackbar = useSnackbar();
  const [listQuery, setListQuery] = useState("");
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const { data: listsOfCreator = { users_in_lists: [] }, loading: loadingListsOfCreator } =
    useListsOfCreatorQuery({
      variables: { userId },
      skip: !open
    });

  const listNameLike = `%${listQuery.replace(/\s+/g, "%")}%`;
  const {
    data: { lists = [] } = {},
    loading: loadingSearch
  } = useSearchListsQuery({
    variables: { where: { name: { _ilike: listNameLike }, platform: { _eq: platform } } }
  });

  const [addUserToList, { loading: loadingAdd }] = useAddUserToListMutation({
    onCompleted: () => snackbar.success("User was added to the list"),
    onError: error => snackbar.error(error.message),
    refetchQueries: [ListsOfCreatorDocument]
  });

  const onAddUser = (listId: number) => {
    addUserToList({ variables: { list_id: listId, user_id: userId } });
  };

  return (
    <Root ref={rootRef}>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={faListCheck} />
      </Button>
      <Popover
        open={open}
        anchorEl={rootRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <Card>
          <h4>Manage lists for @{userLabel}</h4>
          <TextInput
            placeholder="Search list by name"
            value={listQuery}
            size="small"
            onChange={value => setListQuery(value)}
            style={{ width: "100%", marginBottom: 20 }}
          />

          <div className="scroll-container">
            <h5>Included on these lists</h5>
            {loadingListsOfCreator && <CircularProgress />}
            {listsOfCreator.users_in_lists.map(user => (
              <ListEditorItem
                active
                key={user.user_id}
                name={user.list?.name ?? ""}
                count={user.list?.users_in_lists_aggregate.aggregate?.count ?? 0}
              />
            ))}

            <h5>Results</h5>
            {(loadingSearch || loadingAdd) && <CircularProgress />}
            {!loadingAdd &&
              lists.map(list => (
                <ListEditorItem
                  active={listsOfCreator.users_in_lists.some(u => u.list?.id === list.id)}
                  key={list.id}
                  name={list.name ?? ""}
                  count={list.users_in_lists_aggregate.aggregate?.count ?? 0}
                  onClick={() => onAddUser(list.id)}
                />
              ))}
          </div>
        </Card>
      </Popover>
    </Root>
  );
};
