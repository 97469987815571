import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OverviewCampaignQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type OverviewCampaignQuery = { __typename?: 'Query', campaign?: { __typename?: 'campaign', id: number, max_budget?: number | null, target_participants?: number | null, number_of_posts?: number | null, platform?: Types.Platform_Enum | null, stub: string, target_reach?: number | null, title: string, market_ids: string | number[], vertical_ids: string | number[], approved_campaign_posts_aggregate: { __typename?: 'campaign_posts_aggregate', aggregate?: { __typename?: 'campaign_posts_aggregate_fields', count: number } | null }, approved_campaign_stories_aggregate: { __typename?: 'campaign_story_aggregate', aggregate?: { __typename?: 'campaign_story_aggregate_fields', count: number } | null }, campaign_participants: Array<{ __typename?: 'campaign_user', global_pay?: number | null, campaign_videos: Array<{ __typename?: 'campaign_videos', id: number }>, user?: { __typename?: 'users', id: number, user_instagram?: { __typename?: 'users_instagram', id: number, followed_by?: number | null, username: string } | null, user_youtube?: { __typename?: 'users_youtube', id: number, subscribers?: string | null, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, followed_by?: number | null, username?: string | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }> } | null };


export const OverviewCampaignDocument = gql`
    query overviewCampaign($campaignId: Int!) {
  campaign: campaign_by_pk(id: $campaignId) {
    id
    max_budget
    target_participants
    number_of_posts
    platform
    stub
    target_reach
    title
    market_ids
    vertical_ids
    approved_campaign_posts_aggregate: campaign_posts_aggregate(
      where: {approved: {_eq: true}}
    ) {
      aggregate {
        count
      }
    }
    approved_campaign_stories_aggregate: campaign_stories_aggregate(
      where: {approved: {_eq: true}}
    ) {
      aggregate {
        count
      }
    }
    campaign_participants: campaign_users(
      where: {state: {_in: ["approved", "confirmed"]}}
    ) {
      global_pay
      campaign_videos {
        id
      }
      user {
        id
        ...UserInfo
        user_instagram {
          id
          followed_by
        }
        user_youtube {
          id
          subscribers
        }
        user_tiktok {
          id
          followed_by
        }
        user_snapchat {
          id
        }
      }
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useOverviewCampaignQuery__
 *
 * To run a query within a React component, call `useOverviewCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useOverviewCampaignQuery(baseOptions: Apollo.QueryHookOptions<OverviewCampaignQuery, OverviewCampaignQueryVariables> & ({ variables: OverviewCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OverviewCampaignQuery, OverviewCampaignQueryVariables>(OverviewCampaignDocument, options);
      }
export function useOverviewCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewCampaignQuery, OverviewCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OverviewCampaignQuery, OverviewCampaignQueryVariables>(OverviewCampaignDocument, options);
        }
export function useOverviewCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OverviewCampaignQuery, OverviewCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OverviewCampaignQuery, OverviewCampaignQueryVariables>(OverviewCampaignDocument, options);
        }
export type OverviewCampaignQueryHookResult = ReturnType<typeof useOverviewCampaignQuery>;
export type OverviewCampaignLazyQueryHookResult = ReturnType<typeof useOverviewCampaignLazyQuery>;
export type OverviewCampaignSuspenseQueryHookResult = ReturnType<typeof useOverviewCampaignSuspenseQuery>;
export type OverviewCampaignQueryResult = Apollo.QueryResult<OverviewCampaignQuery, OverviewCampaignQueryVariables>;