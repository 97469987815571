import { type FC, useState } from "react";

import { faAdd, faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useNavigate, useParams } from "react-router-dom";

import { useCampaignsQuery } from "../generated";
import { ContentSearch } from "./ContentSearch";
import { MessageListDocument, useInsertCampaignContentMutation } from "./generated";

export const AddExistingMessage: FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const [contentId, setContentId] = useState<number | null>(null);

  const {
    data: {
      campaigns: [campaign] = []
    } = {},
    loading: loadingCampaigns
  } = useCampaignsQuery({
    variables: { where: { stub: { _eq: campaignStub } } }
  });

  const [insertCampaignContent, { loading: loadingInsert }] = useInsertCampaignContentMutation({
    onError: error => {
      snackbar.error(`Cannot add message, reason: ${error.message}`);
    }
  });

  const backUrl = `/campaign/${campaignStub}/messages`;

  const handleAdd = async () => {
    if (!contentId) return;

    await insertCampaignContent({
      variables: {
        object: {
          campaign_id: campaign.id,
          content_id: contentId,
          content_type: "custom"
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [MessageListDocument]
    });

    navigate(backUrl);
  };

  if (loadingCampaigns || !campaign) return null;

  return (
    <div>
      <h2>Add existing message to campaign</h2>
      <p>Select existing message to add to campaign.</p>
      <br />
      <ContentSearch contentId={contentId} onChange={setContentId} />
      <br />
      <br />
      <Button
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        disabled={loadingInsert}
        href={backUrl}
        style={{ marginRight: 10 }}
      >
        Cancel
      </Button>
      <Button icon={<FontAwesomeIcon icon={faAdd} />} disabled={loadingInsert} onClick={handleAdd}>
        Add
      </Button>
    </div>
  );
};
