import type { FC } from "react";

import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import type { Campaign_User_State_Enum, Gigapay_Status_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { Menu, type MenuOption } from "components/ui/Menu";

import {
  CampaignParticipantsDocument,
  useGigaPayCancelPaymentMutation,
  useGigaPayRegisterPaymentMutation
} from "../generated";

const statuses: Record<Gigapay_Status_Enum, { title: string; desc: string }> = {
  PENDING_KYC: {
    desc: "The creator needs to pass verification at GigaPay. In case of an outdated email sent to the creator you need to retry an email invitation at GigaPay dashboard",
    title: "Onboarding…"
  },
  PASSED_KYC: {
    title: "Pay",
    desc: "The creator has successfully passed preliminary verification at GigaPay. You can request a payment by clicking Pay"
  },
  PAYMENT_REQUESTED: {
    title: "Paying…",
    desc: "A payment has been issued however needs to be approved by Nini first. It shouldn't happen in more than a week. Until it gets approved you can still cancel the payment."
  },
  HALF_PAYMENT_REQUESTED: {
    title: "Half paid",
    desc: "A partial payment has been requested. You still can request the second half of the payment"
  },
  MONEY_SENT: {
    title: "Paid",
    desc: "Even though money has been sent it does not mean it arrived. It may take up to a few days depending on the bank of the receiver."
  }
};

export const GigaPayStatusCell: FC<{
  gigaPayStatus: Gigapay_Status_Enum;
  campaignStub: string;
  gigaPayUserId: string;
  gigaPayPaymentId: string | null | undefined;
  campaignUserState: Campaign_User_State_Enum;
  paymentValue: number;
}> = ({
  gigaPayStatus,
  campaignStub,
  gigaPayUserId,
  campaignUserState,
  paymentValue,
  gigaPayPaymentId
}) => {
  const snackbar = useSnackbar();
  const [pay, { loading }] = useGigaPayRegisterPaymentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsDocument],
    onCompleted: () => {
      snackbar.success(
        "Payment successfully requested. Remember it will take a few days until Finances accept the payment."
      );
    },
    onError: err => {
      snackbar.error("Sth went wrong…");
      snackbar.error(err.message);
    }
  });
  const [cancel, { loading: canceling }] = useGigaPayCancelPaymentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsDocument],
    onCompleted: () => {
      snackbar.success("Payment successfully canceled.");
    },
    onError: err => {
      snackbar.error("Sth went wrong…");
      snackbar.error(err.message);
    }
  });

  if (gigaPayStatus === "PASSED_KYC" || gigaPayStatus === "HALF_PAYMENT_REQUESTED") {
    if (!paymentValue) return <span>Missing payment</span>;

    const options: MenuOption[] = [
      {
        label: gigaPayStatus === "HALF_PAYMENT_REQUESTED" ? "Pay other 50%" : "Pay 50%",
        onClick: () => {
          pay({ variables: { campaignStub, gigaPayUserId, paymentType: "HALF" } });
          snackbar.info("Paying…, don't leave the page");
        }
      }
    ];

    if (gigaPayStatus === "PASSED_KYC") {
      options.unshift({
        label: "Pay 100%",
        onClick: () => {
          pay({ variables: { campaignStub, gigaPayUserId, paymentType: "FULL" } });
          snackbar.info("Paying…, don't leave the page");
        }
      });
    }

    if (gigaPayStatus === "HALF_PAYMENT_REQUESTED") {
      options.push({
        label: "Cancel payment",
        onClick: () => {
          if (!gigaPayPaymentId) {
            snackbar.error("No Payment id - refresh the page");
            return;
          }
          cancel({ variables: { paymentId: gigaPayPaymentId } });
          snackbar.info("Canceling…, don't leave the page");
        }
      });
    }
    if (!["approved", "confirmed"].includes(campaignUserState.toLowerCase())) {
      return <span>First either approve or confirm user</span>;
    }

    return (
      <Menu
        renderTriggerComponent={({ onClick }) => (
          <Button variant="outlined" isLoading={loading || canceling} onClick={onClick}>
            <span
              style={{
                textAlign: "left",
                ...(gigaPayStatus === "HALF_PAYMENT_REQUESTED"
                  ? { color: palette.secondary.gold }
                  : {})
              }}
            >
              {gigaPayStatus === "HALF_PAYMENT_REQUESTED" ? "Pay other 50%" : "Pay"}
            </span>
            <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faCaretDown} />
          </Button>
        )}
        options={options}
      />
    );
  }

  if (gigaPayStatus === "PAYMENT_REQUESTED") {
    return (
      <Menu
        renderTriggerComponent={({ onClick }) => (
          <Button
            variant="outlined"
            style={{ textAlign: "left" }}
            isLoading={canceling}
            onClick={onClick}
          >
            <span>Payment approved</span>
            <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faCaretDown} />
          </Button>
        )}
        options={[
          {
            label: "Cancel payment",
            onClick: () => {
              if (!gigaPayPaymentId) {
                snackbar.error("No Payment id - refresh the page");
                return;
              }
              cancel({ variables: { paymentId: gigaPayPaymentId } });
              snackbar.info("Canceling…, don't leave the page");
            }
          }
        ]}
      />
    );
  }

  return (
    <Tooltip title={statuses[gigaPayStatus].desc}>
      <div>
        <span>{statuses[gigaPayStatus].title} ℹ️</span>
      </div>
    </Tooltip>
  );
};
