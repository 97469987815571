import { type FC, useState } from "react";

import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";

import { Popup } from "components/ui/Popup";

import { CampaignVideoStatsDocument, useDeleteCampaignVideoMutation } from "./generated";

export const DeleteVideoButton: FC<{ id: number; title: string }> = ({ id, title }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [deleteVideo] = useDeleteCampaignVideoMutation({
    variables: { id },
    onCompleted: () => setShowPopup(false),
    refetchQueries: [CampaignVideoStatsDocument],
    awaitRefetchQueries: true
  });

  return (
    <>
      <Button
        variant="text"
        onClick={() => setShowPopup(true)}
        icon={<FontAwesomeIcon color={palette.primary.red} icon={faTrash} />}
      >
        Delete
      </Button>
      {showPopup && (
        <Popup
          actionLabel="Delete"
          onAction={() => deleteVideo()}
          title={`Delete the "${title}" video`}
          onClose={() => setShowPopup(false)}
        />
      )}
    </>
  );
};
