import { type FC, useState } from "react";

import {
  faArrowsRotate,
  faBackwardFast,
  faCheck,
  faCircleInfo,
  faCirclePlay,
  faDownload,
  faKey,
  faNote,
  faSparkles,
  faTrash,
  faWebhook
} from "@fortawesome/pro-regular-svg-icons";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";

import { Menu, type MenuOption } from "components/ui/Menu";
import { Popup } from "components/ui/Popup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";
import { useUpdateUserMutation } from "modules/generated";

import {
  CampaignParticipantsDocument,
  CampaignParticipantsStatsDocument,
  useDeleteAgreementContractMutation,
  useDeleteCampaignUserMutation,
  useResetCampaignUserMutation
} from "../generated";
import { useRefreshScriveAgreementMutation, useUpdateScriveAgreementMutation } from "./generated";

export const ActionsCell: FC<{
  row: {
    id: number;
    email?: string | null;
    notes?: string | null;
    userLabel: string;
    platform: Platform_Enum;
    campaignUserId?: number | null;
    agreementId: string | null | undefined;
    encodedOnboardingUrl?: string;
    encodedAuthenticationUrl?: string;
    encodedContentApprovalUrl?: string;
    agreementPdf?: string | null;
    idEncoded?: string | null;
    campaignStub: string;
    skipAPIVerification?: boolean;
  };
}> = ({ row }) => {
  const snackbar = useSnackbar();

  const [isNotePopup, setIsNotePopup] = useState(false);
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isResetStatePopup, setIsResetStatePopup] = useState(false);
  const [deleteAgreementConfirmationPopup, setDeleteAgreementConfirmationPopup] = useState(false);
  const [customScriveAgreementPopup, setCustomScriveAgreementPopup] = useState(false);
  const [customScriveAgreementLink, setCustomScriveAgreementLink] = useState("");

  const [note, setNote] = useState(row.notes);

  const [updateUser] = useUpdateUserMutation({
    onCompleted: () => {
      setIsNotePopup(false);
      snackbar.success("User has been successfully updated");
    },
    onError: () => {
      snackbar.error("Something went wrong");
    }
  });
  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    onCompleted: () => {
      snackbar.success("User has been successfully updated");
    },
    onError: () => {
      snackbar.error("Something went wrong");
    },
    refetchQueries: [CampaignParticipantsDocument],
    awaitRefetchQueries: true
  });
  const [deleteCampaignUser] = useDeleteCampaignUserMutation({
    onCompleted: () => {
      setIsDeletePopup(false);
      snackbar.success("The user has been deleted");
    },
    onError: () => {
      snackbar.error(
        "The deletion has failed, make sure to delete all the content of the user (posts, stories, tiktoks etc) and try again"
      );
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      CampaignParticipantsStatsDocument,
      CampaignParticipantsStatsDocument,
      CampaignParticipantsDocument
    ],
    variables: { campaignUserId: row.campaignUserId ?? 0 }
  });
  const [resetCampaignUser] = useResetCampaignUserMutation({
    onCompleted: () => {
      setIsResetStatePopup(false);
      snackbar.success("The user has been reset");
    },
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsStatsDocument, CampaignParticipantsStatsDocument]
  });

  const [deleteAgreementContract] = useDeleteAgreementContractMutation({
    onCompleted: () => {
      setDeleteAgreementConfirmationPopup(false);
      snackbar.success("The agreement was successfully deleted");
    },
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsStatsDocument, CampaignParticipantsDocument],
    variables: { campaignUserId: row.campaignUserId ?? 0 }
  });

  const [updateScriveAgreement] = useUpdateScriveAgreementMutation({
    onError(err) {
      snackbar.error(err.message);
    },
    onCompleted() {
      snackbar.success("The agreement was successfully connected!");
    },
    awaitRefetchQueries: true,
    refetchQueries: [CampaignParticipantsDocument]
  });

  const [refreshScriveAgreement] = useRefreshScriveAgreementMutation({
    onError(err) {
      snackbar.error(err.message);
    },
    onCompleted() {
      snackbar.success("The agreement was successfully updated!");
    },
    variables: { campaignUserId: row.campaignUserId ?? 0 }
  });

  return (
    <>
      <Menu
        options={[
          { label: "Details", icon: faCircleInfo, href: `/user/${row.id}` },
          { label: "Add a note", icon: faNote, onClick: () => setIsNotePopup(true) },

          ...([
            row.agreementPdf && {
              label: "Download agreement",
              target: "_blank",
              icon: faDownload,
              href: row.agreementPdf
            }
          ].filter(Boolean) as MenuOption[]),
          ...(row.platform === "instagram" || row.platform === "tiktok"
            ? [
                {
                  label: row.skipAPIVerification
                    ? "Enforce API verification"
                    : "Skip API verification",
                  icon: faWebhook,
                  onClick: () => {
                    if (!row.campaignUserId) throw new Error("Missing campaignUserId");
                    return updateCampaignUser({
                      variables: {
                        campaignUserId: row.campaignUserId,
                        _set: { skip_api_verification: !row.skipAPIVerification }
                      }
                    });
                  }
                }
              ]
            : []),
          {
            label: "Onboarding link",
            target: "_blank",
            icon: faCirclePlay,
            href: row.encodedOnboardingUrl
          },
          {
            label: "Authentication link",
            target: "_blank",
            icon: faKey,
            href: row.encodedAuthenticationUrl
          },
          {
            label: "Content approval link",
            target: "_blank",
            icon: faCheck,
            href: row.encodedContentApprovalUrl
          },
          {
            label: "Refresh Scrive agreement",
            icon: faArrowsRotate,
            onClick: () => {
              snackbar.info("Loading…");
              if (!row.campaignUserId) {
                throw new Error("Missing campaignUserId");
                return;
              }
              refreshScriveAgreement({ variables: { campaignUserId: row.campaignUserId } });
            }
          },
          {
            divider: true,
            label: "Custom Scrive agreement",
            icon: faSparkles,
            onClick: () => {
              if (row.agreementId) {
                snackbar.error("First delete the current agreement");
                return;
              }
              setCustomScriveAgreementPopup(true);
            }
          },
          ...([
            row.agreementId && {
              label: "Delete agreement",
              variant: "danger",
              icon: faTrash,
              onClick: () => setDeleteAgreementConfirmationPopup(true)
            }
          ].filter(Boolean) as MenuOption[]),
          {
            label: "Reset user state",
            variant: "danger",
            icon: faBackwardFast,
            onClick: () => setIsResetStatePopup(true)
          },
          {
            label: "Remove user",
            variant: "danger",
            icon: faTrash,
            onClick: () => setIsDeletePopup(true)
          }
        ]}
      />

      {deleteAgreementConfirmationPopup && (
        <Popup
          onClose={() => setDeleteAgreementConfirmationPopup(false)}
          title={`Delete agreement of ${row.userLabel}`}
          actionLabel="Delete"
        >
          <Note label="Consequences">
            The removal will disconnect the agreement from our system however the old direct link to
            the agreement will continue to work. No signed agreement can be deleted.
          </Note>
          <Button
            color="error"
            style={{ marginTop: "auto" }}
            onClick={() => deleteAgreementContract()}
          >
            Yes, delete
          </Button>
        </Popup>
      )}
      {isNotePopup && (
        <Popup
          title="Notes"
          actionLabel="Save"
          onClose={() => setIsNotePopup(false)}
          onAction={() => updateUser({ variables: { id: row.id, set: { notes: note } } })}
        >
          <TextInput value={note ?? ""} onChange={setNote} multiline label="note" />
        </Popup>
      )}
      {isDeletePopup && (
        <Popup
          title={`Delete campaign user: ${row.userLabel}`}
          actionLabel="Remove"
          onClose={() => setIsDeletePopup(false)}
          onAction={() => deleteCampaignUser()}
        />
      )}
      {isResetStatePopup && row.campaignUserId && (
        <Popup
          title={`Reset campaign user: ${row.userLabel}`}
          actionLabel="Reset"
          onClose={() => setIsResetStatePopup(false)}
          onAction={() => resetCampaignUser({ variables: { id: row.campaignUserId ?? 0 } })}
        />
      )}
      {customScriveAgreementPopup && (
        <Popup
          actionLabel="Save"
          onAction={async () => {
            if (!row.campaignUserId) {
              throw new Error("Missing campaignUserId");
            }
            const match = customScriveAgreementLink.match(
              /^https:\/\/scrive\.com\/new\/(?:sign|document)\/([0-9]+)/
            );
            if (!match || !match[1]) {
              snackbar.error(
                "Invalid Scrive URL. It needs to point to the Scrive document like https://scrive.com/new/document/9222115557500095875"
              );
              return;
            }

            const scriveAgreementId = match[1];

            await updateScriveAgreement({
              variables: { campaignUserId: row.campaignUserId, scriveAgreementId }
            });
            setCustomScriveAgreementPopup(false);
          }}
          onClose={() => setCustomScriveAgreementPopup(false)}
          title={`Custom scrive agreement for: ${row.userLabel}`}
        >
          <Note label="Use it only when you cannot use the default Scrive template" variant="info">
            Paste url of an agreement which follows the given pattern:
            <br />
            <i>https://scrive.com/new/sign/9222115557493303780/9221402946349893856</i>
            <br />
            The agreement can be signed or unsigned.
          </Note>
          <TextInput
            value={customScriveAgreementLink}
            onChange={setCustomScriveAgreementLink}
            label="Scrive url"
          />
        </Popup>
      )}
    </>
  );
};
