import styled from "@emotion/styled";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "@relatable/ui/Palette";

const Root = styled.div<{ $active: boolean }>`
  display: grid;
  grid-template-columns: 10% auto 15%;
  gap: 8px;
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 8px;
  background: ${({ $active }) => ($active ? palette.tertiary.blue : palette.gray[10])};

  > span {
    margin-top: auto;
    margin-bottom: auto;
  }

  > .icon {
    font-size: 20px;
    cursor: ${({ $active }) => ($active ? "auto" : "pointer")};
  }

  > .name {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  > .count {
    text-align: right;
    font-weight: normal;
    color: ${palette.gray[60]};
  }
`;

export const ListEditorItem: React.FC<{
  active: boolean;
  name: string;
  count: number;
  onClick?: () => void;
}> = ({ active, name, count, onClick }) => (
  <Root $active={active}>
    <span className="icon">
      <FontAwesomeIcon
        icon={active ? faCircleCheck : faCirclePlus}
        color={active ? palette.primary.green : palette.gray[60]}
        onClick={!active ? onClick : undefined}
      />
    </span>
    <span className="name" title={name}>
      {name}
    </span>
    <span className="count">{count}</span>
  </Root>
);
