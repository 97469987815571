import { type FC, type ReactElement, useRef } from "react";

import { Button } from "@relatable/ui/Button";

const FILES_MIME_TYPES = ["application/pdf", "text/plain"];
const IMAGE_MIME_TYPES = ["image/bmp", "image/gif", "image/jpeg", "image/png", "image/webp"];
const VIDEO_MIME_TYPES = [".m4v", ".mp4", ".webm", ".x-m4v", "video/x-m4v", "video/*"];

type FileType = "videos" | "images" | "files";

export const UploadInput: FC<{
  onChange(files: File[]): void;
  accept?: FileType[];
  customIcon?: JSX.Element;
  label?: string;
  multiple?: boolean;
  disabled?: boolean;
  icon?: ReactElement;
  style?: React.CSSProperties;
  variant?: "text" | "outlined" | "contained";
}> = ({
  onChange,
  accept,
  customIcon,
  label,
  multiple,
  disabled,
  style,
  variant = "contained",
  icon
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const parseAccept = () => {
    const acceptList: string[][] = [];
    if (accept?.includes("files")) acceptList.push(FILES_MIME_TYPES);
    if (accept?.includes("videos")) acceptList.push(VIDEO_MIME_TYPES);
    if (accept?.includes("images")) acceptList.push(IMAGE_MIME_TYPES);
    return acceptList.join();
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => inputRef.current?.click()}
        variant={customIcon ? "text" : variant}
        icon={customIcon ? undefined : icon}
        color="secondary"
        style={customIcon ? { cursor: "pointer", minWidth: "auto", ...style } : style}
      >
        {customIcon ?? label ?? "Choose files"}
      </Button>

      <input
        disabled={disabled}
        multiple={multiple}
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        accept={parseAccept()}
        onChange={e => {
          if (!e.target.files) return;
          onChange(Array.from(e.target.files));
        }}
      />
    </>
  );
};
