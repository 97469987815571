import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { useNavigate } from "react-router-dom";

import {
  AccountDetailsDocument,
  type AccountDetailsQuery,
  AccountsDocument,
  useUpsertAccountMutation
} from "./generated";

export const AccountEditorFields: FC<{
  details: AccountDetailsQuery["accounts_by_pk"];
}> = ({ details }) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [fields, setFields] = useState({
    email: details?.email ?? "",
    intercom_email: details?.intercom_email ?? "",
    first_name: details?.first_name ?? "",
    last_name: details?.last_name ?? "",
    is_active: details?.is_active ?? true,
    instagram: details?.instagram ?? "",
    is_campaign_owner: details?.is_campaign_owner ?? false,
    linkedin: details?.linkedin ?? "",
    office: details?.office ?? "",
    phone: details?.phone ?? "",
    profile_picture: details?.profile_picture ?? "",
    slack_id: details?.slack_id ?? "",
    slack_username: details?.slack_username ?? "",
    title: details?.title ?? ""
  });

  const getError = (values: Partial<AccountDetailsQuery["accounts_by_pk"]>) => {
    if (!values) return "Invalid parameter";

    const mandatoryFields: (keyof typeof fields)[] = [
      "email",
      "first_name",
      "last_name",
      "office",
      "title"
    ];

    for (const field of mandatoryFields) {
      if (field in values && !values[field]) return `${field} is mandatory`;
    }

    if (values.email && !values.email.endsWith("@relatable.me")) {
      return "Invalid email. It must be a @relatable.me email";
    }

    if (values.intercom_email && !values.intercom_email.endsWith("@relatable.me")) {
      return "Invalid intercom email. It must be an email address ending with .a@bambuser.com or @relatable.me";
    }

    if (values.profile_picture && !values.profile_picture.includes("slack-edge.com")) {
      return "Invalid profile picture URL. It should use the URL provided by Slack by using the Copy Image URL button.";
    }

    if (values.slack_id && !values.slack_id.match(/^(U|W)[0-9A-Z]{1,20}$/)) {
      return "Invalid Slack ID.";
    }

    return "";
  };

  const handleFieldChange = (values: Partial<typeof fields>) => {
    if (!values) return;

    setFields(prev => ({
      ...prev,
      ...values
    }));
  };

  const [upsertAccount, { loading }] = useUpsertAccountMutation({
    onError(err) {
      snackbar.error(err.message);
    }
  });

  const handleUpsert = async () => {
    const trimmedFields = Object.fromEntries(
      Object.entries(fields).map(([k, v]) => (typeof v === "string" ? [k, v.trim()] : [k, v]))
    );

    const error = getError(trimmedFields);
    if (error) {
      snackbar.error(error);
      return;
    }

    await upsertAccount({
      variables: {
        set: {
          id: details?.id ?? undefined,
          ...trimmedFields,
          profile_picture: trimmedFields.profile_picture || null,
          intercom_email: trimmedFields.intercom_email || null
        }
      },
      refetchQueries: [AccountsDocument, AccountDetailsDocument],
      awaitRefetchQueries: true,
      onCompleted: () => {
        snackbar.success("Account was updated");
        navigate("/time-reporting/accounts");
      }
    });
  };

  return (
    <>
      <h2 style={{ marginBottom: 20 }}>
        {details ? (
          <span>
            Editing {details.first_name} {details.last_name}
          </span>
        ) : (
          <span>Create new account</span>
        )}
      </h2>

      <Container>
        <TextInput
          required
          type="email"
          label="Email"
          value={fields.email ?? ""}
          onChange={v => handleFieldChange({ email: v })}
        />

        <TextInput
          type="email"
          label="Intercom email in the john.doe.a@relatable.me format. It can be empty when there is no alias"
          value={fields.intercom_email ?? ""}
          onChange={v => handleFieldChange({ intercom_email: v })}
        />

        <TextInput
          required
          label="First name"
          value={fields.first_name ?? ""}
          onChange={v => handleFieldChange({ first_name: v })}
        />

        <TextInput
          required
          label="Last name"
          value={fields.last_name ?? ""}
          onChange={v => handleFieldChange({ last_name: v })}
        />

        <TextInput
          label="Instagram URL"
          value={fields.instagram ?? ""}
          onChange={v => handleFieldChange({ instagram: v })}
        />

        <TextInput
          label="LinkedIn URL"
          value={fields.linkedin ?? ""}
          onChange={v => handleFieldChange({ linkedin: v })}
        />

        <TextInput
          required
          label="Office"
          value={fields.office ?? ""}
          onChange={v => handleFieldChange({ office: v })}
        />

        <TextInput
          required
          label="Title"
          value={fields.title ?? ""}
          onChange={v => handleFieldChange({ title: v })}
        />

        <TextInput
          label="Phone number"
          value={fields.phone ?? ""}
          onChange={v => handleFieldChange({ phone: v })}
        />

        <TextInput
          label="Profile picture (from Slack)"
          value={fields.profile_picture ?? ""}
          onChange={v => handleFieldChange({ profile_picture: v })}
        />

        <TextInput
          label="Slack ID (e.g. U023HU6R3PF)"
          value={fields.slack_id ?? ""}
          onChange={v => handleFieldChange({ slack_id: v })}
        />

        <TextInput
          label="Slack Username (e.g. John Doe)"
          value={fields.slack_username ?? ""}
          onChange={v => handleFieldChange({ slack_username: v })}
        />

        <Checkbox
          label="Is active"
          checked={fields.is_active ?? false}
          onChange={v => handleFieldChange({ is_active: v })}
        />

        <Checkbox
          label="Is campaign owner"
          checked={fields.is_campaign_owner ?? false}
          onChange={v => handleFieldChange({ is_campaign_owner: v })}
        />
      </Container>

      <Button
        icon={<FontAwesomeIcon icon={faSave} />}
        size="medium"
        style={{ marginLeft: "auto", marginTop: 10 }}
        isLoading={loading}
        onClick={handleUpsert}
      >
        Save
      </Button>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
