import type { FC } from "react";

import styled from "@emotion/styled";
import { faChartSimple, faShareNodes, faSparkles, faStar } from "@fortawesome/pro-solid-svg-icons";
import { prettifyPercentages } from "@relatable/helpers";

import { Section } from "../user.styled";
import { StatsSectionItem } from "./StatsSectionItem";

const StyledSection = styled(Section)`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
`;

export const StatsSection: FC<{
  collaborationScore: number | null;
  contentQualityScore: number | null;
  engagementRate: number | null;
  vertical: string | null;
}> = ({ collaborationScore, contentQualityScore, engagementRate, vertical }) => (
  <StyledSection>
    {[
      {
        title: "Collaboration Score",
        value: collaborationScore ? collaborationScore.toFixed(2) : "-",
        icon: faChartSimple
      },
      {
        title: "Content Quality Score",
        value: contentQualityScore ? contentQualityScore.toFixed(2) : "-",
        icon: faSparkles
      },
      {
        title: "Engagement rate (HA)",
        value: engagementRate ? prettifyPercentages({ total: 1, of: engagementRate }) : "",
        icon: faShareNodes
      },
      {
        title: "Vertical",
        value: vertical || "-",
        icon: faStar
      }
    ].map(stat => (
      <StatsSectionItem key={stat.title} title={stat.title} value={stat.value} icon={stat.icon} />
    ))}
  </StyledSection>
);
