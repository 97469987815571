import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faSend } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "@mui/material";
import { sleep } from "@relatable/helpers";
import { Checkbox } from "@relatable/ui/Checkbox";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useParams } from "react-router-dom";

import { ErrorPage } from "components/Error";
import { Popup } from "components/ui/Popup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";
import { useSendEmailToCampaignUserMutation } from "modules/generated";
import { useSlack } from "src/hooks/useSlack";

import {
  CampaignParticipantsDocument,
  type CampaignParticipantsQuery,
  useCampaignParticipantsQuery
} from "../generated";
import { filterCampaignParticipantsData } from "../helpers";
import { SendEmailUsers } from "./SendEmailUsers";

export const SendEmailsPopup: FC<{
  onClose(): void;
  participants: CampaignParticipantsQuery["users_in_lists"];
}> = ({ onClose, participants }) => {
  const snackbar = useSnackbar();
  const { campaignStub } = useParams<{ campaignStub: string }>();

  const [contentToSendId, setContentToSendId] = useState<number | null>(null);

  const [notifyOnSlack, setNotifyOnSlack] = useState(false);
  const [updateUsersState, setUpdateUsersState] = useState(false);

  const { sendSlackNotification } = useSlack();
  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    refetchQueries: [CampaignParticipantsDocument],
    onError: ({ message }) => {
      snackbar.error(message);
    }
  });
  const [sendEmail] = useSendEmailToCampaignUserMutation({
    onCompleted: response => {
      const error = response.sendCreatorEmail?.error;
      if (error) throw new Error(error);
    },
    onError: err => {
      console.error(err);
      snackbar.error(err.message);
    }
  });

  const usersWithCampaignUser = participants.filter(({ campaign_user }) => campaign_user);

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const { data, loading } = useCampaignParticipantsQuery({
    variables: { campaignStubs: [campaignStub ?? ""] }
  });

  const filteredData = filterCampaignParticipantsData(data, listUser =>
    selectedUserIds.includes(listUser.user_id ?? 0)
  );

  const campaign = filteredData ? filteredData.campaigns[0] : null;

  if (!campaign && !loading) return <ErrorPage message={`${campaignStub} campaign is missing`} />;

  const handleSendEmails = async () => {
    const campaignContent = campaign?.campaign_contents.find(({ id }) => id === contentToSendId);
    const contentName = campaignContent?.content?.name;
    if (!contentName) throw new Error("no contentName for email template");
    if (!filteredData?.users_in_lists.length) {
      snackbar.error("no users selected");
      return;
    }

    // send emails sequentially to avoid rate limits at Mailchimp
    for (const user of filteredData.users_in_lists) {
      const id = user.campaign_user?.id;
      if (!id) throw new Error("missing id");
      const state = user.campaign_user?.state;
      await Promise.all([
        updateUsersState &&
          (state === "client_approved" || !state) &&
          updateCampaignUser({ variables: { campaignUserId: id, _set: { state: "lead" } } }),
        sendEmail({
          variables: {
            campaignUserId: id,
            template: contentName
          }
        })
      ]);
      await sleep(0.25);
    }

    if (!campaign) throw Error("campaign not found");
    if (notifyOnSlack) {
      await sendSlackNotification({
        variables: {
          campaignId: campaign.id,
          channel: "general-plus",
          text: `:boom: \`${campaign.account?.first_name}\` just sent out ${filteredData?.users_in_lists.length} invites to the \`${campaignStub}\` campaign. \n<https://giphy.com/gifs/nyan-cat-sIIhZliB2McAo|funny neon cat>`
        }
      });
    }
    snackbar.success("Emails sent");
    onClose();
  };

  return (
    <Popup
      title={`Send an email to ${
        loading ? "…" : filteredData?.users_in_lists.length
      } selected users`}
      onClose={onClose}
      onAction={handleSendEmails}
      actionLabel="Send"
      disabledAction={!contentToSendId}
      actionIcon={<FontAwesomeIcon icon={faSend} />}
    >
      <AccordionsBox>
        <SendEmailUsers
          users={usersWithCampaignUser}
          onChange={setSelectedUserIds}
          selectedUserIds={selectedUserIds}
        />

        <Select
          label="Content to send"
          required
          value={contentToSendId}
          options={
            campaign?.campaign_contents.flatMap(campaignContent => ({
              label: campaignContent.content?.name || "",
              value: campaignContent.id
            })) || []
          }
          onChange={id => {
            setContentToSendId(id === null ? id : Number(id));
          }}
        />

        <div>
          <Checkbox
            label="Send neon cat slack notification on #general"
            checked={notifyOnSlack}
            onChange={() => setNotifyOnSlack(prev => !prev)}
          />
          <Checkbox
            label={'Update users\' state from "client approved" or "Not set" to "lead"'}
            checked={updateUsersState}
            onChange={() => setUpdateUsersState(prev => !prev)}
          />
        </div>
        {!campaign?.account?.id && (
          <Alert variant="filled" severity="warning">
            Warning! There is no campaign owner set for this campaign. In this case the emails are
            sent out from a no-reply email address.
          </Alert>
        )}
      </AccordionsBox>
    </Popup>
  );
};

const AccordionsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
