import { type FC, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "@mui/material";
import type { Campaign_Reels_Set_Input } from "@relatable/gql/generated-base";
import { Button } from "@relatable/ui/Button";
import { Checkbox } from "@relatable/ui/Checkbox";
import { LinearLoader } from "@relatable/ui/LinearLoader";
import { palette } from "@relatable/ui/Palette";
import { Select } from "@relatable/ui/Select";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { TextInput } from "@relatable/ui/TextInput";
import { TimeDurationField } from "@relatable/ui/TimeDurationField";
import { useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { NavigationButton } from "components/ui/NavigationButton";
import { Title } from "lib/styled";
import { useUpdateCampaignReelMutation } from "modules/campaign/Posts/generated";
import { ContentDisplay } from "modules/campaign/common/ContentDisplay";

import { FeedbackState } from "./FeedbackState";
import { ReelDocument, type ReelQuery, useReelQuery } from "./generated";

export const Reel: FC = () => {
  const { reelId, campaignStub } = useParams<{ campaignStub: string; reelId: string }>();
  if (!campaignStub) throw Error("invalid URL - missing campaignStub");

  const { data } = useReelQuery({ variables: { id: Number(reelId), campaignStub } });

  const reel = data?.campaign_reels_by_pk;
  if (!reel) return <Loader />;

  return (
    <>
      <NavigationButton
        style={{ width: "fit-content" }}
        pathname={`/campaign/${campaignStub}/posts`}
      />
      <ReelContent reel={reel} />
    </>
  );
};

const ReelContent: FC<{ reel: NonNullable<ReelQuery["campaign_reels_by_pk"]> }> = ({ reel }) => {
  const [isApprovedLocal, setIsApprovedLocal] = useState(Boolean(reel.approved));
  const snackbar = useSnackbar();

  const [url] = useState(reel.internal_url || reel.image_url || "");
  const [values, setValues] = useState<Campaign_Reels_Set_Input>({
    clips_replays_count: reel.clips_replays_count,
    comments: reel.comments,
    all_plays_count: reel.all_plays_count,
    avg_watch_time: reel.avg_watch_time,
    video_view_total_time: reel.video_view_total_time,
    likes: reel.likes,
    plays: reel.plays,
    actual_reach: reel.actual_reach,
    saved: reel.saved,
    shares: reel.shares
  });

  const [updateReel, { loading }] = useUpdateCampaignReelMutation({
    onError: error => snackbar.error(error.message),
    refetchQueries: [ReelDocument],
    awaitRefetchQueries: true
  });

  const handleApprove = async (approved: boolean) => {
    await updateReel({
      variables: {
        reelId: reel.id,
        set: {
          approved,
          approved_at: approved ? new Date().toISOString() : undefined
        }
      }
    });

    snackbar.success(`Reel ${approved ? "" : "un"}approved`);
  };

  const debouncedInputChange = useMemo(
    () =>
      debounce(async (set: Campaign_Reels_Set_Input) => {
        await updateReel({
          variables: {
            reelId: reel.id,
            set
          }
        });
        snackbar.success("Reel updated");
      }, 1000),
    [reel.id, updateReel, snackbar]
  );
  const handleInputChange = (inputValues: Partial<typeof values>) => {
    setValues(p => ({ ...p, ...inputValues }));
    debouncedInputChange(inputValues);
  };

  const isApproved = Boolean(reel.approved);

  return (
    <>
      <Title>Post - Reel by @{reel.user?.user_instagram?.username}</Title>
      <LinearLoader visible={loading} />

      <MediaContainer>
        <ContentDisplay url={url} thumbnail={url} status={isApprovedLocal ? "approved" : ""} />

        <InputsWrapper>
          <Checkbox
            onChange={() => setIsApprovedLocal(prev => !prev)}
            label={
              isApprovedLocal ? (
                <FontAwesomeIcon icon={faThumbsUp} color={palette.primary.green} />
              ) : (
                <FontAwesomeIcon icon={faThumbsDown} color={palette.secondary.gold} />
              )
            }
            checked={isApprovedLocal}
            disabled={isApproved}
          />
          {isApproved ? (
            <>
              <TextInput
                label="Followers"
                value={String(reel.user?.user_instagram?.followed_by || 0)}
                disabled
              />

              <NumberInput
                label="All views (plays)"
                value={values.all_plays_count}
                onChange={all_plays_count => handleInputChange({ all_plays_count })}
              />

              <NumberInput
                label="Views (initial plays)"
                value={values.plays}
                onChange={plays => handleInputChange({ plays })}
              />

              <NumberInput
                label="Replays"
                value={values.clips_replays_count}
                onChange={clips_replays_count => handleInputChange({ clips_replays_count })}
              />

              <NumberInput
                label="Actual reach"
                value={values.actual_reach}
                onChange={actual_reach => handleInputChange({ actual_reach })}
              />

              <NumberInput
                label="Avg. watch time"
                value={values.avg_watch_time}
                onChange={avg_watch_time => handleInputChange({ avg_watch_time })}
              />

              <TimeDurationField
                label="Total watch time"
                hours={values.video_view_total_time ? values.video_view_total_time / 60 / 60 : 0}
                onChange={v =>
                  handleInputChange({ video_view_total_time: v ? Math.round(v * 60 * 60) : 0 })
                }
                inputProps={{ style: { height: "auto" } }}
              />

              <NumberInput
                label="Likes"
                value={values.likes}
                onChange={likes => handleInputChange({ likes })}
              />

              <NumberInput
                label="Comments"
                value={values.comments}
                onChange={comments => handleInputChange({ comments })}
              />

              <NumberInput
                label="Shares"
                value={values.shares}
                onChange={shares => handleInputChange({ shares })}
              />

              <NumberInput
                label="Saves"
                value={values.saved}
                onChange={saved => handleInputChange({ saved })}
              />

              <Select
                label="Tracking url"
                onChange={track_url => {
                  updateReel({
                    variables: {
                      reelId: reel.id,
                      set: {
                        track_url
                      }
                    }
                  }).then(() => snackbar.success("Post updated"));
                }}
                value={reel.track_url}
                options={
                  reel.user?.campaign_users[0]?.tracking?.shortlinks?.map(link => ({
                    label: link,
                    value: link
                  })) || []
                }
              />

              {reel.impressions_legacy && (
                <>
                  <div style={{ margin: 30 }} />
                  <TextInput
                    label="Frequency legacy"
                    type="number"
                    value={String((reel.impressions_legacy || 0) / (reel.actual_reach || 0))}
                    disabled
                  />

                  <TextInput
                    label="Impressions legacy"
                    type="number"
                    value={String(reel.impressions_legacy || "")}
                    disabled
                  />
                </>
              )}
            </>
          ) : null}
        </InputsWrapper>
      </MediaContainer>

      <FeedbackState
        contentName="reel"
        approved_at={reel.approved_at}
        isApproved={isApproved}
        isApprovedLocal={isApprovedLocal}
      />
      <Button
        style={{ minWidth: 200, marginTop: 50, marginLeft: "auto" }}
        className="send-button"
        size="full"
        disabled={isApproved || !isApprovedLocal}
        onClick={() => handleApprove(isApprovedLocal)}
      >
        Approve
      </Button>
    </>
  );
};

const NumberInput: FC<{
  value: number | null | undefined;
  label: string;
  onChange(v: number | null): void;
}> = ({ label, value, onChange }) => (
  <TextInput
    type="number"
    label={label}
    value={String(value || "")}
    onChange={v => onChange(v ? Math.round(Number(v)) : null)}
  />
);

const MediaContainer = styled.div`
  display: flex;
  margin: 25px 0px;
  padding-right: 50px;
`;

const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
`;
