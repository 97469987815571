import type { FC } from "react";

import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { prettifyNumber } from "@relatable/helpers";
import { Button } from "@relatable/ui/Button";
import { useParams } from "react-router-dom";

import { ExpenseTable } from "./ExpenseTable";
import { useCampaignExpensesQuery } from "./generated";

export const ExpenseList: FC = () => {
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const { data, loading } = useCampaignExpensesQuery({
    variables: { campaignStub: campaignStub ?? "" },
    skip: !campaignStub
  });

  const sumAmount = (data?.campaign_expenses ?? []).reduce((acc, curr) => {
    const amount = curr.amount ?? 0;
    const fxRate = curr.exchange_rates?.rates?.SEK ?? 1;
    return acc + amount * fxRate;
  }, 0);

  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: 10 }}>Expenses</h2>

      <ExpenseTable loading={loading} expenses={data?.campaign_expenses ?? []} />

      <div style={{ opacity: loading ? 0 : 1, transition: "opacity 500ms ease" }}>
        <Button
          linkStyle={{ margin: "auto", display: "flex", justifyContent: "center", marginTop: 15 }}
          href={`/campaign/${campaignStub}/expenses/new`}
        >
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 10 }} />
          Add new
        </Button>
        <p style={{ textAlign: "center" }}>
          💰 Gross profit reduced by {prettifyNumber(Math.round(sumAmount))} SEK
        </p>
      </div>
    </>
  );
};
