import { type FC, type PropsWithChildren, type ReactElement, useState } from "react";

import styled from "@emotion/styled";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Dialog, type DialogProps, DialogTitle } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";

export const Popup: FC<
  PropsWithChildren<{
    title?: string | JSX.Element;
    actionLabel?: string;
    onClose(): void;
    onAction?(): void | Promise<unknown>;
    disabledAction?: boolean;
    maxWidth?: DialogProps["maxWidth"];
    allowOverflow?: boolean;
    padding?: number | string;
    actionIcon?: ReactElement;
  }>
> = ({
  children,
  onClose,
  title,
  onAction,
  actionLabel,
  disabledAction,
  maxWidth,
  allowOverflow,
  padding,
  actionIcon
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();

  const handleAction = async () => {
    if (!onAction) return;
    setIsLoading(true);
    try {
      await onAction();
    } catch (e) {
      console.error(e);
      snackbar.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      disableEnforceFocus
      maxWidth={maxWidth}
      open
      onClose={onClose}
      PaperProps={{
        style: {
          overflow: allowOverflow ? "visible" : undefined,
          borderRadius: 20,
          paddingBottom: padding === undefined ? 30 : padding
        }
      }}
    >
      <FontAwesomeIcon
        style={{
          position: "absolute",
          top: 22,
          right: 22,
          cursor: "pointer"
        }}
        icon={faX}
        onClick={onClose}
      />
      {title && <DialogTitle>{title}</DialogTitle>}

      <Body style={{ overflowY: allowOverflow ? "visible" : "auto", padding, paddingBottom: 0 }}>
        {children}

        {onAction && (
          <Button
            icon={actionIcon}
            disabled={isLoading || disabledAction}
            style={{ minWidth: 150, minHeight: 35, marginTop: "auto" }}
            onClick={() => handleAction()}
            variant="contained"
            color="secondary"
          >
            {isLoading ? <CircularProgress color="secondary" size={20} /> : actionLabel || "Close"}
          </Button>
        )}
      </Body>
    </Dialog>
  );
};

const Body = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-width: 550px;
  min-height: 300px;
  background-color: ${palette.gray.white};
  padding: 30px;
  outline: none;
`;
