import { type FC, useState } from "react";

import styled from "@emotion/styled";
import {
  faFaceAngry,
  faFaceFrownSlight,
  faFaceGrinStars,
  faFaceMeh,
  faFaceSmile
} from "@fortawesome/pro-regular-svg-icons";
import { getUserLabel } from "@relatable/helpers/user";
import { Accordion } from "@relatable/ui/Accordion";
import { Button } from "@relatable/ui/Button";
import { Note } from "@relatable/ui/Note";
import { palette } from "@relatable/ui/Palette";

import { StepperPopup } from "components/ui/StepperPopup";
import { useUpdateCampaignUserMutation } from "modules/campaign/generated";
import { COLLABORATION_SCORE_LABELS, CONTENT_QUALITY_SCORE_LABELS } from "src/lib/constants";

import { CommentStep } from "./CommentStep";
import { ScoreStep } from "./ScoreStep";
import { UserStep } from "./UserStep";
import { CampaignUserReviewDocument, useCampaignUserReviewQuery } from "./generated";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 650px;

  > h2 {
    font-size: 24px;
  }

  > div > h3,
  > div > button {
    font-size: 12px;
    color: ${palette.gray[40]};
    font-weight: normal;
  }
`;

export const ReviewPopup: FC<{
  campaignUserId: number;
  onClose: () => void;
}> = ({ campaignUserId, onClose }) => {
  const [isExtendedPopup, setIsExtendedPopup] = useState(false);

  const { data } = useCampaignUserReviewQuery({
    variables: { campaignUserId }
  });

  const [step, setStep] = useState<number | null>(0);

  const [updateCampaignUser] = useUpdateCampaignUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: [CampaignUserReviewDocument]
  });

  if (!data?.campaign_user?.user || step === null) return null;

  const { user } = data.campaign_user;
  if (typeof user.tag_ids === "string") {
    throw new Error("user.tag_ids is a string, should be number[]");
  }

  const username = getUserLabel(user);

  const maxStep = (() => {
    if (data.campaign_user?.collaboration_score === null) return 0;
    if (data.campaign_user?.content_quality_score === null) return 1;
    if (!data.campaign_user?.notes) return 2;
    return 3;
  })();

  return (
    <StepperPopup
      title={
        <span style={{ fontSize: 12, fontWeight: 700 }}>
          Review <strong style={{ color: palette.primary.red }}>@{username}</strong> for this
          campaign
        </span>
      }
      onClose={() => onClose()}
      onSetStep={setStep}
      steps={isExtendedPopup ? 4 : 1}
      currentStep={step}
      maxWidth={false}
      disabledSteps={[0, 1, 2, 3].slice(maxStep + 1)}
      allowOverflow
      padding={12}
      hideButtons={!isExtendedPopup}
    >
      <Root>
        <Accordion
          style={{ marginBottom: 10 }}
          options={data.campaign_user.user.otherCampaignUsers.map(cu => {
            return {
              title: cu.campaign.title,
              defaultExpanded: true,
              content: (
                <Note label="Comment" variant="error">
                  {cu.notes ||
                    `${username} was rejected by a production manager without a comment.`}
                </Note>
              )
            };
          })}
        />

        {step === 0 && (
          <>
            <h2>How were they to collaborate with?</h2>
            <div
              style={{
                height: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {isExtendedPopup ? (
                <h3>scale of 1 to 5</h3>
              ) : (
                <Button variant="text" onClick={() => setIsExtendedPopup(true)}>
                  show more options
                </Button>
              )}
            </div>
            <ScoreStep
              steps={
                isExtendedPopup
                  ? [
                      { value: 1, title: COLLABORATION_SCORE_LABELS[0], icon: faFaceAngry },
                      { value: 2, title: COLLABORATION_SCORE_LABELS[1], icon: faFaceFrownSlight },
                      { value: 3, title: COLLABORATION_SCORE_LABELS[2], icon: faFaceMeh },
                      { value: 4, title: COLLABORATION_SCORE_LABELS[3], icon: faFaceSmile },
                      { value: 5, title: COLLABORATION_SCORE_LABELS[4], icon: faFaceGrinStars }
                    ]
                  : [
                      { value: 1, title: COLLABORATION_SCORE_LABELS[0], icon: faFaceAngry },
                      { value: 5, title: COLLABORATION_SCORE_LABELS[4], icon: faFaceGrinStars }
                    ]
              }
              value={data.campaign_user?.collaboration_score ?? null}
              onChange={value => {
                updateCampaignUser({
                  variables: { campaignUserId, _set: { collaboration_score: value } }
                });
                if (isExtendedPopup) return;
                if (value === 1) {
                  setStep(2);
                  return;
                }

                onClose();
              }}
            />
          </>
        )}

        {step === 1 && (
          <>
            <h2>How was their content?</h2>
            <h3>scale of 1 to 5</h3>
            <ScoreStep
              steps={[
                { value: 1, title: CONTENT_QUALITY_SCORE_LABELS[0], icon: faFaceAngry },
                { value: 2, title: CONTENT_QUALITY_SCORE_LABELS[1], icon: faFaceFrownSlight },
                { value: 3, title: CONTENT_QUALITY_SCORE_LABELS[2], icon: faFaceMeh },
                { value: 4, title: CONTENT_QUALITY_SCORE_LABELS[3], icon: faFaceSmile },
                { value: 5, title: CONTENT_QUALITY_SCORE_LABELS[4], icon: faFaceGrinStars }
              ]}
              value={data.campaign_user?.content_quality_score ?? null}
              onChange={value =>
                updateCampaignUser({
                  variables: { campaignUserId, _set: { content_quality_score: value } }
                })
              }
            />
          </>
        )}
        {step === 2 && (
          <>
            <h2>
              {isExtendedPopup
                ? "How was the creator to work with in this campaign?"
                : "Why do you want to reject this creator?"}
            </h2>
            <h3>
              You can type up to <strong>400</strong> characters
            </h3>
            <CommentStep
              value={data.campaign_user?.notes ?? ""}
              onChange={value =>
                updateCampaignUser({
                  variables: { campaignUserId, _set: { notes: value } }
                })
              }
            />
          </>
        )}
        {step === 3 && (
          <>
            <h2>Before finish, please check these details</h2>
            <h3>Is everything still correct?</h3>
            <UserStep userId={user.id} comment={user.relatable_comment} tagIds={user.tag_ids} />
          </>
        )}
      </Root>
    </StepperPopup>
  );
};
