import {
  clientApproved,
  creatorDone,
  getContentAggregateState
} from "@relatable/helpers/approvalLogs";
import { getUserKey, getUserLabel, getUserLink } from "@relatable/helpers/user";

import type { CONTENT_APPROVAL_STATE_MANAGER_LABELS } from "modules/campaign/ContentApproval/common/utils";

import type { ContentApprovalOverviewQuery } from "../generated";
import type { Filter } from "../types";

export interface UserContentStatus {
  userKey: string;
  userLabel: string;
  userLink: string;
  user_id: number;
  type: string;
  publish_date_max?: string;
  publish_date_min?: string;
  content_approval_state:
    | ContentApprovalOverviewQuery["campaigns"][number]["campaign_content_settings"][number]["campaign_preapproval_contents"][number]
    | null;
  state: keyof typeof CONTENT_APPROVAL_STATE_MANAGER_LABELS;
  content_id?: number;
  content_setting_id?: number;
  contentSettingName: string;
}

export const transformContentStatus = ({
  campaign_content_settings,
  ...campaign
}: ContentApprovalOverviewQuery["campaigns"][number]): UserContentStatus[] => {
  if (!campaign) {
    return [];
  }

  return campaign_content_settings.reduce((acc: UserContentStatus[], setting) => {
    campaign.campaign_users.forEach(campaignUser => {
      const existingContent = setting.campaign_preapproval_contents?.find(
        pc => pc.user_id === campaignUser.id
      );
      const state = (() => {
        const itemStates: (string | null | undefined)[] = [];
        if (existingContent?.content_preapproval_caption) {
          itemStates.push(
            existingContent.content_preapproval_caption.content_preapproval_logs[0]?.state
          );
        }
        if (
          Array.isArray(existingContent?.campaign_preapproval_content_medias) &&
          existingContent?.campaign_preapproval_content_medias.length > 0
        ) {
          itemStates.push(
            ...existingContent.campaign_preapproval_content_medias.map(
              m => m.content_preapproval_logs[0]?.state
            )
          );
        }

        return getContentAggregateState(itemStates);
      })();

      acc.push({
        content_id: existingContent?.id || undefined,
        publish_date_max: setting.publish_date_max,
        publish_date_min: setting.publish_date_min,
        content_approval_state: existingContent || null,
        type: setting.type || "",
        state,
        user_id: campaignUser.id,
        content_setting_id: setting.id,
        userKey: getUserKey(campaignUser.user) as string,
        userLabel: getUserLabel(campaignUser.user),
        userLink: getUserLink(campaignUser.user),
        contentSettingName: setting.name || ""
      });
    });

    return acc;
  }, []);
};

type FilterUserContentStatusProps = { filter: Filter };
export const filterUserContentStatus = (
  usersContentStatus: UserContentStatus[],
  { filter }: FilterUserContentStatusProps
) => {
  return usersContentStatus
    .sort((a, b) => {
      if (b.userLabel === a.userLabel) {
        return 0;
      }

      return b.userLabel < a.userLabel ? 1 : -1;
    })
    .filter(({ type, state, publish_date_min, publish_date_max }) => {
      if (filter.type !== "all" && filter.type !== type) {
        return false;
      }

      if (filter.state !== "all" && filter.state !== state) {
        return false;
      }

      if (
        filter.dateInterval !== "all" &&
        `${publish_date_min},${publish_date_max}` !== filter.dateInterval
      ) {
        return false;
      }

      return true;
    });
};

export const getProgressData = (data: UserContentStatus[]) => {
  const uniqueParticipantsLength = new Set(data.map(i => i.userKey)).size;
  const postsPerPerson = Number(data.length / uniqueParticipantsLength);

  const toReturn = data.reduce(
    (acc, c, index) => {
      if (clientApproved(c.state)) {
        acc.approvedContentCount += 1;
        acc.approvedParticipants[c.userKey] =
          acc.approvedParticipants[c.userKey] !== undefined
            ? acc.approvedParticipants[c.userKey] + 1
            : 1;
      }

      if (creatorDone(c.state)) {
        acc.submittedContentCount += 1;
      }

      if (index === data.length - 1) {
        acc.approvedParticipantsCount = Object.keys(acc.approvedParticipants).filter(
          k => acc.approvedParticipants[k] === postsPerPerson
        ).length;
      }

      return acc;
    },
    {
      approvedParticipants: {},
      approvedParticipantsCount: 0,
      submittedContentCount: 0,
      approvedContentCount: 0
    }
  );

  return {
    uniqueParticipantsCount: uniqueParticipantsLength,
    approvedParticipantsCount: toReturn.approvedParticipantsCount,
    submittedContentCount: toReturn.submittedContentCount,
    approvedContentCount: toReturn.approvedContentCount
  };
};
