import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import { UserInfoFragmentDoc, UserProfilePictureFragmentDoc, MediaStateFragmentDoc, CaptionStateFragmentDoc, IntercomFragmentDoc } from '../../../../../packages/gql/generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Campaign_User_Bool_Exp>;
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  orderBy: Types.Campaign_User_Order_By;
}>;


export type PaymentsQuery = { __typename?: 'Query', campaign_user: Array<{ __typename?: 'campaign_user', id: number, currency?: string | null, payment_state: Types.Payment_Status_Enum, local_invoice_pay?: number | null, global_pay?: number | null, local_salary_pay?: number | null, gigapay_status?: Types.Gigapay_Status_Enum | null, accounted_at?: string | null, half_accounted_at?: string | null, campaign_user_days_since_completed?: { __typename?: 'campaign_user_days_since_completed', days_since_completed?: number | null } | null, campaign: { __typename?: 'campaign', id: number, stub: string, title: string, project: { __typename?: 'project', id: number, hubspot_deal_id: string } }, campaign_expenses: Array<{ __typename?: 'campaign_expense', id: number, description?: string | null, amount?: number | null, currency?: string | null, receipt?: string | null, comment?: string | null, created_at?: string | null, vat?: number | null, exchange_rates?: any | null, date?: string | null, payment_state: Types.Payment_Status_Enum, gigapay_payment_id?: string | null, campaign_expense_type?: { __typename?: 'campaign_expense_type', id: number, description?: string | null } | null }>, user?: { __typename?: 'users', id: number, legacy_custom_data?: any | null, user_youtube?: { __typename?: 'users_youtube', id: number, channel_title?: string | null, channel_id?: string | null, username?: string | null, median_views?: number | null } | null, user_tiktok?: { __typename?: 'user_tiktok', id: number, username?: string | null, followed_by?: number | null } | null, user_instagram?: { __typename?: 'users_instagram', id: number, username: string, followed_by?: number | null } | null, user_snapchat?: { __typename?: 'users_snapchat', id: number, username: string, subscribers?: number | null } | null } | null }> };

export type PaymentNotesQueryVariables = Types.Exact<{
  campaignUserId: Types.Scalars['Int']['input'];
}>;


export type PaymentNotesQuery = { __typename?: 'Query', campaign_user_by_pk?: { __typename?: 'campaign_user', id: number, notes?: string | null } | null };

export type UpdateExpenseMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set: Types.Campaign_Expense_Set_Input;
}>;


export type UpdateExpenseMutation = { __typename?: 'Mutation', update_campaign_expense_by_pk?: { __typename?: 'campaign_expense', id: number } | null };


export const PaymentsDocument = gql`
    query payments($where: campaign_user_bool_exp, $limit: Int!, $offset: Int!, $orderBy: campaign_user_order_by!) {
  campaign_user(
    limit: $limit
    offset: $offset
    where: $where
    order_by: [$orderBy]
  ) {
    id
    currency
    payment_state
    local_invoice_pay
    global_pay
    local_salary_pay
    gigapay_status
    accounted_at
    half_accounted_at
    campaign_user_days_since_completed {
      days_since_completed
    }
    campaign {
      id
      stub
      title
      project {
        id
        hubspot_deal_id
      }
    }
    campaign_expenses {
      id
      description
      amount
      currency
      receipt
      comment
      created_at
      vat
      exchange_rates
      date
      payment_state
      gigapay_payment_id
      campaign_expense_type {
        id
        description
      }
    }
    user {
      id
      ...UserInfo
      legacy_custom_data
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __usePaymentsQuery__
 *
 * To run a query within a React component, call `usePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function usePaymentsQuery(baseOptions: Apollo.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables> & ({ variables: PaymentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
      }
export function usePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
        }
export function usePaymentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsSuspenseQueryHookResult = ReturnType<typeof usePaymentsSuspenseQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const PaymentNotesDocument = gql`
    query paymentNotes($campaignUserId: Int!) {
  campaign_user_by_pk(id: $campaignUserId) {
    id
    notes
  }
}
    `;

/**
 * __usePaymentNotesQuery__
 *
 * To run a query within a React component, call `usePaymentNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentNotesQuery({
 *   variables: {
 *      campaignUserId: // value for 'campaignUserId'
 *   },
 * });
 */
export function usePaymentNotesQuery(baseOptions: Apollo.QueryHookOptions<PaymentNotesQuery, PaymentNotesQueryVariables> & ({ variables: PaymentNotesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentNotesQuery, PaymentNotesQueryVariables>(PaymentNotesDocument, options);
      }
export function usePaymentNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentNotesQuery, PaymentNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentNotesQuery, PaymentNotesQueryVariables>(PaymentNotesDocument, options);
        }
export function usePaymentNotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PaymentNotesQuery, PaymentNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PaymentNotesQuery, PaymentNotesQueryVariables>(PaymentNotesDocument, options);
        }
export type PaymentNotesQueryHookResult = ReturnType<typeof usePaymentNotesQuery>;
export type PaymentNotesLazyQueryHookResult = ReturnType<typeof usePaymentNotesLazyQuery>;
export type PaymentNotesSuspenseQueryHookResult = ReturnType<typeof usePaymentNotesSuspenseQuery>;
export type PaymentNotesQueryResult = Apollo.QueryResult<PaymentNotesQuery, PaymentNotesQueryVariables>;
export const UpdateExpenseDocument = gql`
    mutation updateExpense($id: Int!, $set: campaign_expense_set_input!) {
  update_campaign_expense_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, options);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>;