import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faFileArrowDown, faFileArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@relatable/ui/Button";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useParams } from "react-router-dom";

import { UploadClipsPopup } from "./UploadClipsPopup";
import { useSendCampaignStoryZipOnSlackMutation } from "./generated";

export const ActionButtons: FC = () => {
  const [isUploadClipsPopup, setIsUploadClipsPopup] = useState(false);
  const snackbar = useSnackbar();
  const { campaignStub: rawCampaignStubs } = useParams<{ campaignStub: string }>();
  const campaignStubs = (rawCampaignStubs ?? "").split(",");
  const [sendCampaignStoryZipOnSlack] = useSendCampaignStoryZipOnSlackMutation();

  if (campaignStubs.length !== 1) return null;
  return (
    <SActionButtons>
      <Button
        icon={<FontAwesomeIcon icon={faFileArrowDown} />}
        onClick={async () => {
          try {
            await sendCampaignStoryZipOnSlack({ variables: { stub: rawCampaignStubs ?? "" } });
            snackbar.success(
              "Download initiated, it is going to appear in the #campaign-posts channel (it takes a while)"
            );
          } catch (e) {
            console.error(e);
            snackbar.error("Something went wrong");
          }
        }}
      >
        Download approved clips
      </Button>
      <Button
        icon={<FontAwesomeIcon icon={faFileArrowUp} />}
        variant="outlined"
        onClick={() => setIsUploadClipsPopup(true)}
      >
        Upload clips
      </Button>

      {isUploadClipsPopup && (
        <UploadClipsPopup
          onUpload={() => snackbar.success("Clips have been successfully uploaded")}
          onClose={() => setIsUploadClipsPopup(false)}
        />
      )}
    </SActionButtons>
  );
};

const SActionButtons = styled.div`
  display: flex;
  gap: 20px;
`;
