import type { FC } from "react";

import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import type { Platform_Enum } from "@relatable/gql/generated-base";
import { prettifyDate, prettifyNumber, prettifyPercentages } from "@relatable/helpers";
import { getUserKey, getUserLabel, getUserLink } from "@relatable/helpers/user";
import { palette } from "@relatable/ui/Palette";
import { Table, type TableColumn } from "@relatable/ui/Table";
import { Link } from "react-router-dom";

import { Center } from "lib/styled";
import { CustomDataCell } from "modules/campaign/common/CustomDataCell";

import type { CampaignParticipantsOtherStatisticsQuery } from "../generated";

export const StatisticsTable: FC<{
  data: CampaignParticipantsOtherStatisticsQuery | undefined;
}> = ({ data }) => {
  const hasYoutubeCampaign = data?.campaigns.some(c => c.platform === "youtube");
  const hasTiktokCampaign = data?.campaigns.some(c => c.platform === "tiktok");
  const isSingleCampaign = data?.campaigns.length === 1;

  if (!data) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  const rows =
    data?.users_in_lists.map(
      ({
        user,
        campaign_stub,
        campaign_user,
        user_id,
        user_instagram,
        user_tiktok,
        user_youtube
      }) => {
        const campaign = data?.campaigns.find(c => c.stub === campaign_stub);
        if (!campaign?.platform) throw new Error("Invalid user platform");

        const platformFollowers: Record<Platform_Enum, number> = {
          instagram: user_instagram?.followed_by || 0,
          youtube: user_youtube?.median_views || 0,
          tiktok: user_tiktok?.followed_by || 0,
          snapchat: 0
        };

        const normalizedLocalPay = Math.round(
          (campaign_user?.local_invoice_pay ?? 0) / (campaign?.exchange_rate ?? 1)
        );
        const totalReach = (campaign?.number_of_posts ?? 0) * platformFollowers[campaign?.platform];
        const cpm = totalReach > 0 ? Math.round((normalizedLocalPay * 1000) / totalReach) : null;

        return {
          key: `${user_id} ${campaign_user?.id}`,
          id: user_id,
          first_name: user?.first_name,
          email: user?.email,
          platform: campaign.platform,
          custom_data: campaign_user?.custom_data,
          publishDate: prettifyDate(campaign_user?.publish_date),
          cpm,
          hypeAuditor: user?.hypeauditor?.state,
          userKey: user ? getUserKey(user) : null,
          userLabel: getUserLabel(user),
          userLink: getUserLink(user),

          // tiktok
          audienceActivity: user_tiktok?.audience_activity,
          audienceCountries: user_tiktok?.audience_countries,
          audienceGenders: user_tiktok?.audience_genders,
          likes: user_tiktok?.likes,
          comments: user_tiktok?.comments,
          profileViews: user_tiktok?.profile_views,
          shares: user_tiktok?.shares,
          videoViews: user_tiktok?.video_views,

          // youtube
          maxViews: user_youtube?.max_views,
          medianViews: user_youtube?.median_views,

          avgEngagement: prettifyPercentages({ of: user?.hypeauditor?.avg_engagement, total: 1 })
        };
      }
    ) || [];

  const columns: TableColumn<typeof rows>[] = [
    {
      field: "userLabel",
      headerName: hasYoutubeCampaign && isSingleCampaign ? "Channel" : "Username",
      renderCell: ({ row }) => {
        return (
          <div style={{ display: "flex" }}>
            {row.userLabel ? (
              <Link target="_blank" rel="noreferrer" to={row.userLink}>
                <span>
                  {row.userLabel} {row.first_name && `(${row.first_name})`}
                </span>
              </Link>
            ) : (
              "-"
            )}
          </div>
        );
      }
    },
    { field: "email", headerName: "Email" },
    {
      field: "custom_data",
      headerName: "Custom data",
      renderCell: ({ value }) => <CustomDataCell data={value} />
    },

    {
      field: "audienceActivity",
      headerName: "Audience activity",
      hide: !hasTiktokCampaign,
      renderCell: ({ value: val }) => {
        if (!val) return "-";
        const value = Array.isArray(val) ? val : [val];
        const title = value
          .map(v => {
            const [key, percentage] = v.split("|#|");
            const hour = `${`0${key}`.slice(-2)}:00`;
            return `${hour} - ${prettifyNumber(Number(percentage))}`;
          })
          .join("\n");

        return <FontAwesomeIcon icon={faCircleInfo} color={palette.secondary.blue} title={title} />;
      }
    },
    {
      field: "audienceCountries",
      headerName: "Audience countries",
      hide: !hasTiktokCampaign,
      renderCell: ({ value: val }) => {
        if (!val) return "-";
        const value = Array.isArray(val) ? val : [val];
        const title = value
          .map(v => {
            const [key, percentage] = v.split("|#|");
            return `${key} ${prettifyNumber(Number(percentage), {
              percentages: true
            })}`;
          })
          .join("\n");

        return <FontAwesomeIcon icon={faCircleInfo} color={palette.secondary.blue} title={title} />;
      }
    },
    {
      field: "audienceGenders",
      headerName: "Audience genders",
      hide: !hasTiktokCampaign,
      renderCell: ({ value: val }) => {
        if (!val) return "-";
        const value = Array.isArray(val) ? val : [val];
        return value.map(v => {
          const [key, percentage] = v.split("|#|");
          return (
            <div key={key} style={{ whiteSpace: "nowrap" }}>
              {key}{" "}
              {prettifyNumber(Number(percentage), {
                percentages: true
              })}
            </div>
          );
        });
      }
    },
    {
      field: "likes",
      headerName: "Likes",
      hide: !hasTiktokCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "comments",
      headerName: "Comments",
      hide: !hasTiktokCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    { field: "publishDate", headerName: "Publish date", hide: !hasYoutubeCampaign },
    {
      field: "maxViews",
      headerName: "Max views",
      hide: !hasYoutubeCampaign,
      renderCell: ({ value }) => prettifyNumber(typeof value === "string" ? Number(value) : value)
    },
    {
      field: "medianViews",
      headerName: "Median views",
      hide: !hasYoutubeCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "profileViews",
      headerName: "Profile views",
      hide: !hasTiktokCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "shares",
      headerName: "Shares",
      hide: !hasTiktokCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },
    {
      field: "videoViews",
      headerName: "Video views",
      hide: !hasTiktokCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    },

    {
      field: "avgEngagement",
      headerName: "Avg engagement",
      hide: isSingleCampaign && hasYoutubeCampaign
    },
    {
      field: "cpm",
      headerName: "CPM",
      hide: isSingleCampaign && hasYoutubeCampaign,
      renderCell: ({ value }) => prettifyNumber(value)
    }
  ];

  return (
    <Table
      tableId="campaign-participant-stats"
      rows={rows}
      columns={columns}
      sortable
      canSelectColumns
      canExportCSV
    />
  );
};
