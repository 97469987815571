import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { faBolt, faStop } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slider, TextField } from "@mui/material";
import { Button } from "@relatable/ui/Button";

export const Controls: FC<{
  loading: boolean;
  generating: boolean;
  onAbort: () => void;
  onSubmit: ({ text, temperature }: { text: string; temperature?: number }) => void;
}> = ({ loading, generating, onAbort, onSubmit }) => {
  const [temperature, setTemperature] = useState<number | undefined>(undefined);
  const [text, setText] = useState("");

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (loading) return;
    if (e.shiftKey || e.ctrlKey || e.key !== "Enter") return;
    e.preventDefault();
    onSubmit({ text, temperature });
    setText("");
  };

  const handleTemperatureChange = (value: number) => {
    if (value < 0.1) setTemperature(0.1);
    else if (value > 0.8) setTemperature(0.8);
    else setTemperature(undefined);
  };

  return (
    <Root>
      <TextField
        label="Question"
        value={text}
        onChange={e => setText(e.target.value)}
        multiline
        autoFocus
        inputProps={{
          onKeyDown: handleKeyDown
        }}
        style={{ width: "100%", marginBottom: 10 }}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            disabled={loading || generating}
            onClick={() => {
              onSubmit({ text, temperature });
              setText("");
            }}
            color="primary"
            icon={<FontAwesomeIcon icon={faBolt} />}
          >
            Submit
          </Button>

          <Button
            disabled={!generating}
            onClick={onAbort}
            style={{ marginLeft: 10 }}
            icon={<FontAwesomeIcon icon={faStop} />}
          >
            Stop generating
          </Button>
        </div>
        <div style={{ width: 200 }}>
          <Slider
            defaultValue={0.5}
            step={null}
            disabled={loading || generating}
            valueLabelDisplay="off"
            min={0}
            max={1}
            onChange={(_, value) => handleTemperatureChange(value as number)}
            marks={[
              {
                value: 0,
                label: "Concise"
              },
              {
                value: 0.5,
                label: "Auto"
              },
              {
                value: 1,
                label: "Creative"
              }
            ]}
          />
        </div>
      </div>
    </Root>
  );
};

const Root = styled.div``;
