import { type FC, Suspense, useCallback } from "react";

import styled from "@emotion/styled";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import {
  faBoxArchive,
  faBuilding,
  faCreditCard,
  faFolderGear,
  faGear,
  faLink,
  faList
} from "@fortawesome/pro-regular-svg-icons";
import { faCircleCaretDown, faRightFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, IconButton } from "@mui/material";
import { ErrorBoundary } from "@relatable/ui/ErrorBoundary";
import { palette } from "@relatable/ui/Palette";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { ButtonBar } from "components/ButtonBar";
import { SearchBox } from "components/SearchBox";
import { Menu, type MenuOption } from "components/ui/Menu";
import { useUser } from "hooks/useUser";
import { useCampaignsQuery } from "modules/campaign/generated";

import { useReportFeErrorMutation } from "@relatable/gql/generated";

export const Layout: FC = () => {
  const { pathname } = useLocation();
  const user = useUser({ skip: pathname === "/login-callback" });

  const [mutate] = useReportFeErrorMutation();

  const errorHandler = useCallback(
    (error: string) => {
      mutate({ variables: { url: window.location.href, message: error } });
    },
    [mutate]
  );

  return (
    <ErrorBoundary onError={errorHandler}>
      <div style={{ background: palette.gray[10], width: "100%", minHeight: "100vh" }}>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <Suspense fallback={<Loader />}>{user ? <Outlet /> : <Loader />}</Suspense>
      </div>
    </ErrorBoundary>
  );
};

const navigationItems = [
  {
    name: "AI chat",
    url: "/chat"
  },
  {
    name: "Projects",
    url: "/overview"
  },
  {
    name: "Campaigns",
    url: "/campaigns/list"
  },
  {
    name: "Finance",
    url: "/finance"
  },
  {
    name: "Payments",
    url: "/payments"
  },
  {
    name: "Curation",
    url: "/curation"
  },
  {
    name: "Time Reporting",
    url: "/time-reporting"
  },
  {
    name: "Links",
    url: "/links"
  }
];

const Header: FC = () => {
  const { campaignStub } = useParams<{ campaignStub?: string }>();
  const { search } = useLocation();

  const campaignSearchId = search
    .replace("?", "")
    .split("&")
    .reduce<null | string>((acc, s) => {
      const [key, value] = s.split("=");
      return key === "campaignIds" ? value : acc;
    }, null);

  const isCampaignView = campaignStub || campaignSearchId;

  const { data } = useCampaignsQuery({
    variables: {
      where: campaignStub
        ? { stub: { _in: campaignStub.split(",") } }
        : { id: { _eq: Number(campaignSearchId) } }
    },
    skip: !isCampaignView
  });

  const campaigns = data?.campaigns;
  const campaign = campaigns?.[0];
  const projectStub = campaign?.project.stub;
  const stubUri = campaigns?.map(c => c.stub)?.join(",") || campaignStub;
  const pathPrefix = `/campaign/${stubUri}`;
  const hasContentSettings = campaigns?.some(c => c.content_settings.length > 0);
  const showAggregateResults = campaigns && campaigns?.length > 1;
  const hasPost = campaigns?.some(c =>
    c.content_settings.some(
      item => c.platform === "instagram" && ["photo", "carousel", "video"].includes(item.type)
    )
  );

  const hasStory = campaigns?.some(c => c.content_settings.some(item => item.type === "story"));
  const hasYouTube = campaigns?.some(
    c => c.platform === "youtube" && c.content_settings.some(item => item.type === "youtube")
  );
  const hasTikTok = campaigns?.some(c => c.content_settings.some(item => item.type === "tiktok"));
  const hasSnap = campaigns?.some(c => c.content_settings.some(item => item.type === "snap"));

  const projectLinks: MenuOption[] =
    campaign?.project.project_attachments.map((c, index, arr) => ({
      label: c.name,
      href: c.link,
      icon: faLink,
      target: "_blank",
      divider: index === arr.length - 1
    })) ?? [];

  return (
    <ItemsContainer>
      <NavLink to="/overview">
        <BrandLogo src="/images/relatable_red.svg" title="Relatable logo" />
      </NavLink>

      {isCampaignView ? (
        <ButtonBar
          navigationItems={[
            {
              name: "Timeline",
              url: `${pathPrefix}/timeline`
            },
            !showAggregateResults && {
              name: "Overview",
              url: `${pathPrefix}/overview`
            },
            {
              name: "Participants",
              url: `${pathPrefix}/participants`
            },
            !showAggregateResults &&
              (hasContentSettings || !campaign) && {
                name: "Content approval",
                url: `${pathPrefix}/approval`
              },
            !showAggregateResults && {
              name: "Messages",
              url: `${pathPrefix}/messages`
            },
            hasPost && {
              name: "Posts",
              url: `${pathPrefix}/posts`
            },
            hasStory && {
              name: "Stories",
              url: `${pathPrefix}/stories`
            },
            hasYouTube && {
              name: "Videos",
              url: `${pathPrefix}/videos`
            },
            hasTikTok && {
              name: "TikToks",
              url: `${pathPrefix}/tiktoks`
            },
            hasSnap && {
              name: "Snaps",
              url: `${pathPrefix}/snaps`
            }
          ]}
        />
      ) : (
        <ButtonBar navigationItems={navigationItems} />
      )}

      {campaigns && !hasContentSettings && (
        <Alert severity="error">Missing content settings!</Alert>
      )}

      <div style={{ display: "flex", alignItems: "center", gap: 5, marginLeft: "auto" }}>
        <SearchBox />
        {isCampaignView && (
          <>
            <div style={{ position: "relative" }}>
              <Menu
                options={navigationItems.map(i => ({ label: i.name, href: i.url }))}
                renderTriggerComponent={p => (
                  <IconButton {...p}>
                    <FontAwesomeIcon size="lg" icon={faCircleCaretDown} />
                  </IconButton>
                )}
              />
            </div>
            <Menu
              renderTriggerComponent={p => (
                <IconButton {...p}>
                  <FontAwesomeIcon size="lg" icon={faCog} />
                </IconButton>
              )}
              options={[
                ...projectLinks,
                {
                  label: "Campaign settings",
                  href: `/campaign/${campaignStub}/edit`,
                  icon: faGear
                },
                {
                  label: "Project settings",
                  href: `/project/${projectStub}/edit`,
                  icon: faFolderGear
                },
                {
                  label: "Creator List export",
                  href: `/hypeauditor/${campaign?.platform}/aggregated?campaignIds=${campaigns?.map(c => c.id)?.join(",")}&listIds=${campaign?.list_id}`,
                  icon: faList,
                  divider: true
                },
                {
                  label: "Client Workspace settings",
                  href: `/campaign/${campaignStub}/manage/sheet`,
                  icon: faBuilding
                },
                {
                  label: "Expenses",
                  href: `/campaign/${campaignStub}/expenses`,
                  divider: true,
                  icon: faCreditCard
                },
                {
                  label: "Archive campaign",
                  href: `/campaign/${campaignStub}/archive`,
                  icon: faBoxArchive,
                  variant: "danger"
                }
              ]}
            />
          </>
        )}
        <LogoutIcon />
      </div>
    </ItemsContainer>
  );
};

const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: 85%;
  max-width: 1280px;
  position: relative;
  gap: 15px;
`;

const BrandLogo = styled.img`
  height: 24px;
  margin-top: 3px;
`;

const HeaderContainer = styled.header`
  width: 100vw;
  position: relative;
  top: 0;
  background-color: ${palette.gray.white};
  z-index: 10;
  border-bottom: 1px solid ${palette.gray[30]};
  height: 68px;
`;

const LogoutIcon: FC = () => {
  const onLogoutClick = async () => {
    await fetch(`${process.env.GRAPH_URL}/auth/logout`, { credentials: "include" });
    // we need a full reload
    window.location.href = "/";
  };

  return (
    <IconButton title="Logout" onClick={onLogoutClick}>
      <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
    </IconButton>
  );
};
